<b>Your Subscriptions</b>
<div class="space">
  <div class="tableWrap">
    <app-data-table
      [dataSource]="dataSource"
      [columnConfig]="columnConfig"
      [isPageable]="true"
    >
      <ng-template #cellTemplate let-element="element" let-col="column">
        <ng-container [ngSwitch]="col.key">
          <span *ngSwitchCase="'event_type'">
            {{ element.event_type }}
          </span>
          <span *ngSwitchCase="'title'">
            {{ element.title }}
          </span>
          <span *ngSwitchCase="'created'">
            {{ element.created }}
          </span>

          <span *ngSwitchCase="'is_active'">
            <mat-icon
              *ngIf="element.is_active; else falseTemplate"
              class="theme-decernis__icon"
              svgIcon="check-no-rounded"
            ></mat-icon>

            <ng-template #falseTemplate>
              <mat-icon class="theme-decernis__icon" svgIcon="close"></mat-icon>
            </ng-template>
          </span>

          <span *ngSwitchCase="'manage'">
            <button
              class="theme-decernis__button btn-create btn-button"
              mat-button
              type="button"
              color="primary"
              (click)="optOutAction(element.opt_out_token)"
              [disabled]="element.is_active === false"
            >
              <mat-icon
                class="theme-decernis__icon"
                svgIcon="delete"
              ></mat-icon>
              Unsubscribe
            </button>
          </span>
        </ng-container>
      </ng-template>
    </app-data-table>
  </div>
</div>
