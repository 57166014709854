import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RequestArgs } from 'app/components/newsletter/newsletter.ds';
/**
 * Generic base class, used to implement model-specific datasources, where filtering, ordering and pagination is handled by the server.
 *
 * A data source is a definitive way of providing a dataset to a table component. It's responsible for
 * providing the data in its final form, and it's responsible for handling additional user input
 * (e.g sorting or filtering). This data source implementation handles it on the browser level.
 *
 * It's the responsibility of the component which created the data source to provide filter values to it.
 *
 * -> Example usage:
 * Suppose we have a model called `Recipe`:
 *
 * @example
 * <pre><code>
 * export class RecipeDataSource extends BaseServerSideDataSource<Recipe> {
 *   protected loadData(): void {
 *     this.rest.get(someUrl).subscribe( data => {
 *         this.dataLength = data.count;
 *         this.subject.next(data.results);
 *     });
 *   }
 * }
 * </code></pre>
 */
export abstract class BaseServerSideDataSource<T> extends DataSource<T> {
  loadingSubject = new Subject<boolean>();
  public data: T[] = [];
  public dataLength: Number;
  public pageSize = 50;
  public offset = 0;
  public loading$ = this.loadingSubject.asObservable();

  _paginator: MatPaginator;
  _filterChange: Subject<RequestArgs>;
  unsubscribe$: Subject<void> = new Subject();

  baseArgs = {
    active: null,
    direction: null,
    offset: null,
    filters: null,
  };

  currArgs = { ...this.baseArgs };

  protected dataSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  constructor() {
    super();
  }

  /**
   * Method that provides initial data to the stream. This method needs to be overriden by the child class.
   */
  public abstract loadData(args?: RequestArgs): void;

  /**
   * Getter used to read the current value of the filter stream
   */
  get filter(): Subject<RequestArgs> {
    return this._filterChange;
  }

  set filter(filter: Subject<RequestArgs>) {
    if (!this._filterChange) {
      this.bindFiltering(filter);
    }
    this._filterChange = filter;
  }

  set paginator(paginator: MatPaginator) {
    if (paginator) {
      this._paginator = paginator;
      this.currArgs.offset = paginator.pageSize * paginator.pageIndex;
      this.bindPageEvent(paginator);
    }
  }

  get paginator() {
    return this._paginator;
  }

  set sort(sort: MatSort) {
    if (sort) {
      this.currArgs.active = sort.active;
      this.currArgs.direction = sort.direction;
      this.bindSortEvent(sort);
    }
  }

  /**
   * Reset pagination, so that the table gets back to first page.
   */
  public resetPagination(): void {
    this.offset = 0;
    this.currArgs.offset = null;

    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  public generateArgs(): RequestArgs {
    const newArgs = { ...this.currArgs, ...this.currArgs.filters };
    delete newArgs.filters;

    return Object.keys(newArgs)
      .filter(key => newArgs[key])
      .reduce((next, key) => ((next[key] = newArgs[key]), next), {});
  }

  public reset() {
    this.currArgs = { ...this.baseArgs };
    this.resetPagination();
  }

  bindPageEvent(paginator: MatPaginator) {
    paginator.page
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(pageEvent => {
          this.offset = pageEvent.pageSize * pageEvent.pageIndex;
          this.currArgs.offset = this.offset.toString();
        })
      )
      .subscribe(() => this.loadData(this.generateArgs()));
  }

  bindSortEvent(sort: MatSort) {
    sort.sortChange
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(({ active, direction }) => {
          this.currArgs.active = active;
          this.currArgs.direction = direction;
          this.resetPagination();
        })
      )
      .subscribe(() => {
        this.loadData(this.generateArgs());
      });
  }

  bindFiltering(filter: Subject<RequestArgs>) {
    filter
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(filters => {
          this.resetPagination();
          this.currArgs.filters = filters;
        })
      )
      .subscribe(() => this.loadData(this.generateArgs()));
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   */
  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect() {
    this.dataSubject.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

/**
 * Interface for a injectable that's supposed to construct an instance of the data source.
 *
 * This solution is required in order to mock data source implementation in unit tests.
 */
export interface IServerSideDataSourceFactory<T> {
  getDataSource(...dataSourceParams): BaseServerSideDataSource<T>;
}
