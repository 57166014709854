<mat-form-field class="theme-decernis__form-field" appearance="outline">
  <mat-label>Choose timezone</mat-label>
  <mat-select
    #timezoneSelect
    class="theme-decerins__select"
    panelClass="theme-decernis__selectPanel"
    [formControl]="control"
    (selectionChange)="onSelectChange($event)"
  >
    <app-select-search [formControl]="filterTimezoneCtrl"></app-select-search>

    <mat-option
      *ngFor="let zone of filteredTimezone | async"
      [value]="zone.timezone"
    >
      {{ zone.timezone }} {{ zone.offset | GMTTimezone }}
    </mat-option>
  </mat-select>
</mat-form-field>
