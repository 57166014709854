<div class="search-preview__table">
  <div class="search-preview__table-header">
    <h3 class="search-preview__table-title">Search Query preview</h3>
  </div>
  <ul class="search-preview__table-list">
    <ng-container *ngIf="searchQueryRows.length; else emptyMessage">
      <ng-container
        *ngFor="
          let queryRow of searchQueryRows;
          let first = first;
          let id = index
        "
      >
        <li
          class="search-preview__table-item"
          *ngIf="
            queryRow.selectedOption &&
            queryRow.operator &&
            queryRow.chips.length
          "
        >
          <span class="search-preview__chip-separator" *ngIf="!first">AND</span>
          <mat-chip
            class="theme-decernis__chip search-preview__fat-chip"
            color="primary"
            [disableRipple]="true"
            [removable]="removable"
            (removed)="remove(id)"
          >
            <span class="search-preview__chip-wrap">
              <mat-icon
                class="theme-decernis__icon search-preview__chip-icon"
                svgIcon="{{ queryRow.operator | iconName }}"
              ></mat-icon>
            </span>
            <span class="search-preview__chip-wrap">
              <span class="search-preview__selectedOption primary">{{
                queryRow.selectedOption
              }}</span>
            </span>
            <ng-container *ngFor="let chip of queryRow.chips; let last = last">
              <span class="search-preview__chip-wrap">
                <app-chip
                  color="accent"
                  [name]="chip.name"
                  [small]="true"
                ></app-chip>
                <span class="search-preview__separator" *ngIf="!last">{{
                  (queryRow.logicalOperator === 'any' ? 'or' : 'and')
                    | uppercase
                }}</span>
              </span>
            </ng-container>
            <span
              class="search-preview__chip-wrap"
              *ngIf="queryRow.optionalText"
            >
              <span class="theme-decernis__chipOptionalText"
                >{{ queryRow.optionalText }}
              </span>
            </span>
            <span class="search-preview__chip-wrap">
              <mat-icon
                matChipRemove
                class="theme-decernis__icon search-preview__chip-icon search-preview__chip-icon__reverseMargin"
                svgIcon="cancel"
              ></mat-icon>
            </span>
          </mat-chip>
        </li>
      </ng-container>
    </ng-container>
  </ul>
  <ng-template #emptyMessage>
    <li>
      <p class="search-preview__emptyText">
        Compose your search query to see the preview.
      </p>
    </li>
  </ng-template>
</div>
