import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';

@Injectable()
export class EventSubscriptions {
  endpoint = environment.apiUrls.news_ws + '/events/subscriptions';
  constructor(private rest: RestService) {}

  list(args: any) {
    return this.rest.get(this.endpoint, { limit: 50, ...args });
  }
}
