import { SelectOption } from 'app/interfaces';

const SelectOptionsConfig: SelectOption[] = [
  {
    name: 'Agency',
    safeName: 'agency',
    useService: true,
    serviceEndpoint: 'pdms/metadata/agencies',
    multiInstances: false,
    hightlightInQuery: false,
  },
  {
    name: 'Category',
    safeName: 'category',
    useService: true,
    serviceEndpoint: 'pdms/metadata/categories',
    multiInstances: false,
    hightlightInQuery: false,
  },
  {
    name: 'Country',
    safeName: 'country',
    useService: true,
    serviceEndpoint: 'pdms/metadata/countries',
    multiInstances: false,
    hightlightInQuery: false,
  },
  {
    name: 'Region',
    safeName: 'region',
    useService: true,
    serviceEndpoint: 'pdms/metadata/regions',
    multiInstances: false,
    hightlightInQuery: false,
  },
  {
    name: 'Ruletype',
    safeName: 'ruletype',
    useService: true,
    serviceEndpoint: 'pdms/metadata/ruletypes',
    multiInstances: false,
    hightlightInQuery: false,
  },
  {
    name: 'Date',
    safeName: 'date',
    useService: false,
    serviceEndpoint: '',
    multiInstances: false,
    hightlightInQuery: false,
    onlyOneInstance: true,
    additionalOptions: {
      dateSelectValues: [
        {
          name: 'days from now',
          value: 'days',
        },
        {
          name: 'months from now',
          value: 'months',
        },
        {
          name: 'years from now',
          value: 'years',
        },
      ],
    },
  },
  {
    name: 'Domain',
    safeName: 'domain',
    useService: false,
    serviceEndpoint: '',
    multiInstances: true,
    hightlightInQuery: false,
  },
  {
    name: 'Language',
    safeName: 'lang',
    useService: true,
    serviceEndpoint: 'languages',
    multiInstances: false,
    hightlightInQuery: false,
  },
  {
    name: 'Text',
    safeName: 'text',
    useService: false,
    serviceEndpoint: '',
    multiInstances: true,
    hightlightInQuery: false,
  },
  {
    name: 'Title',
    safeName: 'title',
    useService: false,
    serviceEndpoint: '',
    multiInstances: true,
    hightlightInQuery: false,
  },
  {
    name: 'URL',
    safeName: 'url',
    useService: false,
    serviceEndpoint: '',
    multiInstances: true,
    hightlightInQuery: false,
  },
  {
    name: 'Key term',
    safeName: 'key_terms',
    useService: false,
    serviceEndpoint: '',
    multiInstances: true,
    hightlightInQuery: true,
  },
  {
    name: 'Journal',
    safeName: 'journal',
    useService: true,
    serviceEndpoint: 'pdms/metadata/journals',
    multiInstances: false,
    hightlightInQuery: false,
  },
  {
    name: 'Group',
    safeName: 'group_name',
    useService: true,
    hightlightInQuery: false,
    serviceEndpoint: 'analytics/group_name',
    multiInstances: false,
  },
  // TEMP: Hidden untill we get some results from server response
  // {
  //   name: 'Subgroup',
  //   safeName: 'subgroup_name',
  //   useService: true,
  //   serviceEndpoint: 'analytics/subgroup_name',
  //   multiInstances: false,
  // },
  {
    name: 'Type',
    safeName: 'type_name',
    useService: true,
    hightlightInQuery: false,
    serviceEndpoint: 'analytics/type_name',
    multiInstances: false,
  },
].sort((a, b) => (a.safeName < b.safeName ? -1 : 1));

export { SelectOptionsConfig };
