import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { QueryRow } from 'app/interfaces';
import { SelectOptionsConfig } from 'app/components/profile/select-option-config';
@Component({
  selector: 'app-search-preview',
  templateUrl: './search-preview.component.html',
  styleUrls: ['./search-preview.component.scss'],
})
export class SearchPreviewComponent implements OnInit {
  @Output() queryRowUpdate: EventEmitter<any> = new EventEmitter();
  @Input()
  set searchQueryFields(searchQueryFields: QueryRow[]) {
    this.searchQueryRows = searchQueryFields
      .filter(row => row.selectedOption && row.chips.length)
      .map((row: QueryRow) => {
        return {
          ...row,
          selectedOption: this.getOptionName(row.selectedOption),
        };
      });
  }

  searchQueryRows: QueryRow[] = [];
  removable = true;

  constructor() {}

  ngOnInit() {}

  remove(id: number): void {
    const removed = this.searchQueryRows.splice(id, 1);
    this.queryRowUpdate.emit(removed);
  }

  getOptionName(selectedOption: string): string {
    return SelectOptionsConfig.find(
      option => option.safeName === selectedOption
    ).name;
  }
}
