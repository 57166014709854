import { Injectable } from '@angular/core';
import { ServerResponseIssue, Issue, IssueType } from 'app/interfaces';
import { IssueTypeServiceService } from './issue-type-service.service';
import { Observable, of } from 'rxjs';
import { shareReplay, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class IssuesDataFormatterService {
  issues: Issue[];
  issueType$: Observable<IssueType[]> = this.issueTypeService
    .get()
    .pipe(shareReplay());
  dateFormat = 'YYYY-MM-DD';

  constructor(private issueTypeService: IssueTypeServiceService) {}

  format(issues: ServerResponseIssue[]): Observable<Issue[]> {
    return of(this.mapKeys(issues)).pipe(
      switchMap(mappedIssues => this.formatResponse(mappedIssues)),
      map(formattedIssues => this.formatDate(formattedIssues))
    );
  }

  mapKeys(issues: ServerResponseIssue[]): Issue[] {
    const keysToDelete = ['username', 'updated'];

    return issues.map(issue => {
      const newIssue: Issue = {
        ...issue,
        creator: issue.username,
        modified: issue.updated,
      };

      keysToDelete.forEach(key => {
        delete newIssue[key];
      });

      return newIssue;
    });
  }

  formatResponse(issues: Issue[]): Observable<Issue[]> {
    return this.issueType$.pipe(
      map(types => {
        return issues.map(issue => ({
          ...issue,
          issue_type: types.find(type => type.id === +issue.issue_type)
            .issue_type,
        }));
      })
    );
  }

  formatDate(issues: Issue[]): Issue[] {
    const dateKeys = ['publish', 'created', 'modified'];
    return issues.map(issue => {
      dateKeys.forEach(key => {
        if (issue[key]) {
          issue[key] = moment(issue[key]).format(this.dateFormat);
        } else {
          issue[key] = '-';
        }
      });

      return issue;
    });
  }
}
