<div class="columnPicker">
  <mat-checkbox
    *ngFor="let column of columnConfig"
    class="columnPicker__item"
    color="primary"
    [checked]="!column.hide"
    [disabled]="column.locked"
    (change)="onCheckboxChange($event, column.key)"
  >
    {{ column.name }}
  </mat-checkbox>
</div>
