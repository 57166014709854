import { Injectable } from '@angular/core';
import {
  MatDialogConfig,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DialogTitle, DialogOptionActions } from 'app/interfaces';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { ColumnConfig } from '../data-table.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ColumnPickerService {
  constructor(private dialog: MatDialog) {}

  callDialog(config: ColumnConfig[]): MatDialogRef<any> {
    const title: DialogTitle = {
      iconName: 'cog',
      text: 'Choose columns',
    };
    const optionActions: Array<DialogOptionActions> = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Cancel',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Save',
      },
    ];
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '320px';
    dialogResponseConfig.data = {
      confirm: true,
      title,
      optionActions,
      columnPickerConfig: config,
    };

    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }
}
