<form (ngSubmit)="onFiltersSubmit()">
  <div class="theme-decernis__tableWrap">
    <table
      mat-table
      matSort
      multiTemplateDataRows
      class="theme-decernis__table"
      [matSortActive]="initialSortName"
      [matSortDirection]="initialSortDirection"
      [dataSource]="dataSource"
    >
      <ng-container
        *ngFor="let column of columnConfig; let id = index"
        [matColumnDef]="column.sortingKey || column.key"
        [sticky]="column.sticky"
        [stickyEnd]="column.stickyEnd"
      >
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="theme-decernis__tableHead"
          [attr.data-disabled]="!column.sortable"
          [ngStyle]="column.style"
          [ngClass]="{
            shadow: column.sticky || column.stickyEnd,
            'shadow--right': column.sticky,
            'shadow--left': column.stickyEnd
          }"
        >
          {{ column.name }}
        </th>

        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="column.style"
          [ngClass]="{
            'theme-decernis__tableCell': 'true',
            shadow: column.sticky || column.stickyEnd,
            'shadow--right': column.sticky,
            'shadow--left': column.stickyEnd
          }"
        >
          <ng-container *ngIf="column.cellTemplate; else regularTemplate">
            <ng-container
              *ngTemplateOutlet="
                cellTemplate;
                context: createContext(element, column)
              "
            ></ng-container>
          </ng-container>

          <ng-template #regularTemplate>
            {{ element[column.key] }}
          </ng-template>
        </td>
      </ng-container>

      <ng-container
        *ngFor="let column of filterColumns"
        [matColumnDef]="column"
        [sticky]="column.sticky"
        [stickyEnd]="column.stickyEnd"
      >
        <td
          *matHeaderCellDef
          class="theme-decernis__tableHead theme-decernis__tableHead--lighter"
          [ngClass]="{
            shadow: column.sticky || column.stickyEnd,
            'shadow--right': column.sticky,
            'shadow--left': column.stickyEnd
          }"
        >
          <app-table-column-filter
            *ngIf="getFilterColumnConfig(column); let config"
            [type]="config.type"
            [value]="config.value"
            [hide]="config.hide"
            [name]="config.name"
            [selectable]="config.selectable"
            (onFilterValueChange)="onFilterValueChange($event)"
          >
          </app-table-column-filter>
        </td>
      </ng-container>

      <tr
        mat-header-row
        class="theme-decernis__headerRow"
        *matHeaderRowDef="displayedColumns"
      ></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="theme-decernis__tableRow"
      ></tr>

      <ng-container *ngIf="filterColumns.length">
        <tr
          mat-header-row
          *matHeaderRowDef="filterColumns"
          class="theme-decernis__headerRow"
        ></tr>
      </ng-container>
    </table>

    <div *ngIf="loading$ | async" class="theme-decernis__tableLoader">
      <mat-spinner diameter="80"></mat-spinner>
    </div>
  </div>

  <mat-paginator
    *ngIf="isPageable"
    [length]="dataSource?.dataLength"
    [pageIndex]="dataSource?.offset / dataSource?.pageSize"
    [pageSize]="dataSource?.pageSize"
  ></mat-paginator>
</form>
