import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-script-loader',
  templateUrl: './script-loader.component.html',
  styleUrls: ['script-loader.component.scss'],
})
export class ScriptComponent implements AfterViewInit {
  @Input() async = false;
  @Input() id: string;
  @Input() src: string;
  @Input() type: string;
  @Input() scriptText: string;
  @ViewChild('script', { static: true }) script: ElementRef;

  convertToScript() {
    const element = this.script.nativeElement;
    const script = document.createElement('script');
    script.type = this.type ? this.type : 'text/javascript';
    if (this.src) {
      script.src = this.src;
    }
    if (this.id) {
      script.id = this.id;
    }
    if (this.async) {
      script.async = this.async;
    }
    if (this.scriptText) {
      script.innerHTML = this.scriptText;
    }
    const parent = element.parentElement;
    parent.parentElement.replaceChild(script, parent);
  }

  ngAfterViewInit() {
    this.convertToScript();
  }
}
