import { Routes } from '@angular/router';

import { DecernisOAuth2Guard, LoginComponent } from 'sso-angular';
import { PageNotFoundComponent } from 'app/components/common/page-not-found/page-not-found.component';

import { ProfileComponent } from 'app/components/profile/profile.component';
import { AnalyticsNewsletterFormComponent } from 'app/components/newsletter/analytics-newsletter-form/analytics-newsletter-form.component';
import { IssueSingleComponent } from 'app/components/issue-single/issue-single.component';
import { AnalyticsNewsletterTableComponent } from './components/newsletter/analytics-newsletter-table/analytics-newsletter-table.component';
import { IssueNewsletterFormComponent } from 'app/components/newsletter/issue-newsletter-form/issue-newsletter-form.component';
import { IssueHomeComponent } from './components/issue-home/issue-home.component';
import { IssueDisplayComponent } from './components/issue-display/issue-display.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ProfilesTableComponent } from './components/profiles-table/profiles-table.component';
import { AuthPermissionService } from './services/auth-permission/auth-permission.service';

const loginProtected = [DecernisOAuth2Guard, AuthPermissionService];

export const appRoutes: Routes = [
  { path: '', redirectTo: 'analytics', pathMatch: 'full' },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: loginProtected,
    runGuardsAndResolvers: 'always',
    data: { name: 'analytics' },
  },
  {
    path: 'profiles',
    canActivate: loginProtected,
    children: [
      {
        path: '',
        component: ProfilesTableComponent,
        pathMatch: 'full',
        canActivate: loginProtected,
      },
      {
        path: 'add',
        component: ProfileComponent,
        pathMatch: 'full',
        canActivate: loginProtected,
      },
      {
        path: ':id',
        component: ProfileComponent,
        canActivate: loginProtected,
      },
    ],
  },
  {
    path: 'issues',
    canActivate: loginProtected,
    children: [
      {
        path: '',
        component: IssueHomeComponent,
        pathMatch: 'full',
        canActivate: loginProtected,
      },
      {
        path: 'add',
        component: IssueSingleComponent,
        pathMatch: 'full',
        canActivate: loginProtected,
      },
      {
        path: 'edit/:id',
        component: IssueSingleComponent,
        pathMatch: 'full',
        canActivate: loginProtected,
      },
      {
        path: 'details/:id',
        component: IssueDisplayComponent,
        pathMatch: 'full',
        canActivate: loginProtected,
      },
      {
        path: 'newsletter',
        canActivate: loginProtected,
        children: [
          {
            path: 'add',
            component: IssueNewsletterFormComponent,
            canActivate: loginProtected,
            pathMatch: 'full',
          },
          {
            path: ':id',
            component: IssueNewsletterFormComponent,
            canActivate: loginProtected,
          },
        ],
      },
    ],
  },
  {
    path: 'newsletter',
    canActivate: loginProtected,
    data: { embedded: false, name: 'newsletter' },
    children: [
      {
        path: '',
        component: AnalyticsNewsletterTableComponent,
        pathMatch: 'full',
        canActivate: loginProtected,
      },
      {
        path: 'add',
        component: AnalyticsNewsletterFormComponent,
        canActivate: loginProtected,
      },
      {
        path: ':id',
        component: AnalyticsNewsletterFormComponent,
        canActivate: loginProtected,
      },
      {
        path: 'analytics',
        canActivate: loginProtected,
        children: [
          {
            path: '',
            component: AnalyticsNewsletterTableComponent,
            pathMatch: 'full',
            canActivate: loginProtected,
          },
          {
            path: 'add',
            component: AnalyticsNewsletterFormComponent,
            canActivate: loginProtected,
            pathMatch: 'full',
          },
          {
            path: ':id',
            component: AnalyticsNewsletterFormComponent,
            canActivate: loginProtected,
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
