import { HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, RestService } from 'sso-angular';
import { LoggedInService } from 'sso-angular';
import { NavbarItems } from '../navbar/navbar.component';

@Component({
  selector: 'app-dom-base',
  styles: [],
  template: `
    <app-connection-warning></app-connection-warning>
    <nav class="navbar navbar-default navbar-inverse navbar-fixed-top">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>

        <div id="navbar" class="navbar-collapse collapse">
          <a
            id="decernis_logo"
            [href]="logoUrl"
            class="navbar-left"
            role="button"
          >
            <div class="logo"></div>
          </a>

          <h2 *ngIf="companyTitle" class="company-title">{{ companyTitle }}</h2>
          <app-navbar [items]="items"></app-navbar>
        </div>
        <div
          id="navbar_login"
          class="btn-group pull-right dropdown"
          title="Please login"
          dropdown
          [isDisabled]="!loggedIn"
        >
          <button
            disabled
            id="split-button"
            type="button"
            class="btn btn-default btn-sm dropdown-toggle"
            dropdownToggle
          >
            <i class="fa fa-user"></i>
            <i *ngIf="!loggedIn">Not logged in</i>
            <span *ngIf="loggedIn">{{ displayedUser || username }}</span>
          </button>

          <ul
            class="dropdown-menu"
            role="menu"
            aria-labelledby="split-button"
            *dropdownMenu
          >
            <li *ngIf="changePasswordUrl" role="menuitem">
              <a (click)="changePassword()" href="javascript:void(0);"
                >Change password</a
              >
            </li>
            <li role="menuitem">
              <a (click)="logout()" href="javascript:void(0);">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="inner-container">
        <ng-content></ng-content>
      </div>
    </div>

    <hr />
    <footer>
      <div>
        &copy;
        <app-date-now [format]="'yyyy'"></app-date-now>
        Decernis, {{ appName }} ||
        <a href="https://decernis.com/disclaimer/"> Disclaimer</a> ||
        <a href="https://decernis.com/terms-of-service/">Terms of Service</a> ||
        <a href="https://decernis.com/privacy-policy/">Privacy Policy</a> ||
        <a href="https://support.decernis.com/hc/en-us/requests/new"
          >Report a problem</a
        >
      </div>
      <div></div>
    </footer>
  `,
})
export class DomBaseComponent implements OnInit, OnDestroy {
  @Input()
  items: NavbarItems[] = [];

  @Input()
  appName = '';

  @Input() companyTitle: string;
  @Input()
  router: Router;

  @Input()
  displayedUser: string;

  loggedIn = false;
  usernameSubscription;
  loggedInSubscription;
  configSubscription;
  username: string = null;
  logoUrl = '/';
  clientConfig = {};
  changePasswordUrl: string = null;

  constructor(
    private auth: AuthService,
    private rest: RestService,
    private loggedInService: LoggedInService,
    @Inject('env') private environment
  ) {
    this.changePasswordUrl = environment.changePasswordUrl;
  }

  logout() {
    this.auth.logout();
  }

  getApplicationConfig(): Observable<HttpResponse<any>> {
    return this.rest.get(this.environment.apiUrls.config, {}, {}, false, true);
  }

  ngOnInit() {
    this.loggedInService.isLoggedIn.subscribe(value => (this.loggedIn = value));
    this.username = this.auth.getUsername();
    this.usernameSubscription = this.auth.currentUsername$.subscribe(
      username => {
        this.username = username;
      }
    );
    this.loggedInSubscription = this.auth.loggedIn$.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
    this.configSubscription = this.getApplicationConfig();
    this.configSubscription.subscribe(response => {
      this.clientConfig = response.body;
      if (
        this.clientConfig['application_config'] &&
        this.clientConfig['application_config']['LOGO_URL']
      ) {
        this.logoUrl = this.clientConfig['application_config']['LOGO_URL'];
      }
    });
  }

  ngOnDestroy() {
    this.usernameSubscription.unsubscribe();
    this.loggedInSubscription.unsubscribe();
  }

  changePassword() {
    window.open(`${this.environment.changePasswordUrl}`);
  }
}
