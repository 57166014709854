<mat-chip
  [selectable]="selectable"
  [removable]="removable"
  [color]="color"
  (removed)="remove()"
  [ngClass]="{ chip: true, 'chip--small': small }"
  [matTooltipDisabled]="!useTooltip"
  [matTooltip]="name"
  [disabled]="disabled"
>
  <mat-icon
    *ngIf="iconName"
    class="theme-decernis__icon chip__icon chip__icon--before"
    [svgIcon]="iconName"
  ></mat-icon>
  {{ formattedValue }}
  <mat-icon
    *ngIf="removable"
    class="theme-decernis__icon chip__icon chip__icon--after"
    matChipRemove
    svgIcon="cancel"
    >cancel</mat-icon
  >
</mat-chip>
