import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  QueryRow,
  OperatorOptions,
  SearchQueryFilterData,
  Chip,
  SelectOption,
  QueryRowOption,
} from 'app/interfaces';
import { MetadataService } from 'app/services/analytics/metadata.service';
import * as _ from 'lodash';
import { ChipCreatorService } from 'app/services/chip-creator.service';

interface SelectOptionExtended extends SelectOption {
  serviceValues: Chip[];
  selectedOperators: Object;
  variableDisabled: boolean;
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() selectOptions: SelectOption[] = [];
  @Input() operatorOptions: OperatorOptions;
  @Input() logicalOperators: Object;
  @Input() rowsOptions: any;
  @Input() queryRowsList: QueryRow[];
  @Output() rowsUpdate: EventEmitter<QueryRow[]> = new EventEmitter();
  @Output() dateIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchInputsAreValid: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  defaultHintMessage = `Use <span class="bold">COMMA</span> or <span class="bold">ENTER</span> at
  the end of the ready text value to add it to the tag list.`;
  initialData: SearchQueryFilterData;
  selectableOptions: SelectOptionExtended[] = [];
  isLoading: boolean;
  title: string;
  tooltip: string;
  removable = true;
  clearOnSelect = true;
  blurOnSelect = true;
  defaultQueryRowConfig: QueryRow = {
    selectedOption: '',
    chips: [],
    logicalOperator: 'all',
    _variableSelectDisabled: true,
    _logicalOperatorDisabled: true,
    _valid: true,
  };
  dateFormControl: FormControl = new FormControl('', Validators.max(1000));

  constructor(
    private metadata: MetadataService,
    private chipCreator: ChipCreatorService
  ) {}

  ngOnInit() {
    if (!this.selectableOptions.length) {
      this.initSelectableOptions(this.selectOptions);
    }
    this.initQueryRowsOptions(this.rowsOptions);
    this.updateAllSelectableOptions();
    this.initDateField();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.queryRowsList) {
      const changedMeta = changes.queryRowsList.previousValue;
      if (changedMeta) {
        changedMeta
          .filter(
            (queryRow: QueryRow) =>
              queryRow.selectedOption && queryRow.chips.length
          )
          .forEach((queryRow: QueryRow) => {
            this.synchronizeMetadataValues(
              queryRow.selectedOption,
              queryRow.chips
            );
          });
      }

      this.updateAllSelectableOptions();
      this.checkAndResetDateControl();
    }
  }

  initQueryRowsOptions(queryRowsOptions: QueryRowOption): void {
    const {
      title,
      tooltip,
      queryRowsList,
      defaultQueryRowConfig,
    } = queryRowsOptions;
    this.initQueryRowBasicConfig(queryRowsList);
    this.title = title;
    this.tooltip = tooltip;

    if (defaultQueryRowConfig) {
      this.defaultQueryRowConfig = defaultQueryRowConfig;
    }
    // Init autocomplete data
    queryRowsList.forEach((row: QueryRow) => {
      if (row.selectedOption) {
        this.addAdditionalOptions(row.selectedOption, row);
        this.checkLogicalOperatorAviability(row);
        this.getServiceData(row.selectedOption, row);
      }
    });
  }

  initQueryRowBasicConfig(rows: QueryRow[]): QueryRow[] {
    const defaultKeys = Object.keys(this.defaultQueryRowConfig);
    return rows.map(row => {
      const rowKeys = Object.keys(row);
      defaultKeys.forEach(defaultKey => {
        if (!rowKeys.includes(defaultKey)) {
          row[defaultKey] = this.defaultQueryRowConfig[defaultKey];
        }
      });
      return row;
    });
  }

  initSelectableOptions(selectOptions: SelectOption[]) {
    this.selectableOptions = selectOptions.map((selectOption: SelectOption) => {
      const selectedOperators = {};

      return {
        ...selectOption,
        serviceValues: [],
        selectedOperators,
        variableDisabled: false,
      };
    });
  }

  initDateField() {
    const dateFieldRow = this.queryRowsList.find(
      row => row.selectedOption === 'date'
    );
    if (dateFieldRow) {
      const value = dateFieldRow.chips.length ? dateFieldRow.chips[0].name : '';
      this.dateFormControl = new FormControl(
        { value, disabled: false },
        Validators.max(1000)
      );
      this.dateFormControl.markAsTouched();
    }
  }

  onSelectVariableChange(value: string, rowId: number): void {
    const row = this.getRow(rowId);
    const oldValue = row.selectedOption;

    if (oldValue) {
      this.synchronizeMetadataValues(oldValue, row.chips);
    }
    this.updateRowObject(value, row);
    this.checkLogicalOperatorAviability(row);
    this.updateAllSelectableOptions();
    this.checkAndResetDateControl();
    this.emit();
    this.getServiceData(value, row);
  }

  checkLogicalOperatorAviability(row: QueryRow) {
    const optionsUsingService = this.selectableOptions
      .filter(option => option.useService)
      .map(option => option.safeName);
    const isDisabled = optionsUsingService.includes(row.selectedOption);
    row._logicalOperatorDisabled = isDisabled;
    row.logicalOperator = isDisabled ? 'any' : 'all';
  }

  getServiceData(selectedOption: string, row: QueryRow): void {
    const singleOption = this.getOptionObj(selectedOption);
    if (singleOption) {
      const chipList = row.chips.map(chip => chip.value);

      if (row._useService && !singleOption.serviceValues.length) {
        this.isLoading = true;
        this.metadata.getData(selectedOption).subscribe(
          (response: any) => {
            let values = [];
            if (response.body.results) {
              values = response.body.results;
            } else {
              values = response.body;
            }

            singleOption.serviceValues = this.formatServiceDataResponse(
              values,
              chipList
            );
            this.isLoading = false;
          },
          errors => {},
          () => this.synchronizeMetadataValues(selectedOption)
        );
      }
    }
  }

  normalizeServiceData(serviceData: any[]): Chip[] {
    return serviceData.map(data => ({
      name: data.name || data.value,
      value: data.value || data.id,
    }));
  }

  synchronizeMetadataValues(
    selectedOption: string,
    deletedValues?: Chip[]
  ): void {
    const singleOption = this.getOptionObj(selectedOption);
    const usedValues = [].concat(
      this.queryRowsList
        .filter(selected => selected.selectedOption === selectedOption)
        .map(option => option.chips.map(chip => chip.name))
        .flat()
    );

    if (deletedValues) {
      singleOption.serviceValues = singleOption.serviceValues
        .concat(deletedValues)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    } else {
      singleOption.serviceValues = singleOption.serviceValues.filter(
        (chip: Chip) => !usedValues.includes(chip.name)
      );
    }
  }

  onLogicalOperatorSelect(value: 'all' | 'any', rowId: number): void {
    const row = this.getRow(rowId);
    row.logicalOperator = value;
    this.emit();
  }

  onChipListUpdate(chipList: Chip[], rowId: number): void {
    const row = this.getRow(rowId);
    row.chips = chipList;
    this.synchronizeMetadataValues(row.selectedOption);
    this.emit();
  }

  onDateSelectChange(value: string, rowId: number): void {
    this.getRow(rowId).optionalText = this.mapDateValueToName(value);
    this.emit();
  }

  onDateNumberInput(value: number, rowId: number): void {
    const row = this.getRow(rowId);
    const name = value.toString();
    row.chips = name ? [this.chipCreator.create(name)] : [];
    this.emit();
  }

  addRow(): void {
    this.queryRowsList.push({ ...this.defaultQueryRowConfig });
    this.emit();
  }

  removeRow(id: number, selectedOption?: string): void {
    const deletedRowChips = this.queryRowsList[id].chips;
    this.queryRowsList.splice(id, 1);
    if (
      selectedOption &&
      !this.isVariableAllowedMultiInstances(selectedOption)
    ) {
      this.updateSelectableOptions(selectedOption);
    }
    if (deletedRowChips.length) {
      this.synchronizeMetadataValues(selectedOption, deletedRowChips);
    }

    this.checkAndResetDateControl();
    this.emit();
  }

  getRow(id: number): QueryRow {
    return this.queryRowsList[id];
  }

  updateAllSelectableOptions(): void {
    this.selectableOptions
      .filter(option => !option.multiInstances || option.onlyOneInstance)
      .map(option => option.safeName)
      .forEach(name => {
        this.updateSelectableOptions(name);
      });
  }

  updateSelectableOptions(optionName: string): void {
    const rowOptions = this.selectableOptions.find(
      options => options.safeName === optionName
    );

    const variableDisabled = rowOptions.onlyOneInstance
      ? this.queryRowsList
          .map((queryRow: QueryRow) => queryRow.selectedOption)
          .includes(optionName)
      : false;

    rowOptions.variableDisabled = variableDisabled;
  }

  updateRowObject(optionName: string, row: QueryRow): void {
    row.selectedOption = optionName;
    row._variableSelectDisabled = false;

    if (row.optionalText) {
      row.optionalText = '';
    }

    row.chips = [];
    row._useService = this.isVariableUsingService(optionName);
    this.addAdditionalOptions(optionName, row);
  }

  addAdditionalOptions(optionName: string, row: QueryRow) {
    const optionConfig = this.selectableOptions.find(
      options => options.safeName === optionName
    );

    if (optionConfig.additionalOptions) {
      row._additionalOptions = optionConfig.additionalOptions;

      // Add initial value to second date select
      if (!row.optionalText && optionName === 'date') {
        row.optionalText =
          optionConfig.additionalOptions.dateSelectValues[0].name;
      }
    }
  }

  checkAndResetDateControl() {
    if (
      !this.queryRowsList.find(queryRow => queryRow.selectedOption === 'date')
    ) {
      this.dateFormControl.setValue('');
    }
  }

  mapDateValueToName(dateValue: string): string {
    return this.selectableOptions
      .find(option => option.safeName === 'date')
      .additionalOptions.dateSelectValues.find(
        option => option.value === dateValue
      ).name;
  }

  mapNameToDateValue(text: string): string {
    return this.selectableOptions
      .find(option => option.safeName === 'date')
      .additionalOptions.dateSelectValues.find(option => option.name === text)
      .value;
  }

  /* Format response based on response structure */

  formatServiceDataResponse(data: any, selectedChips: string[]): Chip[] {
    if (typeof data[0] === 'object') {
      return this.normalizeServiceData(data)
        .filter(
          optionObj =>
            optionObj.value !== '' && !selectedChips.includes(optionObj.value)
        )
        .map(optionObj => {
          const name = optionObj.name ? optionObj.name : optionObj.value;
          return {
            name,
            value: optionObj.value,
          };
        });
    } else if (typeof data[0] === 'string') {
      return data
        .filter(
          optionName => optionName !== '' && !selectedChips.includes(optionName)
        )
        .map(optionName => ({ name: optionName, value: optionName }));
    }
  }

  getTooltipText(optionName: string, additionalText?: string): string {
    return !additionalText
      ? `You’ve already used ${optionName} filter`
      : `You’ve already used ${optionName} for ${additionalText} filter`;
  }

  getOptionalText(rowId): string {
    const row = this.getRow(rowId);
    return row.optionalText
      ? this.mapNameToDateValue(row.optionalText)
      : row._additionalOptions.dateSelectValues[0].value;
  }

  getOptionObj(name: string): SelectOptionExtended {
    return this.selectableOptions.find(option => option.safeName === name);
  }

  isVariableUsingService(name: string): boolean {
    return this.getOptionObj(name).useService;
  }

  isVariableAllowedMultiInstances(name: string): boolean {
    return this.getOptionObj(name).multiInstances;
  }

  isVariableAllowedOnlyOneInstance(name: string): boolean {
    return this.getOptionObj(name).onlyOneInstance;
  }

  removeExtraKeys(queryRowList: QueryRow[]): QueryRow[] {
    const usedParameters = [
      'selectedOption',
      'chips',
      'operator',
      'logicalOperator',
      'optionalText',
      '_valid',
    ];
    return queryRowList.map(queryRow => {
      return Object.keys(queryRow).reduce((params, key) => {
        if (usedParameters.includes(key)) {
          params[key] = queryRow[key];
        }
        return params;
      }, {} as QueryRow);
    });
  }

  onSearchValidUpdate(valid: boolean, id: number) {
    this.queryRowsList[id]._valid = valid;
    this.searchInputsAreValid.emit(this.queryRowsList.every(row => row._valid));
  }

  emit(): void {
    this.searchInputsAreValid.emit(this.queryRowsList.every(row => row._valid));
    this.rowsUpdate.emit(this.removeExtraKeys(this.queryRowsList));
    this.dateIsValid.emit(this.dateFormControl.valid);
  }
}
