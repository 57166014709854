import { SelectOptionsConfig } from '../profile/select-option-config';
import { SearchQueryFilterData } from 'app/interfaces';

const filtersToRemove = ['key_terms', 'date', 'journal', 'lang', 'url'];
const selectOptions = SelectOptionsConfig.filter(
  option => !filtersToRemove.includes(option.safeName)
)
  .map(option => {
    return {
      ...option,
      onlyOneInstance: true,
      multiInstances: false,
    };
  })
  .sort((a, b) => (a.safeName < b.safeName ? -1 : 1));

const FiltersConfig: SearchQueryFilterData = {
  selectOptions,
  logicalOperators: {
    selectedValue: 'all',
    options: [
      {
        value: 'any',
        name: 'Any',
      },
      {
        value: 'all',
        name: 'All',
      },
    ],
  },
  operatorOptions: {
    selectedValue: '',
    options: [
      {
        value: 'include',
        iconName: 'adjust',
      },
      {
        value: 'exclude',
        iconName: 'block',
      },
    ],
  },
  queryRowsOptions: {
    title: '',
    queryRowsList: [
      {
        selectedOption: '',
        chips: [],
        _valid: true,
      },
    ],
  },
};

export { FiltersConfig };
