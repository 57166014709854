<mat-form-field
  class="theme-decernis__form-field"
  appearance="outline"
  (click)="fileInput.click()"
>
  <input
    #placeholderInput
    matInput
    name="file"
    readonly
    [formControl]="fileControl"
    [placeholder]="placeholder"
  />
  <mat-error *ngIf="fileControl.errors?.requiredFileType"
    >This file type is not allowed</mat-error
  >
  <mat-icon
    *ngIf="iconName"
    class="theme-decernis__icon theme-decernis__icon--medium"
    color="primary"
    matSuffix
    [svgIcon]="iconName"
  ></mat-icon>
</mat-form-field>

<input #fileInput hidden type="file" (change)="onFileChange($event)" />
