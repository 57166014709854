<div class="wrapper">
  <div class="form__header">
    <h2>
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--big"
        svgIcon="info"
      ></mat-icon>
      Profiles
    </h2>
  </div>

  <div class="profilesTable__section profilesTable__section--flex">
    <div class="profilesTable__columnPicker">
      <button
        mat-button
        class="theme-decernis__button"
        (click)="openColumnPicker()"
      >
        <mat-icon class="theme-decernis__icon" svgIcon="cog"></mat-icon>
        CHOOSE COLUMNS
      </button>
    </div>
    <button
      class="theme-decernis__button"
      mat-button
      mat-flat-button
      color="primary"
      type="button"
      routerLink="/profiles/add"
    >
      <mat-icon class="theme-decernis__icon" svgIcon="add_circle"></mat-icon>
      Add Profile
    </button>
  </div>

  <app-data-table
    [dataSource]="dataSource"
    [columnConfig]="columnConfig"
    [isPageable]="true"
    [initialSortName]="sortName"
    [initialSortDirection]="sortDirection"
  >
    <ng-template #falseTemplate>
      <mat-icon class="theme-decernis__icon" svgIcon="close"></mat-icon>
    </ng-template>

    <ng-template #public>
      <span>Public</span>
    </ng-template>

    <ng-template #cellTemplate let-element="element" let-col="column">
      <ng-container [ngSwitch]="col.key">
        <ng-container *ngSwitchCase="'full_title'">
          <a
            class="link"
            [routerLink]="['/analytics']"
            [queryParams]="{ profile: element.id }"
          >
            {{ element[col.key] }}
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'advanced_view_selected'">
          <mat-icon
            *ngIf="element.advanced_view_selected; else falseTemplate"
            class="theme-decernis__icon"
            svgIcon="check-no-rounded"
          ></mat-icon>
        </ng-container>

        <ng-container *ngSwitchCase="'private'">
          <span *ngIf="element.private; else public">Private</span>
        </ng-container>
        <ng-container *ngSwitchCase="'shared'">
          <mat-icon
            *ngIf="element.shared; else falseTemplate"
            class="theme-decernis__icon"
            svgIcon="check-no-rounded"
          ></mat-icon>
        </ng-container>

        <ng-container *ngSwitchCase="'manage'">
          <div class="profilesTable__flexWrap">
            <button
              class="theme-decernis__button"
              mat-button
              type="button"
              color="primary"
              (click)="editProfile(element.id)"
              [disabled]="permissionToModify(element)"
            >
              <mat-icon
                class="theme-decernis__icon"
                svgIcon="save-edit"
              ></mat-icon>
              Edit
            </button>
            <button
              class="theme-decernis__button"
              mat-button
              type="button"
              color="primary"
              (click)="deleteProfile(element.id)"
              [disabled]="permissionToModify(element)"
            >
              <mat-icon
                class="theme-decernis__icon"
                svgIcon="delete"
              ></mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </app-data-table>
</div>
