import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconName',
})
export class IconNamePipe implements PipeTransform {
  icons = [
    {
      value: 'include',
      iconName: 'adjust',
    },
    {
      value: 'exclude',
      iconName: 'block',
    },
  ];

  transform(value: any): any {
    return this.getIconName(value);
  }

  getIconName(name) {
    return this.icons
      .filter(iconObj => iconObj.value === name)
      .map(icon => icon.iconName);
  }
}
