import { Injectable } from '@angular/core';
import { SearchQueryFilterData, Chip, QueryRow } from 'app/interfaces';
import { SolrUtilsService } from './solr-utils/solr-utils.service';
import { QueryService } from './analytics/query.service';
import { SelectOptionsConfig } from 'app/components/profile/select-option-config';

interface QueryStringObject {
  queryString: string;
  filterQueryString: string;
  keyTerms: string[];
}

@Injectable({
  providedIn: 'root',
})
export class QueryStringBuilderService {
  constructor(
    private solrQuery: SolrUtilsService,
    private queryService: QueryService
  ) {}

  build(queryFiltersData: SearchQueryFilterData[]): QueryStringObject {
    const queryStringArray = [];
    const filterQueryStringArray = [];

    this.getQueryRowList(queryFiltersData)
      .filter(queryRow => queryRow.selectedOption)
      .forEach(queryRow => {
        const optionObject = SelectOptionsConfig.find(
          option => option.safeName === queryRow.selectedOption
        );
        optionObject.hightlightInQuery
          ? queryStringArray.push(queryRow)
          : filterQueryStringArray.push(queryRow);
      });

    return {
      queryString: this.generateQueryString(queryStringArray),
      filterQueryString: this.generateFilterQueryString(filterQueryStringArray),
      keyTerms: this.generateKeyTerms(queryStringArray),
    };
  }

  generate(queryRows: QueryRow[]) {
    const data = queryRows
      .map(query => {
        const metadata = query.selectedOption;
        const operator = query.operator === 'exclude' ? '*:* NOT ' : ''; // exclude = NOT
        const logicalOperator = query.logicalOperator === 'any' ? 'OR' : 'AND';

        if (metadata !== 'date') {
          if (query.chips.length > 0) {
            return this.solrQuery.concatQueries(
              query.chips.map((chip: Chip) => chip.value),
              metadata,
              logicalOperator,
              operator
            );
          }
        } else {
          const chipDate = query.chips.length
            ? parseInt(query.chips[0].name, 10)
            : 0;
          return this.queryService.dateFromNow(chipDate, query.optionalText);
        }
      })
      .filter(value => value !== undefined);

    return this.solrQuery.fieldTermQuery(data, true, true);
  }

  generateKeyTerms(queryRows: QueryRow[]) {
    return queryRows
      .filter(
        row => row.selectedOption === 'key_terms' && row.operator === 'include'
      )
      .map(row => row.chips.map(chip => chip.value))
      .flat();
  }

  generateQueryString(queryRows: QueryRow[]) {
    return this.generate(queryRows);
  }

  generateFilterQueryString(queryRows: QueryRow[]) {
    return this.generate(queryRows);
  }

  getQueryRowList(queryFiltersData: SearchQueryFilterData[]) {
    return [].concat(
      ...queryFiltersData.map(
        filterData => filterData.queryRowsOptions.queryRowsList
      )
    );
  }
}
