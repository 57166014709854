import { ColumnConfig } from 'app/components/ui/data-table/data-table.interfaces';

const columnConfig: ColumnConfig[] = [
  {
    key: 'event_type',
    name: 'Event type',
    cellTemplate: true,
    style: {
      'min-width': '100px',
    },
  },
  {
    key: 'title',
    name: 'Title',
  },
  {
    key: 'created',
    name: 'Created',
  },
  {
    key: 'is_active',
    name: 'Is active',
    cellTemplate: true,
    style: {
      'min-width': '100px',
    },
  },
  {
    key: 'manage',
    name: 'Manage',
    cellTemplate: true,
  },
];

export { columnConfig };
