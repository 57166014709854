import { HttpResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Location, LocationStrategy } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { ProfilesV2Service } from 'app/services/analytics/profile.service';
import {
  SearchQueryFilterData,
  QueryRow,
  KeyTerm,
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  QueryRowOption,
  Profile,
  Chip,
  Company,
} from 'app/interfaces';
import { Router } from '@angular/router';
import { SearchQueryFiltersInitialConfig } from './search-query-filters-config';
import * as _ from 'lodash';
import { QueryStringBuilderService } from 'app/services/query-string-builder.service';
import { ProfileImportService } from 'app/services/profile/profile-import.service';
import { SolrQueryParserService } from 'app/services/solr-utils/solr-query-parser.service';
import { DialogRedirectService } from 'app/services/dialog/dialog-redirect.service';
import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { CompanyService } from 'app/services/company/company.service';
import { MatSelect } from '@angular/material/select';
import { AuthService } from 'sso-angular';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  dirty: boolean;
  iconName: string;
  pageTitle: string;
  profile: string;
  profileId: string;
  keyTermsString: string;
  keyTermsData: KeyTerm[] = [];
  queryString: string;
  filterQueryString: string;
  searchQueryFiltersData: SearchQueryFilterData[] = [];
  searchQueryPreviewData: QueryRow[] = [];
  searchQueryFilterValid = true;
  advancedViewSelected: Boolean = false;
  advancedViewModified: Boolean = false;
  advancedViewInitial: Boolean = false;
  initialQueryString: string;
  initialFilterQueryString: string;
  initialKeyTerms: string[];
  keyTermsChips: Chip[] = [];
  formData: object;
  resolved = false;
  dateFormValidate: Boolean = true;
  isDataImportedFromFile: boolean;
  panelOpenStateHide: boolean;

  userHasPermissionsToShareProfile = true;
  company$: Observable<Company[]>;
  company: Company[];
  filteredCompany: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  filterCompanyCtrl: FormControl = new FormControl();

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };
  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];
  serverErrors = {};

  @ViewChild('companySelect') companySelect: MatSelect;

  constructor(
    private queryStringBuilder: QueryStringBuilderService,
    private platformStrategy: LocationStrategy,
    private profileService: ProfilesV2Service,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private profileImport: ProfileImportService,
    private solrQueryParser: SolrQueryParserService,
    private dialogRedirect: DialogRedirectService,
    private location: Location,
    private companyService: CompanyService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.company$ = this.companyService
      .get('/analytics/shareprofilecompanies')
      .pipe(shareReplay());

    this.profileForm = this.formBuilder.group({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(1000),
      ]),
      description: new FormControl('', Validators.maxLength(2000)),
      private: new FormControl({ value: false, disabled: true }),
      shared: new FormControl({ value: false, disabled: false }),
      shared_to_companies: new FormControl([]),
    });

    this.isDataImportedFromFile = window.history.state.import;
    if (!this.isDataImportedFromFile) {
      this.profileId = this.route.snapshot.params.id;
      if (this.profileId) {
        this.profileService.detail(this.profileId).subscribe(
          (response: HttpResponse<any>) => {
            this.setInitialVariables(true, response.body);
          },
          error => {
            if (error.status === 404) {
              this.dialogRedirect.callDialog('/analytics');
            }

            this.resolved = true;
          },
          () => {
            this.profileForm.patchValue(this.formData);
            this.resolved = true;
          }
        );
      } else {
        this.setInitialVariables(false);
        this.resolved = true;
      }
    } else {
      this.profileImport.fileImported$.subscribe(file => {
        this.setInitialVariables(true, file.profile);
        this.setPageTitle(`Import ${file.profile.full_title}`);
        this.profileForm.patchValue(this.formData);
        this.profileForm.get('private').enable();
        this.profileForm.markAsDirty();
        this.resolved = true;
      });
    }
    this.userHasPermissionsToShareProfile = this.auth.checkPermission(
      'analytics.share_profile_perm'
    );

    this.onFormChanges();
  }

  onFormChanges(): void {
    this.profileForm.get('shared_to_companies').valueChanges.subscribe(() => {
      if (this.profileForm.dirty) {
        this.profileForm
          .get('shared')
          .patchValue(
            this.profileForm.get('shared_to_companies').value.length !== 0
          );
      }
    });
  }

  setInitialVariables(isEditPage?: boolean, profileData?: Profile) {
    if (isEditPage) {
      this.setCompanies(false);

      this.profile = profileData.full_title;
      this.searchQueryFiltersData = this.setInitialQueryFiltersData(
        profileData.structure
      );
      this.searchQueryPreviewData = this.getSearchPreviewData();
      this.keyTermsData = this.formatKeyTermInput(profileData.key_terms_string);
      this.keyTermsChips = profileData.key_terms.map(term => ({
        name: term,
        value: term,
      }));
      this.queryString = profileData.query_string;
      this.initialQueryString = profileData.query_string;
      this.filterQueryString = profileData.filter_query_string;
      this.initialFilterQueryString = profileData.filter_query_string;
      this.advancedViewInitial = profileData.advanced_view_selected;
      this.advancedViewSelected = profileData.advanced_view_selected;
      this.initialKeyTerms = profileData.key_terms;

      profileData.username === localStorage.getItem('username')
        ? this.profileForm.get('private').enable()
        : this.profileForm.get('private').disable();

      this.formData = {
        title: this.profile,
        description: profileData.description || '',
        private: profileData.private,
        shared: profileData.shared,
        shared_to_companies: profileData.shared_to_companies,
      };

      this.iconName = 'edit';
      this.setPageTitle('Edit ' + this.profile);
    } else {
      this.profileForm.get('private').enable();
      this.searchQueryFiltersData = this.setInitialQueryFiltersData();
      this.initialQueryString = '';
      this.filterQueryString = '';
      this.iconName = 'add';
      this.setPageTitle('Add Automation Profile');
      this.setCompanies(false);
    }
  }

  setInitialQueryFiltersData(
    searchConfig?: QueryRowOption[]
  ): SearchQueryFilterData[] {
    const mappedConfig = _.cloneDeep(SearchQueryFiltersInitialConfig);
    if (searchConfig) {
      return mappedConfig.map((searchFilterData, id) => ({
        ...searchFilterData,
        queryRowsOptions: {
          ...searchConfig[id],
          defaultQueryRowConfig:
            searchFilterData.queryRowsOptions.defaultQueryRowConfig,
        },
      }));
    } else {
      return mappedConfig;
    }
  }

  /**
   * Basic/Advanced button
   */
  changeQueryType(advanced?: boolean): void {
    this.advancedViewSelected = advanced;

    /* When switching query type we alway reset non-chips values in inputs so they are all become valid */
    this.searchQueryFilterValid = true;
    this.setQueryRowsValid();
  }

  /**
   * Back button
   */
  goBack() {
    this.platformStrategy.back();
  }

  setPageTitle(title) {
    this.pageTitle = title;
  }

  navigateToPreviousPage() {
    this.location.back();
  }

  onSubmit() {
    const queryRowOptions = this.searchQueryFiltersData.map(
      searchFilterData => searchFilterData.queryRowsOptions
    );

    const isAdvancedProfile =
      this.advancedViewSelected || this.advancedViewModified;

    const queryString = this.queryString || '';
    let keyTerms = [];
    if (isAdvancedProfile) {
      keyTerms = this.keyTermsChips.map(term => term.value);
    } else {
      keyTerms = this.solrQueryParser.generateKeyTermsList(queryString);
    }
    const keyTermsString = this.keyTermsString || '';
    const data = {
      title: this.profileForm.value.title,
      description: this.profileForm.value.description || '',
      private: this.profileForm.value.private,
      shared: this.profileForm.value.shared,
      query_string: queryString,
      filter_query_string: this.filterQueryString || '',
      key_terms: keyTerms,
      structure: queryRowOptions,
      key_terms_string: keyTermsString,
      advanced_view_selected: isAdvancedProfile,
      shared_to_companies: this.profileForm.value.shared_to_companies,
    };

    if (!this.profile || this.isDataImportedFromFile) {
      this.createProfile(data);
    } else {
      this.updateProfile(data);
    }
  }

  /**
   * Function loops over selected values for metadata in basic view
   * @return Formatted and escaped SOLR query
   */
  buildQueryString() {
    const {
      queryString,
      filterQueryString,
      keyTerms,
    } = this.queryStringBuilder.build(this.searchQueryFiltersData);
    this.queryString = queryString;
    this.filterQueryString = filterQueryString;
    this.keyTermsChips = keyTerms.map(term => ({ name: term, value: term }));
  }

  formatKeyTermInput(keyTerms: string): KeyTerm[] {
    if (keyTerms) {
      const keyTermsArr = keyTerms.split('\n');
      return keyTermsArr.map(term => ({
        key_term: term,
        synonym: '',
        url: '',
      }));
    }
    return [];
  }

  queryRowPreviewRemove(removedRow: QueryRow) {
    const { sectionId, id } = removedRow[0];
    const filtered = this.searchQueryFiltersData[
      sectionId
    ].queryRowsOptions.queryRowsList.filter((queryRow, index) => index !== id);
    this.searchQueryFiltersData[
      sectionId
    ].queryRowsOptions.queryRowsList = filtered;
    this.searchQueryPreviewData = this.getSearchPreviewData();
    this.updateQueryString();
  }

  queryRowsUpdate(queryRowList: QueryRow[], index: number): void {
    this.searchQueryFiltersData[
      index
    ].queryRowsOptions.queryRowsList = queryRowList;
    this.searchQueryPreviewData = this.getSearchPreviewData();
    this.updateQueryString();
  }

  updateQueryString(): void {
    this.buildQueryString();
    this.initialQueryString = this.queryString;
    this.initialFilterQueryString = this.filterQueryString;
    this.initialKeyTerms = this.keyTermsChips.map(term => term.value);
    this.dirty = true;
  }

  getSearchPreviewData(): QueryRow[] {
    return this.searchQueryFiltersData
      .map((filterData, sectionId) =>
        filterData.queryRowsOptions.queryRowsList.map((queryRow, id) => ({
          ...queryRow,
          sectionId,
          id,
        }))
      )
      .flat();
  }

  dateIsValid(event: boolean): void {
    this.dateFormValidate = event;
  }

  keyTermSelectOutput(keyTerms: KeyTerm[]): void {
    const terms = keyTerms.map((term: KeyTerm) => term.key_term);
    this.keyTermsString = terms.join('\n');
    this.dirty = true;
  }

  createProfile(data): void {
    let msg = '';
    const confirmModal = this.callConfirmDialog(true);
    let dialogTitle = this.errorDialogTitle;
    let optionActions = this.defaultDialogOptionActions;
    confirmModal
      .afterClosed()
      .subscribe(({ confirmed }: AdditionalDialogData) => {
        if (confirmed) {
          this.profileService.create(data).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body.id) {
                msg = `Profile ${response.body.title} created`;
                this.profileId = response.body.id;
                dialogTitle = this.successDialogTitle;
                optionActions = [
                  {
                    actionAfterClose: true,
                    cssClass: 'mat-stroked-button mat-primary',
                    description: 'Close',
                  },
                ];
              } else if (response.status !== 400) {
                msg = `Unexpected error: ${response.status} ${response.statusText}`;
              }
            },
            errors => {
              this.validateTitle(errors);
              msg = DialogComponent.parseErrorResponse(errors);
              this.callDialog(msg, dialogTitle, optionActions);
            },
            () => {
              this.callDialog(msg, dialogTitle, optionActions);
              this.router.navigate(['/analytics'], {
                queryParams: { profile: this.profileId },
              });
            }
          );
        }
      });
  }

  validateTitle(errors): void {
    for (const key in errors.error) {
      if (key === 'title') {
        this.serverErrors[key] = JSON.stringify(
          ''.concat(errors.error[key].replace(/(\r\n|\n|\r)/gm, ''))
        ).replace(new RegExp('"', 'g'), '');
        this.profileForm.controls.title.setErrors({
          serverValidationError: true,
        });
      }
    }
  }

  updateProfile(data): void {
    let msg = '';
    const confirmDialog = this.callConfirmDialog();
    let dialogTitle = this.errorDialogTitle;
    let optionActions = this.defaultDialogOptionActions;
    confirmDialog
      .afterClosed()
      .subscribe(({ confirmed }: AdditionalDialogData) => {
        if (confirmed) {
          this.profileService.updateProfile(this.profileId, data).subscribe(
            (response: HttpResponse<any>) => {
              if (response.body.id) {
                msg = 'Profile ' + response.body.title + ' updated';
                dialogTitle = this.successDialogTitle;
                optionActions = [
                  {
                    actionAfterClose: true,
                    cssClass: 'mat-stroked-button mat-primary',
                    description: 'Close',
                  },
                ];
              } else if (response.status !== 400) {
                msg = `Unexpected error: ${response.status} ${response.statusText}`;
              }
            },
            errors => {
              this.validateTitle(errors);
              msg = DialogComponent.parseErrorResponse(errors);
              this.callDialog(msg, dialogTitle, optionActions);
            },
            () => {
              this.callDialog(msg, dialogTitle, optionActions);
              this.router.navigate(['/analytics'], {
                queryParams: { profile: this.profileId },
              });
            }
          );
        }
      });
  }

  onQueryStringInput(value: string): void {
    this.advancedViewModified = !(value === this.initialQueryString);
    this.queryString = value;
    this.profileForm.markAsDirty();
  }

  onQueryFilterStringInput(value: string): void {
    this.advancedViewModified = !(value === this.initialFilterQueryString);
    this.filterQueryString = value;
    this.profileForm.markAsDirty();
  }

  onAdvancedChipListUpdate(chips: Chip[]): void {
    this.advancedViewModified = !_.isEqual(
      _.sortBy(chips.map(term => term.value)),
      _.sortBy(this.initialKeyTerms)
    );
    this.profileForm.markAsDirty();
  }

  setQueryRowsValid(): void {
    this.searchQueryFiltersData.forEach(data =>
      data.queryRowsOptions.queryRowsList.forEach(row => (row._valid = true))
    );
  }

  onFiltersValidationUpdate(valid: boolean) {
    let allValid = true;

    if (this.advancedViewSelected) {
      allValid = valid;
    } else {
      allValid = this.searchQueryFiltersData.every(data =>
        data.queryRowsOptions.queryRowsList.every(row => row._valid)
      );
    }

    this.searchQueryFilterValid = allValid;
  }

  /**
   * Function to call confirm dialog box
   * @return reference to dialog box object
   */
  callConfirmDialog(createNew?: boolean) {
    const message = 'Are you sure you want to save the profile?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    if (createNew) {
      title = {
        iconName: 'add',
        text: 'Save profile',
      };
    } else {
      title = {
        iconName: 'edit',
        text: 'Update profile',
      };
    }
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionActions);
  }

  onCancelClick() {
    this.isDirty() ? this.callCancelDialog() : this.navigateToPreviousPage();
  }
  /**
   * Function to call cancel dialog box
   */
  callCancelDialog(): void {
    const message = 'Are you sure you want to discard changes in the profile?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    title = {
      iconName: 'cancel',
      text: 'Cancel',
    };
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Return to profile',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Yes, discard changes',
      },
    ];
    const ref = this.callDialog(message, title, optionActions);
    ref.afterClosed().subscribe(({ confirmed }: AdditionalDialogData) => {
      if (confirmed) {
        this.navigateToPreviousPage();
      }
    });
  }

  /**
   * Function to call dialog box
   * @message: string
   * @title: DialogTitle object
   * @optionActions: DialogOptionActions object
   * @return reference to dialog box
   */
  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  /**
   * Function to check is form is dirty
   * @return boolean
   */
  isDirty() {
    return this.dirty || this.profileForm.dirty;
  }

  isValidAndDirty() {
    return (
      this.isDirty() &&
      this.profileForm.valid &&
      this.dateFormValidate &&
      this.searchQueryFilterValid
    );
  }

  setCompanies(isAddPage?: boolean) {
    this.company$.subscribe(company => {
      this.company = company;
      this.filteredCompany.next(company);
      if (isAddPage) {
        this.setCurrentCompany();
      }
    });

    this.filterCompanyCtrl.valueChanges.subscribe(() => {
      this.filterCompany();
    });

    this.filteredCompany.pipe(take(1)).subscribe(() => {
      this.companySelect.compareWith = (a: Company, b: Company) =>
        a.id === b.id;
    });
  }

  setCurrentCompany() {
    const currentCompany = localStorage.getItem('current_company');
    const companyOpt = this.company.find(
      company => company.name === currentCompany
    );
    if (companyOpt) {
      this.profileForm.controls.shared_to_companies.setValue([companyOpt]);
    }
  }

  filterCompany() {
    if (!this.company) {
      return;
    }

    let search = this.filterCompanyCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.company.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCompany.next(
      this.company.filter(
        company => company.name.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
