import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'env/environment';

@Component({
  selector: 'app-piwik',
  templateUrl: './piwik.component.html',
  styleUrls: [],
})
export class PiwikScriptComponent implements OnInit {
  @Input() userId: string;
  @Input() customerId: string;
  @Input() siteId: string;

  piwikScript: string;

  constructor() {}

  ngOnInit() {
    this.piwikScript = `var _paq = _paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['setCustomDimension', 1, '${this.userId}']);
    _paq.push(['setCustomDimension', 2, '${this.customerId}']);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="//${environment.piwikServerUrl}/";
      _paq.push(['setTrackerUrl', u+'piwik.php']);
      _paq.push(['setSiteId', ${this.siteId}]);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
    })();`;
  }
}
