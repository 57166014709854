import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

/*
Reset route service provides an option to call a callback when user clicks on the same route as currently selected,
e.g. user is on Analytics page and click "Analytics" again
*/

@Injectable({
  providedIn: 'root',
})
export class ResetRouteService implements OnDestroy {
  navigationSubscription: Subscription;

  constructor(private router: Router) {}

  configure(route: string, callback: () => void) {
    if (!this.navigationSubscription) {
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          if (e.url === route && e.urlAfterRedirects === route) {
            callback();
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
