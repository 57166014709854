import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  Chip,
  Profile,
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  SearchQueryFilterData,
  QueryRow,
} from 'app/interfaces';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { KeyTermsSelectComponent } from '../key-terms-select/key-terms-select.component';
import { ProfilesV2Service } from 'app/services/analytics/profile.service';
import { ProfileExportService } from 'app/services/profile/profile-export.service';
import { ProfileImportService } from 'app/services/profile/profile-import.service';
import { shareReplay } from 'rxjs/operators';
import { SnackBarService } from 'app/services/snackbar.service';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../ui/dialog/dialog.component';
import { AuthService } from 'sso-angular';

@Component({
  selector: 'app-analytics-search-filters',
  templateUrl: './analytics-search-filters.component.html',
  styleUrls: ['./analytics-search-filters.component.scss'],
})
export class AnalyticsSearchFiltersComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() initialKeyTerms: string[];
  @Input() filtersConfig: SearchQueryFilterData;
  @Input() isSearchLocked: boolean;
  @ViewChild('searchKeyTerms', { static: true })
  searchKeyTerms: KeyTermsSelectComponent;

  resetProfilesSubject: Subject<boolean> = new Subject<boolean>();
  keyTermsComponent: KeyTermsSelectComponent;
  keyTerms: string[] = [];
  profiles: Profile[];
  profiles$: Observable<Profile[]> = this.getProfiles().pipe(shareReplay(1));
  filters: QueryRow[] = [];
  selectedColumnID: number[] = [0, 1];
  selectsValid: boolean[] = [true, true];
  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };
  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];

  userHasPermissionsToShareProfile = false;
  selectedProfile: Profile;

  constructor(
    private profilesService: ProfilesV2Service,
    private profileExportService: ProfileExportService,
    private profileImportService: ProfileImportService,
    private snackBar: SnackBarService,
    private router: Router,
    private dialog: MatDialog,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.userHasPermissionsToShareProfile = this.auth.checkPermission(
      'analytics.share_profile_perm'
    );
  }

  setSelectedProfile(profile: Profile) {
    this.selectedProfile = profile;
  }

  selectColumn(id: number) {
    this.selectedColumnID = [id];

    if (id === 1) {
      /* There is no validation on second column */
      this.parentForm.setErrors(null);
    } else {
      this.validateSearchInputs();
    }
  }

  reset() {
    this.searchKeyTerms.reset();
    this.keyTerms = [];
    this.filters = [];
    this.resetProfilesSubject.next(true);
    this.selectsValid = [true, true];
  }

  keyTermsEvent(chips: Chip[]) {
    this.keyTerms = _.uniq(chips.map(term => term.name));
  }

  rowsUpdate(tags: QueryRow[]) {
    this.filters = tags.filter(tag => tag.selectedOption && tag.chips.length);
  }

  getProfiles(args?: Object): Observable<Profile[]> {
    return this.profilesService.getProfiles({ limit: 20, ...args });
  }

  exportProfile(profileId): void {
    this.profilesService.detail(profileId).subscribe(response => {
      const profile = response.body as Profile;
      this.profileExportService.export(profile);
    });
  }

  importProfile(): void {
    this.profileImportService.import().subscribe(
      () => {
        this.router.navigateByUrl(`/profiles/import`, {
          state: { import: true },
        });
      },
      error => {
        if (error === 'not-valid-profile') {
          this.snackBar.open(`Profile is not valid`);
        }
      }
    );
  }

  callDeleteProfile() {
    let msg = '';
    const confirmDialog = this.callConfirmDialog();
    let dialogTitle = this.errorDialogTitle;
    let optionActions = this.defaultDialogOptionActions;

    confirmDialog
      .afterClosed()
      .subscribe(({ confirmed }: AdditionalDialogData) => {
        if (confirmed) {
          this.profilesService
            .delete(this.parentForm.controls.profile.value)
            .subscribe(
              response => {
                if (response.ok) {
                  msg = `Profile has been deleted`;
                  dialogTitle = this.successDialogTitle;
                  this.profiles$ = this.getProfiles().pipe(shareReplay(1));
                  optionActions = [
                    {
                      actionAfterClose: true,
                      cssClass: 'mat-stroked-button mat-primary',
                      description: 'Close',
                      id: 'delete-dialog',
                    },
                  ];
                } else {
                  msg = `Unexpected error: ${response.status} ${response.statusText}`;
                  this.callDialog(msg, dialogTitle, optionActions);
                }
              },
              errors => {
                msg = DialogComponent.parseErrorResponse(errors);
                this.callDialog(msg, dialogTitle, optionActions);
              },
              () => {
                this.callDialog(msg, dialogTitle, optionActions);
                this.router.navigate(['/analytics']);
              }
            );
        }
      });
  }

  searchInputsAreValid(valid: boolean, id: number) {
    this.selectsValid[id] = valid;
    this.validateSearchInputs();
  }

  validateSearchInputs() {
    const error = this.selectsValid.every(field => field)
      ? null
      : { invalid: true };
    this.parentForm.setErrors(error);
  }

  callConfirmDialog() {
    const message = 'Are you sure you want to delete profile permanently?';
    const title: DialogTitle = {
      iconName: 'delete',
      text: 'Delete profile',
    };
    const optionalActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionalActions);
  }

  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  permissionToModify(profileId) {
    return !(
      this.selectedProfile &&
      this.selectedProfile.shared === true &&
      !this.userHasPermissionsToShareProfile
    );
  }
}
