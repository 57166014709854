<div class="key-term-datatable">
  <h3>
    Key terms
    <mat-icon
      class="theme-decernis__icon theme-decernis__icon--small"
      color="primary"
      svgIcon="info"
      matTooltip="Key Term may be CAS number or substance name. The Key Terms automatically expand the query with synonyms. When used in conjunction with query string ‘AND’ operator is used between user supplied query and key terms synonyms."
      [matTooltipPosition]="tooltipPosition"
      matTooltipClass="theme-decernis__tooltip"
    ></mat-icon>
  </h3>

  <div class="key-term-datatable__header">
    <div class="row">
      <div class="col-xs-6">
        <app-key-term-dropdown
          (selectChange)="optionSelected($event)"
          [clearOnSelect]="clearOnSelect"
          [blurOnSelect]="blurOnSelect"
        >
        </app-key-term-dropdown>
      </div>

      <div class="col-xs-6"></div>
    </div>
  </div>
  <div
    *ngIf="selectedKeyTerms && selectedKeyTerms.length; else emptyMessage"
    class="key-term-datatable__body"
  >
    <span class="key-term-datatable__message">{{
      selectedKeyTerms.length | i18nPlural: keyTermMapping
    }}</span>
    <mat-table
      #selectedKeyTermsTable
      [dataSource]="selectedKeyTerms"
      class="key-term-datatable__table"
    >
      <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <span class="bold">{{ i + 1 }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="key_term">
        <mat-header-cell *matHeaderCellDef> Key Terms </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.key_term }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <button
            type="button"
            class="theme-decernis__button"
            mat-button
            (click)="deleteRow(i)"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="delete"></mat-icon>
            Delete
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedTableColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedTableColumns"></mat-row>
    </mat-table>
  </div>

  <ng-template #emptyMessage>
    <span class="key-term-datatable__message key-term-datatable__message--alt"
      >There are no Key terms added to this profile.</span
    >
  </ng-template>
</div>
