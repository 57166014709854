import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputOptions } from 'app/interfaces';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() inputOptions: InputOptions;
  @Output() fileOutput: EventEmitter<File> = new EventEmitter();
  @Output() isFileValid: EventEmitter<boolean> = new EventEmitter();
  placeholder: string;
  iconName: string;
  validFileTypes: string[] = [];
  fileControl: FormControl = new FormControl('');

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('placeholderInput', { static: true }) placeholderInput: ElementRef;

  constructor() {}

  ngOnInit() {
    const { placeholder, iconName, validFileTypes } = this.inputOptions;
    this.placeholder = placeholder;
    this.iconName = iconName;

    if (validFileTypes) {
      this.validFileTypes = validFileTypes;
    }

    this.isFileValid.emit(false);
  }

  onFileChange(event: Event) {
    const fileInput = this.fileInput.nativeElement as HTMLInputElement;
    const placeholder = this.placeholderInput.nativeElement as HTMLInputElement;
    const file = fileInput.files[0];

    if (fileInput.files.length) {
      placeholder.value = file.name;
    } else {
      placeholder.value = '';
    }

    const valid = this.isValidFileType(file.name);
    this.isFileValid.emit(valid);

    if (valid) {
      this.fileOutput.emit(file);
      this.fileControl.setErrors(null);
    } else {
      this.fileControl.setErrors({ requiredFileType: true });
    }
  }

  isValidFileType(fileName: string) {
    const extension = fileName.split('.')[1].toLowerCase();
    return this.validFileTypes.includes(extension);
  }
}
