<app-loading [show]="!resolved"></app-loading>

<div class="profile-form mat-typography wrapper">
  <div class="form__header">
    <h2>
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--big"
        svgIcon="{{ iconName }}"
      ></mat-icon>
      {{ pageTitle }}
    </h2>
  </div>
  <div class="form__section">
    <div>
      <mat-expansion-panel
        class="theme-decernis__expansionPanel"
        (opened)="panelOpenStateHide = true"
        (closed)="panelOpenStateHide = false"
      >
        <mat-expansion-panel-header collapsedHeight="35px">
          <mat-panel-title>
            {{ panelOpenStateHide ? 'Hide' : 'Show' }} Help
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="theme-decernis__expansionPanelSection">
          <h4 class="theme-decernis__expansionPanelTitle">
            Standard steps to create a profile:
          </h4>
          <ol>
            <li>
              <h4 class="theme-decernis__exampleTitle">
                Populate your search query with key terms from Decernis lexicon
                such as:
              </h4>
            </li>
            <ul>
              <li>
                <h5 class="theme-decernis__exampleTitle">
                  Chemicals (a CAS number or traditional name) or
                </h5>
              </li>
              <li>
                <h5 class="theme-decernis__exampleTitle">
                  Concepts (a toxicological effect or a packaging material type
                  or a food category) that may be:
                </h5>
              </li>
              <ul>
                <li>
                  <h5 class="theme-decernis__exampleTitle">
                    selected from the dropdown or
                  </h5>
                </li>
                <li>
                  <h5 class="theme-decernis__exampleTitle">
                    imported as a CSV
                  </h5>
                </li>
              </ul>
            </ul>
            <li>
              <h4 class="theme-decernis__exampleTitle">
                Please further restrict your search by activating any of the
                following filters:
              </h4>
            </li>
            <ul>
              <li><h5 class="theme-decernis__exampleTitle">agency</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">category</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">country</h5></li>
              <li>
                <h5 class="theme-decernis__exampleTitle">
                  date (time interval)
                </h5>
              </li>
              <li><h5 class="theme-decernis__exampleTitle">domain</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">group</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">journal</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">language</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">region</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">ruletype</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">text</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">title</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">type</h5></li>
              <li><h5 class="theme-decernis__exampleTitle">URL</h5></li>
            </ul>
            <li>
              <h4 class="theme-decernis__exampleTitle">Consider that:</h4>
            </li>
            <ul>
              <li>
                <h5 class="theme-decernis__exampleTitle">
                  you may INCLUDE/EXCLUDE items selected in one field or
                </h5>
              </li>
              <li>
                <h5 class="theme-decernis__exampleTitle">
                  you may include/exclude ANY/ALL items selected in one field.
                </h5>
              </li>
            </ul>
          </ol>
        </div>
      </mat-expansion-panel>
    </div>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-4 col-xs-12">
          <mat-form-field
            class="theme-decernis__form-field"
            appearance="outline"
            hideRequiredMarker
          >
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" name="title" />
            <mat-error
              align="end"
              *ngIf="profileForm.controls.title.errors?.serverValidationError"
              >{{ serverErrors['title'] }}</mat-error
            >
            <mat-error
              align="end"
              *ngIf="profileForm.controls.title.errors?.required"
              >Title is required</mat-error
            >
            <mat-error
              align="end"
              *ngIf="profileForm.controls.title.errors?.maxlength"
              >Title is too long</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 col-xs-12">
          <mat-form-field
            class="theme-decernis__form-field"
            appearance="outline"
          >
            <mat-label
              >Description <span class="italic">(Optional)</span></mat-label
            >
            <input matInput formControlName="description" name="description" />
            <mat-error
              align="end"
              *ngIf="profileForm.controls.description.errors?.maxlength"
              >Description is too long</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 col-xs-12">
          <mat-checkbox
            formControlName="private"
            name="private"
            color="primary"
            class="theme-decernis__checkbox"
            >Make this profile private</mat-checkbox
          >
          <mat-icon
            *ngIf="profileForm.controls.private.disabled"
            class="theme-decernis__icon theme-decernis__icon--small form__tooltipIcon"
            color="primary"
            svgIcon="info"
            matTooltip="Only owner can change profile type"
            matTooltipClass="theme-decernis__tooltip"
          ></mat-icon>
        </div>
      </div>
      <ng-container *ngIf="userHasPermissionsToShareProfile">
        <div class="row">
          <div class="col-md-8 col-xs-12">
            <mat-checkbox
              formControlName="shared"
              name="shared"
              color="primary"
              class="theme-decernis__checkbox"
              >Share this profile</mat-checkbox
            >
            <mat-icon
              class="theme-decernis__icon theme-decernis__icon--small form__tooltipIcon"
              color="primary"
              svgIcon="info"
              matTooltip="Only user with correct rights can share profile"
              matTooltipClass="theme-decernis__tooltip"
            ></mat-icon>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 col-xs-12">
            <div
              class="form__section form__section--withTitle form__section--big"
            >
              <h3 class="form__title">Shared to Companies</h3>

              <mat-form-field
                class="theme-decernis__form-field"
                appearance="outline"
              >
                <mat-label>Choose company</mat-label>
                <mat-select
                  #companySelect
                  class="theme-decerins__select"
                  panelClass="theme-decernis__selectPanel"
                  formControlName="shared_to_companies"
                  multiple
                >
                  <app-select-search
                    [formControl]="filterCompanyCtrl"
                  ></app-select-search>
                  <mat-option
                    *ngFor="let company of filteredCompany | async"
                    [value]="company"
                  >
                    {{ company.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="form__section form__section--big form__wrap">
        <h2>Search Query</h2>

        <div>
          <div class="form__wrap">
            <p class="form__helper mat-body-strong">
              Query type
              <mat-icon
                class="theme-decernis__icon theme-decernis__icon--small"
                color="primary"
                svgIcon="info"
                matTooltip="Basic query type allow you to quickly set up the simple profile.
              Although you need the complex query, choose advanced type and configure it manually."
                matTooltipClass="theme-decernis__tooltip"
              ></mat-icon>
            </p>

            <div class="form__button-group theme-decernis__button-group">
              <button
                [ngClass]="{
                  'theme-decernis__button': true,
                  'mat-flat-button': !advancedViewSelected,
                  'mat-stroked-button': advancedViewSelected
                }"
                mat-flat-button
                type="button"
                [color]="!advancedViewSelected ? 'primary' : 'accent'"
                [disabled]="advancedViewInitial || advancedViewModified"
                (click)="changeQueryType()"
              >
                Basic
              </button>

              <button
                mat-button
                type="button"
                [ngClass]="{
                  'theme-decernis__button': true,
                  'mat-flat-button': advancedViewSelected,
                  'mat-stroked-button': !advancedViewSelected
                }"
                [color]="advancedViewSelected ? 'primary' : 'accent'"
                (click)="changeQueryType(true)"
              >
                Advanced
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="form__section" *ngIf="!advancedViewSelected">
        <ng-container *ngIf="resolved">
          <app-search-query-filters
            *ngFor="let sectionData of searchQueryFiltersData; let i = index"
            [selectOptions]="sectionData.selectOptions"
            [operatorOptions]="sectionData.operatorOptions"
            [logicalOperators]="sectionData.logicalOperators"
            [queryRowsOptions]="sectionData.queryRowsOptions"
            [queryRowsList]="sectionData.queryRowsOptions.queryRowsList"
            (queryRowsUpdate)="queryRowsUpdate($event, i)"
            (dateIsValid)="dateIsValid($event)"
            (searchInputsAreValid)="onFiltersValidationUpdate($event, i)"
          ></app-search-query-filters>

          <app-search-preview
            [searchQueryFields]="searchQueryPreviewData"
            (queryRowUpdate)="queryRowPreviewRemove($event)"
          >
          </app-search-preview>
        </ng-container>
      </div>

      <div class="form__section" *ngIf="advancedViewSelected">
        <div>
          <mat-expansion-panel
            class="theme-decernis__expansionPanel"
            (opened)="panelOpenStateLegend = true"
            (closed)="panelOpenStateLegend = false"
          >
            <mat-expansion-panel-header collapsedHeight="35px">
              <mat-panel-title>
                {{ panelOpenStateLegend ? 'Hide' : 'Show' }} legend
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="theme-decernis__expansionPanelSection">
              <h4 class="theme-decernis__expansionPanelTitle">Variables</h4>
              <ul class="theme-decernis__expansionPanelList">
                <li class="theme-decernis__expansionPanelItem">Title</li>
                <li class="theme-decernis__expansionPanelItem">Text</li>
                <li class="theme-decernis__expansionPanelItem">Category</li>
                <li class="theme-decernis__expansionPanelItem">Agency</li>
                <li class="theme-decernis__expansionPanelItem">Domain</li>
                <li class="theme-decernis__expansionPanelItem">Country</li>
                <li class="theme-decernis__expansionPanelItem">Region</li>
                <li class="theme-decernis__expansionPanelItem">Language</li>
                <li class="theme-decernis__expansionPanelItem">Date</li>
                <li class="theme-decernis__expansionPanelItem">URL</li>
                <li class="theme-decernis__expansionPanelItem">Source</li>
                <li class="theme-decernis__expansionPanelItem">Ruletype</li>
              </ul>
              <div class="theme-decernis__example">
                <h5 class="theme-decernis__exampleTitle">Examples:</h5>
                <p class="theme-decernis__exampleText">
                  text:<span class="green">"food additives"</span> /
                  domain:<span class="green">"www.federalregister.gov"</span> /
                  text: <span class="green">"GMO labeling"</span
                  ><span class="orange">~10</span>(Vinicity query) /
                  date:[NOW-<span class="green">720</span>DAY/DAY TO NOW]
                </p>
              </div>
            </div>
            <div class="theme-decernis__expansionPanelSection">
              <h4 class="theme-decernis__expansionPanelTitle">Operators</h4>
              <ul class="theme-decernis__expansionPanelList">
                <li class="theme-decernis__expansionPanelItem">AND</li>
                <li class="theme-decernis__expansionPanelItem">OR</li>
                <li class="theme-decernis__expansionPanelItem">NOT/-</li>
              </ul>
              <div class="theme-decernis__example">
                <h5 class="theme-decernis__exampleTitle">Examples:</h5>
                <p class="theme-decernis__exampleText">
                  text:"variables value"
                  <span class="blue">OR</span> text:"variables value" /
                  text:"variables value"
                  <span class="blue">AND</span> text:"variables value"
                </p>
              </div>
            </div>
            <div class="theme-decernis__expansionPanelSection">
              <h4 class="theme-decernis__expansionPanelTitle">
                Query & Filter query fields are merged with an "AND" operator
              </h4>
            </div>
          </mat-expansion-panel>

          <div class="form__section">
            <h3>
              Query & Filter query
              <mat-icon
                class="theme-decernis__icon theme-decernis__icon--small"
                color="primary"
                svgIcon="info"
                matTooltip="Results from variables used in query are hightlighted in preview. Both fields are merged with an AND operator"
                matTooltipClass="theme-decernis__tooltip"
              ></mat-icon>
            </h3>
          </div>

          <div class="form__section">
            <app-chips-select
              [useService]="false"
              [chips]="keyTermsChips"
              [placeholder]="'Key terms'"
              (chipListUpdate)="onAdvancedChipListUpdate($event)"
              (valid)="onFiltersValidationUpdate($event)"
            >
            </app-chips-select>
          </div>

          <div class="form__section">
            <mat-form-field
              class="theme-decernis__form-field"
              appearance="outline"
            >
              <mat-label>Query</mat-label>
              <textarea
                class="theme-decernis__textarea"
                name="queryString"
                [ngModel]="queryString"
                (ngModelChange)="onQueryStringInput($event)"
                [ngModelOptions]="{ standalone: true }"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="3"
              ></textarea>
            </mat-form-field>
          </div>

          <div class="form__section">
            <mat-form-field
              class="theme-decernis__form-field"
              appearance="outline"
            >
              <mat-label>Filter query</mat-label>
              <textarea
                class="theme-decernis__textarea"
                name="filterQueryString"
                [ngModel]="filterQueryString"
                (ngModelChange)="onQueryFilterStringInput($event)"
                [ngModelOptions]="{ standalone: true }"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="3"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <footer class="form__footer">
        <div class="container">
          <div class="form__footerInner">
            <button
              class="theme-decernis__button"
              mat-button
              mat-stroked-button
              color="primary"
              type="button"
              (click)="onCancelClick()"
            >
              <mat-icon
                class="theme-decernis__icon"
                svgIcon="cancel"
              ></mat-icon>
              Cancel
            </button>
            <button
              [disabled]="!isValidAndDirty()"
              class="theme-decernis__button"
              mat-button
              mat-flat-button
              color="primary"
              type="submit"
            >
              <mat-icon class="theme-decernis__icon" svgIcon="save"></mat-icon>
              Save
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</div>
