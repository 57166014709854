import { Component, Inject, OnInit } from '@angular/core';
import { ResetRouteService } from 'app/services/router/reset-route.service';
import { Subject } from 'rxjs';
import { SubscriptionsDataSource } from 'app/components/subscriptions-table/subscriptions.ds';
import { Location } from '@angular/common';
import { SubscriptionsDataSourceFactory } from 'app/components/subscriptions-table/subscriptions.ds.factory';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WindowToken } from 'app/services/utils/window';
import { cloneDeep } from 'lodash';
import { columnConfig } from './subscriptions-table-column-config';
import { ColumnConfig } from 'app/components/ui/data-table/data-table.interfaces';
import { EventSubscriptions } from 'app/services/events/subscriptions.service';
import { OptOutService } from 'app/services/issues/issue-subscriptions.service';
import { DialogOptionActions, DialogTitle } from 'app/interfaces';
import { HttpResponse } from '@angular/common/http';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subscriptions-table',
  templateUrl: './subscriptions-table.component.html',
  styleUrls: ['./subscriptions-table.component.scss'],
  providers: [ResetRouteService],
})
export class SubscriptionsTableComponent implements OnInit {
  unsubscribe$: Subject<void> = new Subject();
  dataSource: SubscriptionsDataSource;
  displayedColumns: string[] = [
    'event_type',
    'title',
    'created',
    'is_active',
    'manage',
  ];
  dataLoading = false;
  columnConfig: ColumnConfig[];

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };
  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];

  constructor(
    private location: Location,
    private dataSourceFactory: SubscriptionsDataSourceFactory,
    private subscriptionsItems: EventSubscriptions,
    private optOut: OptOutService,
    private router: Router,
    private dialog: MatDialog,
    private resetRoute: ResetRouteService,
    private activatedRoute: ActivatedRoute,
    @Inject(WindowToken) private window: Window
  ) {}

  ngOnInit() {
    this.columnConfig = cloneDeep(columnConfig);
    this.dataLoading = true;
    this.resetRoute.configure('/issues', this.onReset.bind(this));
    this.initDataSource();
    this.dataSource.loadData();
  }

  onReset() {
    this.initDataSource();
    this.dataSource.loadData();
  }

  /**
   * Load data for table
   */
  initDataSource() {
    this.dataSource = this.dataSourceFactory.getDataSource(
      this.subscriptionsItems,
      this.location,
      this.router,
      this.activatedRoute
    );

    this.dataSource.loading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => {
        if (!loading) {
          this.window.scrollTo(0, 0);
        }
      });
  }

  /**
   * Function to call dialog box
   * @message: string
   * @title: DialogTitle object
   * @optionActions: DialogOptionActions object
   * @return reference to dialog box
   */
  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  optOutAction(token) {
    let msg = '';
    const optionalActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
    ];
    const title: DialogTitle = {
      iconName: 'info',
      text: 'Send newsletter',
    };
    this.optOut.optOutFromSubscription(token).subscribe(
      (response: HttpResponse<any>) => {
        if (response.ok) {
          msg = `You have unsubscribed from the newsletter`;
        } else {
          msg = `Unexpected error: ${response.body.detail}`;
          this.callDialog(msg, this.errorDialogTitle, optionalActions);
        }
      },
      errors => {
        msg = DialogComponent.parseErrorResponse(errors);
        this.callDialog(msg, this.errorDialogTitle, optionalActions);
      },
      () => {
        this.callDialog(msg, title, optionalActions)
          .afterClosed()
          .subscribe(() => {
            this.dataSource.reset();
            this.dataSource.loadData();
          });
      }
    );
  }
}
