import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'env/environment';
import { Observable } from 'rxjs';
import { HttpResponseBodyWithPagination, TableData } from 'app/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DocumentDataService {
  documentsEndpoint = environment.apiUrls.news_ws + '/analytics/documents';

  constructor(private rest: RestService) {}

  getData(
    params
  ): Observable<HttpResponse<HttpResponseBodyWithPagination<TableData[]>>> {
    const searchParams = new HttpParams({ fromObject: params });
    return this.rest.post(
      this.documentsEndpoint,
      searchParams,
      {},
      {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }
    );
  }
}
