import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  ColumnConfig,
  ColumnFilter,
  ColumnFilterValue,
  ColumnOutput,
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  IssueType,
  Issue,
  QueryRow,
  SelectValue,
} from 'app/interfaces';
import { ColumnPickerService } from 'app/components/ui/data-table/column-picker/column-picker.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { IssueDataSourceService } from 'app/services/issues/issue-data-source.service';
import { IssuesCrudService } from 'app/services/issues/issues-crud.service';
import { IssuesDataFormatterService } from 'app/services/issues/issues-data-formatter.service';
import { ColumnConfigData } from './column-config-data';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { switchMap, shareReplay, map, takeUntil } from 'rxjs/operators';
import { throwError, Observable, of, Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IssueTypeServiceService } from 'app/services/issues/issue-type-service.service';
import * as moment from 'moment';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SelectOptionsConfig } from '../profile/select-option-config';
import { UserPermissionsService } from 'app/services/user/user-permissions.service';
import { RestService } from 'sso-angular';
import { saveAs } from 'file-saver';
import { DialogRedirectService } from 'app/services/dialog/dialog-redirect.service';
import { ResetRouteService } from 'app/services/router/reset-route.service';
import { Location } from '@angular/common';
import { WindowToken } from 'app/services/utils/window';

@Component({
  selector: 'app-issue-management',
  templateUrl: './issue-management.component.html',
  styleUrls: ['./issue-management.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [ResetRouteService],
})
export class IssueManagementComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  DEFAULT_SORTING_KEY = 'publish';
  DEFAULT_DIRECTION_KEY: 'asc' | 'desc' = 'desc';

  dataSource: IssueDataSourceService;
  issueType$: Observable<IssueType[]>;
  issueSelectValue$: Observable<SelectValue[]>;
  columnConfig: ColumnConfig[];

  isContentAdmin$: Observable<boolean>;

  statusMap = {
    draft: {
      color: 'black',
      icon: 'draft',
    },
    scheduled: {
      color: 'orange',
      icon: 'calendar',
    },
    published: {
      color: 'green',
      icon: 'check',
    },
  };

  publishMap = {
    draft: {
      color: 'black',
    },
    scheduled: {
      color: 'gray',
    },
    published: {
      color: 'black',
    },
  };

  filterColumns: string[];
  visibleColumns: string[];
  filters: ColumnFilterValue = {};
  sorting: ColumnFilterValue;
  routePath = 'issues';

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };

  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];

  dateFormat: 'YYYY-MM-DD';
  expandedElements: Issue[] = [];
  localStorageColumnKey = 'issue_columns';
  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private columnDialog: ColumnPickerService,
    private issueCrudService: IssuesCrudService,
    private issueDataFormatter: IssuesDataFormatterService,
    private issueType: IssueTypeServiceService,
    private dialog: MatDialog,
    private userPermission: UserPermissionsService,
    private rest: RestService,
    private dialogRedirect: DialogRedirectService,
    private resetRoute: ResetRouteService,
    private location: Location,
    @Inject(WindowToken) private window: Window
  ) {}

  ngOnInit() {
    this.issueType$ = this.issueType.get().pipe(shareReplay());
    this.issueSelectValue$ = this.issueType$.pipe(
      map((issueTypes: IssueType[]) =>
        issueTypes.map(
          issue =>
            ({
              name: issue.issue_type,
              value: issue.id.toString(),
            } as SelectValue)
        )
      )
    );
    this.isContentAdmin$ = this.userPermission
      .isContentAdmin()
      .pipe(shareReplay());

    this.initColumnConfig();
    this.mapConfigToColumnsVariables();
    this.initDataSource();
    this.initFilters();
    this.initSorting();

    const initialParams = { ...this.getParamsFromUrl(), ...this.sorting };
    this.dataSource.loadData(initialParams);
  }

  ngAfterViewInit() {
    this.resetRoute.configure('/issues', this.onReInit.bind(this));
  }

  onReInit(): void {
    this.filters = {};
    this.initColumnConfig();
    this.mapConfigToColumnsVariables();
    this.paginator.firstPage();
    this.initSorting(true, true);
  }

  initDataSource() {
    this.dataSource = new IssueDataSourceService(
      this.issueCrudService,
      this.issueDataFormatter
    );

    this.dataSource.loading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => {
        if (!loading) {
          this.window.scrollTo(0, 0);
        }
      });
  }

  initColumnConfig() {
    this.columnConfig = _.cloneDeep(ColumnConfigData);

    const visibleColumns = JSON.parse(
      localStorage.getItem(this.localStorageColumnKey)
    );

    const areColumnKeysValid =
      visibleColumns &&
      visibleColumns.every(columnName =>
        this.columnConfig.map(column => column.key).includes(columnName)
      );

    if (areColumnKeysValid) {
      this.columnConfig.map(config => {
        config.hide = !visibleColumns.includes(config.key);
      });

      this.columnConfig = [...this.columnConfig];
    }

    const issueConfig = this.columnConfig.find(
      config => config.key === 'issue_type'
    );

    issueConfig.filter.selectable = this.issueSelectValue$;
  }

  mapConfigToColumnsVariables() {
    this.visibleColumns = this.columnConfig
      .filter(col => !col.hide)
      .map(col => col.key);

    this.filterColumns = this.columnConfig
      .filter(col => !col.hide)
      .map(col => `filter-${col.filter.name}`);
  }

  getColumnFilterConfigByFilterKey(key: string): ColumnFilter {
    return this.getColumnConfigByFilterKey(key).filter;
  }

  getColumnConfigByFilterKey(key: string): ColumnConfig {
    const filterPrefix = 'filter-';
    return this.columnConfig.find(
      col => col.filter.name === key.replace(filterPrefix, '')
    );
  }

  getParamsFromUrl() {
    const params = {};

    new URLSearchParams(window.location.search).forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  initFilters() {
    const urlParams = this.getParamsFromUrl();

    of(urlParams)
      .pipe(
        switchMap(params => this.mapIssueType(params))
        // map(params => this.mapDateValues(params))
      )
      .subscribe(params => {
        this.filters = params;

        Object.keys(params).forEach(key => {
          const config = this.columnConfig.find(col => col.filter.name === key);
          if (config) {
            config.filter.value = params[key];
          }
        });
      });
  }

  getValidQueryParams(): ColumnFilterValue {
    Object.keys(this.filters)
      .filter(filterName => {
        const config = this.getColumnConfigByFilterKey(filterName);
        return !config || config.hide;
      })
      .forEach(colName => delete this.filters[colName]);

    if (this.sorting) {
      this.filters = { ...this.filters, ...this.sorting };
    }

    return this.filters;
  }

  onSubmit() {
    of(this.getValidQueryParams())
      .pipe(
        switchMap(params => this.mapIssueType(params))
        // map(params => this.mapDateValues(params))
      )
      .subscribe(params => {
        this.router.navigate([`/${this.routePath}`], {
          queryParams: params,
        });

        this.dataSource.loadData(params);
        this.paginator.pageIndex = 0;
      });
  }

  initSorting(alreadyBound?: boolean, setDefault?: boolean) {
    const sortingKey = 'ordering';
    const urlParams = new URLSearchParams(window.location.search);
    const sortInUrl = urlParams.get(sortingKey);
    const prefix = '-';
    let direction: 'asc' | 'desc' = this.DEFAULT_DIRECTION_KEY;
    let key = this.DEFAULT_SORTING_KEY;

    if (sortInUrl) {
      const isPrefixInSort = sortInUrl.includes(prefix);
      key = sortInUrl;
      if (isPrefixInSort) {
        key = key.replace(prefix, '');
        direction = 'desc';
      }
    }

    if (setDefault) {
      /* We programatically set directon to empty so the next time when sort is triggered we will trigger ASC direction */
      this.sort.direction = '';
    }

    if (!alreadyBound) {
      this.bindSorting();
    }

    const dirToValue = direction === 'desc' ? '-' : '';
    this.sorting = { [sortingKey]: `${dirToValue}${key}` };
    this.sort.sort({ id: key, start: direction, disableClear: false });
  }

  bindSorting() {
    const sortingKey = 'ordering';
    this.sort.sortChange.subscribe(({ active, direction }) => {
      const sortingKeyInConfig = this.columnConfig.find(
        config => config.sortingKey && config.key === active
      );
      const activeKey = sortingKeyInConfig
        ? sortingKeyInConfig.sortingKey
        : active;

      let sort = {};
      if (direction) {
        const dir = direction === 'desc' ? '-' : '';
        sort = {
          [sortingKey]: dir + activeKey,
        };
        this.sorting = sort;
        this.filters = { ...this.filters, ...this.sorting };
      } else {
        this.filters[sortingKey] = this.DEFAULT_SORTING_KEY;
        sort = { [sortingKey]: this.DEFAULT_SORTING_KEY };
        this.sorting = sort;
      }

      this.router.navigate([], { queryParams: this.filters });
      this.dataSource.loadData(this.filters);
      this.paginator.pageIndex = 0;
    });
  }

  editIssue(issueID) {
    this.router.navigate([`/${this.routePath}/edit/${issueID}`]);
  }

  displayIssue(issueID) {
    this.router.navigate([`/${this.routePath}/details/${issueID}`]);
  }

  deleteIssue(issueID) {
    let msg = '';
    let dialogTitle = this.errorDialogTitle;
    let optionActions = this.defaultDialogOptionActions;

    this.callConfirmDialog()
      .afterClosed()
      .pipe(
        switchMap((dialogData: AdditionalDialogData) => {
          if (dialogData && dialogData.confirmed) {
            return this.issueCrudService.delete(issueID);
          } else {
            throwError('could-not-delete');
          }
        })
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.ok) {
            msg = 'Issue has been deleted';
            dialogTitle = this.successDialogTitle;
            optionActions = [
              {
                actionAfterClose: true,
                cssClass: 'mat-stroked-button mat-primary',
                description: 'Close',
                id: 'delete-dialog',
              },
            ];
          } else {
            msg = `Unexpected error: ${response.status} ${response.statusText}`;
            this.callDialog(msg, dialogTitle, optionActions);
          }
        },
        error => {
          msg = DialogComponent.parseErrorResponse(error);
          this.callDialog(msg, dialogTitle, optionActions);
        },
        () => {
          this.callDialog(msg, dialogTitle, optionActions);
          const params = { ...this.filters, ...this.sorting };
          this.paginator.firstPage();
          this.dataSource.loadData(params);
        }
      );
  }

  onFilterValueChange(filterValue: ColumnFilterValue): void {
    this.filters = _.pickBy({ ...this.filters, ...filterValue }, _.identity);
    const value = filterValue[Object.keys(filterValue)[0]];
    if (value) {
      this.getColumnConfigByFilterKey(
        Object.keys(filterValue)[0]
      ).filter.value = value;
    }
  }

  getFormData(data: FormData): object {
    const formData = {};

    this.visibleColumns.forEach(value => {
      const entry = data.get(value);
      if (entry) {
        formData[value] = entry;
      }
    });

    return formData;
  }

  mapIssueType(params: ColumnFilterValue) {
    const key = 'issue_type';
    const isIssueTypeInQuery = Object.keys(params).includes(key);

    if (isIssueTypeInQuery) {
      return this.issueType$.pipe(
        map(issues => {
          const found =
            issues.find(issue => issue.issue_type === params[key]) ||
            issues.find(issue => issue.id === +params[key]);
          if (found) {
            params[key] = found.id;
          }
          return params;
        })
      );
    } else {
      return of(params);
    }
  }

  mapDateValues(params: ColumnFilterValue, format?: boolean) {
    const dateKeys = ['created', 'updated', 'publish'];

    Object.keys(params).forEach(key => {
      if (dateKeys.includes(key)) {
        if (!format) {
          params[key] = moment(<string>params[key]).toISOString();
        } else {
          params[key] = moment(<string>params[key]).format(this.dateFormat);
        }
      }
    });

    return params;
  }

  openColumnPicker() {
    this.columnDialog
      .callDialog(this.columnConfig)
      .afterClosed()
      .subscribe((dialogData: AdditionalDialogData) => {
        if (dialogData) {
          const { confirmed, columnOptions } = dialogData;
          if (confirmed) {
            this.applyNewColumnSettings(columnOptions);
          }
        }
      });
  }

  applyNewColumnSettings(columnOptions) {
    Object.keys(columnOptions).forEach(key => {
      const value = !columnOptions[key];
      const column = this.columnConfig.find(col => col.key === key);

      if (!value) {
        column.filter.value = '';
      }

      column.hide = value;
    });

    this.mapConfigToColumnsVariables();
    this.saveColumnConfig();
  }

  saveColumnConfig() {
    const config = this.visibleColumns;
    localStorage.setItem(this.localStorageColumnKey, JSON.stringify(config));
  }

  getAttachement(url: string, fileName: string) {
    return this.rest
      .get(url, {}, {}, true)
      .pipe(map((response: HttpResponse<any>) => response.body))
      .subscribe(
        blob => {
          if (navigator.msSaveBlob) {
            // IE, EDGE
            navigator.msSaveBlob(blob, fileName);
          } else {
            saveAs(blob, fileName);
          }
        },
        err => {
          console.error(err);
          this.dialogRedirect.callDialog('/issues');
        }
      );
  }

  isSticky(filterColumnName): boolean {
    const filterPrefix = 'filter-';
    return this.columnConfig.find(
      col => col.filter.name === filterColumnName.replace(filterPrefix, '')
    ).sticky;
  }

  isStickyEnd(filterColumnName): boolean {
    const filterPrefix = 'filter-';
    return this.columnConfig.find(
      col => col.filter.name === filterColumnName.replace(filterPrefix, '')
    ).stickyEnd;
  }

  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  callConfirmDialog() {
    const message = 'Are you sure you want to delete issue permanently?';
    const title: DialogTitle = {
      iconName: 'delete',
      text: 'Delete issue',
    };
    const optionalActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionalActions);
  }

  extendUrlByOffset(event?) {
    const offset = event.pageSize * event.pageIndex;
    const params = new URLSearchParams(window.location.search);

    if (params.has('offset')) {
      params.set('offset', offset.toString());
    } else {
      params.append('offset', offset.toString());
    }

    /* When we switch tab the URL is cleaned so we have to extract ordering from filters */
    const ordering = this.filters.ordering as string;
    if (ordering) {
      if (params.has('ordering')) {
        params.set('ordering', ordering);
      } else {
        params.append('ordering', ordering);
      }
    }

    const newParams = {};
    params.forEach((value, key) => {
      newParams[key] = value;
    });

    this.location.replaceState('/issues', params.toString());
    this.dataSource.loadData(newParams);
  }

  toggleExpandSingle(row: Issue) {
    const index = this.expandedElements.indexOf(row);
    if (index > -1) {
      this.expandedElements.splice(index, 1);
    } else {
      this.expandedElements.push(row);
    }
  }

  filterEmptyTags(tags: QueryRow[]): QueryRow[] {
    return tags.filter(tag => tag.selectedOption && tag.chips.length);
  }

  mapTagKeyToName(name: string): string {
    return SelectOptionsConfig.find(option => option.safeName === name).name;
  }

  canEditIssue(isSharedIssue: boolean): Observable<boolean> {
    return this.isContentAdmin$.pipe(
      map(isContentAdmin => !(!isContentAdmin && isSharedIssue))
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
