import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { environment } from 'env/environment';
import { RestService } from 'sso-angular';

@Injectable()
export class DocumentsExportService {
  documentsEndpoint: string =
    environment.apiUrls.news_ws + '/analytics/documents_export';

  constructor(private rest: RestService) {}

  query(params) {
    const searchParams = new HttpParams({ fromObject: params });
    return this.rest.post(
      this.documentsEndpoint,
      searchParams,
      {},
      {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      true
    );
  }
}
