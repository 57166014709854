<mat-form-field
  class="theme-decernis__form-field theme-decernis__form-field--flexInput timepicker"
  appearance="outline"
>
  <mat-label>Select time</mat-label>
  <input
    matInput
    [ngxTimepicker]="picker"
    [value]="value"
    [format]="format"
    readonly
  />
  <ngx-material-timepicker-toggle [for]="picker">
    <mat-icon
      matSuffix
      class="theme-decernis__icon theme-decernis__icon--medium"
      svgIcon="calendar"
      ngxMaterialTimepickerToggleIcon
    ></mat-icon>
  </ngx-material-timepicker-toggle>

  <button
    (click)="changeClock()"
    mat-button
    mat-icon-button
    matSuffix
    class="theme-decernis__button"
    type="button"
  >
    <mat-icon
      class="theme-decernis__icon theme-decernis__icon--bigger"
      [svgIcon]="format === 24 ? '24h-clock' : '12h-clock'"
    >
    </mat-icon>
  </button>
  <ngx-material-timepicker
    #picker
    [disableAnimation]="true"
    (opened)="onOpen()"
    (closed)="onClose()"
    (timeChanged)="onTimeChanged($event)"
  ></ngx-material-timepicker>
  <mat-hint align="end">{{ hint }}</mat-hint>
</mat-form-field>
