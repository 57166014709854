<div class="space">
  <button
    class="theme-decernis__button btn-create"
    mat-button
    mat-stroked-button
    type="button"
    routerLink="/issues/newsletter/add"
  >
    <mat-icon class="theme-decernis__icon" svgIcon="add_circle"></mat-icon>
    Add issue newsletter
  </button>

  <div class="tableWrap">
    <app-data-table
      [dataSource]="dataSource"
      [columnConfig]="columnConfig"
      [isPageable]="true"
    >
      <ng-template #cellTemplate let-element="element" let-col="column">
        <ng-container [ngSwitch]="col.key">
          <span *ngSwitchCase="'type'">
            {{ getPeriodicityName(element.periodicity) | async }}
          </span>

          <span *ngSwitchCase="'sendEmpty'">
            <mat-icon
              *ngIf="element.sendEmpty; else falseTemplate"
              class="theme-decernis__icon"
              svgIcon="check-no-rounded"
            ></mat-icon>

            <ng-template #falseTemplate>
              <mat-icon class="theme-decernis__icon" svgIcon="close"></mat-icon>
            </ng-template>
          </span>

          <span *ngSwitchCase="'manage'">
            <button
              class="theme-decernis__button"
              mat-button
              type="button"
              color="primary"
              (click)="redirectToEdit(element.id)"
            >
              <mat-icon
                class="theme-decernis__icon"
                svgIcon="save-edit"
              ></mat-icon>
              Edit
            </button>
            <button
              class="theme-decernis__button btn-create btn-button"
              mat-button
              type="button"
              color="primary"
              (click)="callDelete(element)"
            >
              <mat-icon
                class="theme-decernis__icon"
                svgIcon="delete"
              ></mat-icon>
            </button>
            <div>
              <button
                class="theme-decernis__button btn-create"
                mat-button
                type="button"
                color="primary"
                (click)="sendNow(element)"
              >
                <mat-icon
                  class="theme-decernis__icon"
                  svgIcon="email"
                ></mat-icon>
                Send test email
              </button>
            </div>
          </span>
        </ng-container>
      </ng-template>
    </app-data-table>
  </div>
  <app-subscriptions-table></app-subscriptions-table>
</div>
