<div class="wrapper">
  <div class="form__header">
    <h2>
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--big"
        svgIcon="info"
      ></mat-icon>
      Issue Management
    </h2>
  </div>

  <div class="issueManagement__section issueManagement__section--flex">
    <div class="issueManagement__columnPicker">
      <button
        mat-button
        class="theme-decernis__button"
        (click)="openColumnPicker()"
      >
        <mat-icon class="theme-decernis__icon" svgIcon="cog"></mat-icon>
        CHOOSE COLUMNS
      </button>
    </div>
    <button
      class="theme-decernis__button"
      mat-button
      mat-flat-button
      color="primary"
      type="button"
      routerLink="/issues/add"
    >
      <mat-icon class="theme-decernis__icon" svgIcon="add_circle"></mat-icon>
      Add issue
    </button>
  </div>

  <form (ngSubmit)="onSubmit()">
    <div class="theme-decernis__tableWrap">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        multiTemplateDataRows
        class="theme-decernis__table"
      >
        <ng-container
          *ngFor="let column of columnConfig; let id = index"
          [matColumnDef]="column.key"
          [sticky]="column.sticky"
          [stickyEnd]="column.stickyEnd"
        >
          <th
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            [attr.data-disabled]="!column.sortable"
            class="theme-decernis__tableHead"
            [ngClass]="{
              shadow: column.sticky || column.stickyEnd,
              'shadow--right': column.sticky,
              'shadow--left': column.stickyEnd
            }"
          >
            {{ column.name }}
          </th>

          <ng-container [ngSwitch]="column.key">
            <ng-container *ngSwitchCase="'manage'">
              <td
                mat-cell
                *matCellDef="let element"
                class="theme-decernis__tableCell"
                [ngClass]="{
                  shadow: column.sticky || column.stickyEnd,
                  'shadow--right': column.sticky,
                  'shadow--left': column.stickyEnd
                }"
              >
                <div class="issueManagement__flexWrap">
                  <button
                    class="theme-decernis__button"
                    mat-button
                    type="button"
                    color="primary"
                    (click)="displayIssue(element.id)"
                  >
                    <mat-icon
                      class="theme-decernis__icon"
                      svgIcon="eye"
                    ></mat-icon>
                    Details
                  </button>
                  <button
                    *ngIf="canEditIssue(element.shared) | async"
                    class="theme-decernis__button"
                    mat-button
                    type="button"
                    color="primary"
                    (click)="editIssue(element.id)"
                  >
                    <mat-icon
                      class="theme-decernis__icon"
                      svgIcon="save-edit"
                    ></mat-icon>
                    Edit
                  </button>
                  <button
                    *ngIf="canEditIssue(element.shared) | async"
                    class="theme-decernis__button"
                    mat-button
                    type="button"
                    color="primary"
                    (click)="deleteIssue(element.id)"
                  >
                    <mat-icon
                      class="theme-decernis__icon"
                      svgIcon="delete"
                    ></mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="'status'">
              <td
                mat-cell
                *matCellDef="let element"
                class="theme-decernis__tableCell"
                [ngClass]="{
                  shadow: column.sticky || column.stickyEnd,
                  'shadow--right': column.sticky,
                  'shadow--left': column.stickyEnd
                }"
              >
                <span
                  class="theme-decernis__cellInner theme-decernis__cellInner--withIcon theme-decernis__cellInner--{{
                    statusMap[element[column.key].toLowerCase()].color
                  }}"
                >
                  <mat-icon
                    class="theme-decernis__icon theme-decernis__icon--small"
                    svgIcon="{{
                      statusMap[element[column.key].toLowerCase()].icon
                    }}"
                  ></mat-icon>
                  {{ element[column.key] }}
                </span>
              </td>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'theme-decernis__tableCell': 'true',
                  'theme-decernis__tableCell--bold': column.bold,
                  shadow: column.sticky || column.stickyEnd,
                  'shadow--right': column.sticky,
                  'shadow--left': column.stickyEnd
                }"
              >
                {{ element[column.key] }}
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container
          *ngFor="let column of filterColumns"
          [matColumnDef]="column"
          [sticky]="isSticky(column)"
          [stickyEnd]="isStickyEnd(column)"
        >
          <td
            *matHeaderCellDef
            class="theme-decernis__tableHead theme-decernis__tableHead--lighter"
            [ngClass]="{
              shadow:
                this.getColumnConfigByFilterKey(column).sticky ||
                this.getColumnConfigByFilterKey(column).stickyEnd,
              'shadow--right': this.getColumnConfigByFilterKey(column).sticky,
              'shadow--left': this.getColumnConfigByFilterKey(column).stickyEnd
            }"
          >
            <app-table-column-filter
              *ngIf="getColumnFilterConfigByFilterKey(column); let config"
              [type]="config.type"
              [value]="config.value"
              [hide]="config.hide"
              [name]="config.name"
              [selectable]="config.selectable"
              (onFilterValueChange)="onFilterValueChange($event)"
            >
            </app-table-column-filter>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="visibleColumns.length"
          >
            <div
              class="tags"
              [@detailExpand]="
                expandedElements.indexOf(element) > -1
                  ? 'expanded'
                  : 'collapsed'
              "
            >
              <ng-container
                *ngIf="
                  (element.tags && filterEmptyTags(element.tags).length) ||
                    element.attachments.length;
                  else emptyTags
                "
              >
                <div
                  class="tags__section"
                  *ngIf="element.tags && filterEmptyTags(element.tags).length"
                >
                  <app-tag-list
                    [tags]="filterEmptyTags(element.tags)"
                  ></app-tag-list>
                </div>

                <div class="tags__section" *ngIf="element.attachments.length">
                  <h3 class="tags__title tags__title--noMargin">
                    Attachments
                  </h3>
                  <ul class="tags__list">
                    <li *ngFor="let attachment of element.attachments">
                      <button
                        class="tags__download"
                        type="button"
                        (click)="
                          getAttachement(
                            attachment.uploaded_file,
                            attachment.file_name
                          )
                        "
                      >
                        {{ attachment.file_name }}
                      </button>
                    </li>
                  </ul>
                </div>
              </ng-container>
              <ng-template #emptyTags>
                <div class="tags__container">
                  <h5 class="tags__title">No data related to this issue</h5>
                </div>
              </ng-template>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          class="theme-decernis__headerRow"
          *matHeaderRowDef="visibleColumns"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: visibleColumns"
          class="theme-decernis__tableRow theme-decernis__tableRow--expandable"
          (click)="toggleExpandSingle(row)"
        ></tr>

        <tr
          mat-header-row
          *matHeaderRowDef="filterColumns"
          class="theme-decernis__headerRow"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="theme-decernis__tableRow theme-decernis__tableRow--details theme-decernis__tableRow--withShadow"
        ></tr>
      </table>

      <div
        *ngIf="dataSource.loading$ | async"
        class="theme-decernis__tableLoader"
      >
        <mat-spinner diameter="80"></mat-spinner>
      </div>
    </div>
    <mat-paginator
      [length]="dataSource?.dataLength"
      [pageIndex]="dataSource?.offset / dataSource?.pageSize"
      [pageSize]="dataSource?.pageSize"
      (page)="extendUrlByOffset($event)"
    ></mat-paginator>
  </form>
</div>
