import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class QueryUtilsService {
  queryEvent: Subject<any> = new Subject();

  callQueryChange(value) {
    this.queryEvent.next(value);
  }
}
