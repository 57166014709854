import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';
import { map, shareReplay } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EmailDomainWhitelistService {
  endpoint =
    environment.apiUrls.news_ws + `/notifications/emaildomainwhitelist`;
  emailWhitelist$ = this.getEmailWhitelist().pipe(shareReplay(1));

  constructor(private rest: RestService) {}

  getEmailWhitelist() {
    return this.rest.get(this.endpoint, { limit: 5000 }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body.results.map(entry => entry.email_domain);
      })
    );
  }

  checkDomainValidity(emailList: string[]) {
    return this.emailWhitelist$.pipe(
      map(emailDomainWhitelist => {
        const areAllEmailsValid = emailList.every(email => {
          return emailDomainWhitelist.includes(email.split('@')[1]);
        });
        return [areAllEmailsValid, emailDomainWhitelist];
      })
    );
  }
}
