import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { environment } from 'env/environment';

@Injectable()
export class OptOutService {
  optOutEndpoint = environment.apiUrls.news_ws + '/notifications/opt-out';
  constructor(private rest: RestService) {}

  optOutFromSubscription(uuid) {
    return this.rest.post(this.optOutEndpoint, { token: uuid });
  }
}
