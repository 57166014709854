import { Injectable } from '@angular/core';

import { environment } from 'env/environment';
import { RestService } from 'sso-angular';

@Injectable()
export class GroupsService {
  groupsEndpoint: string = environment.apiUrls.oauth + '/groups';

  constructor(private rest: RestService) {}

  list() {
    return this.rest.get(this.groupsEndpoint, {
      limit: 10000,
      ordering: 'username',
    });
  }
}
