<!-- <app-loading [show]="loader"></app-loading> -->
<div class="mat-typography wrapper">
  <div class="form__header">
    <h2>
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--big"
        svgIcon="{{ iconName }}"
      ></mat-icon>
      {{ pageTitle }}
    </h2>
  </div>
  <form [formGroup]="formdata" (ngSubmit)="onSaveClick(formdata.value)">
    <div class="row">
      <div class="col-md-4 col-xs-12">
        <mat-form-field
          class="theme-decernis__form-field"
          appearance="outline"
          hideRequiredMarker
        >
          <mat-label>Title</mat-label>
          <input
            type="text"
            matInput
            formControlName="title"
            placeholder="title"
            maxlength="100"
          />
          <mat-error
            align="end"
            *ngIf="formdata.controls.title.errors?.required"
            >Title is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8 col-xs-12">
        <mat-form-field class="theme-decernis__form-field" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea
            class="theme-decernis__textarea"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            formControlName="description_text"
          ></textarea>
          <mat-error
            align="end"
            *ngIf="formdata.controls.description_text.errors?.maxlength"
            >Description is too long</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-xs-12">
        <mat-form-field class="theme-decernis__form-field" appearance="outline">
          <mat-label>Schedule</mat-label>
          <mat-select
            class="theme-decerins__select"
            panelClass="theme-decernis__selectPanel"
            disableOptionCentering
            formControlName="periodicity"
          >
            <mat-option
              *ngFor="let elem of periodicity$ | async"
              [value]="elem.id"
            >
              {{ elem.name }}
            </mat-option>
          </mat-select>
          <mat-error
            align="end"
            *ngIf="formdata.controls.periodicity.errors?.required"
            >Schedule is required</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4 col-xs-12">
        <app-timepicker
          [initialTime]="initialTime"
          (timeChanged)="onTimeChanged($event)"
        ></app-timepicker>
      </div>

      <div class="col-md-4 col-xs-12">
        <app-timezone-picker [control]="formdata.get('timezone')">
        </app-timezone-picker>
      </div>
    </div>

    <mat-form-field class="theme-decernis__form-field" appearance="outline">
      <mat-label>Issue type</mat-label>
      <mat-select
        class="theme-decerins__select"
        panelClass="theme-decernis__selectPanel"
        disableOptionCentering
        formControlName="issue_type"
      >
        <mat-option *ngFor="let elem of issueType$ | async" [value]="elem.id">
          {{ elem.issue_type }}
        </mat-option>
      </mat-select>
      <mat-error
        align="end"
        *ngIf="formdata.controls.issue_type.errors?.required"
        >Issue type is required</mat-error
      >
    </mat-form-field>

    <div class="form__section form__section--small">
      <mat-checkbox
        formControlName="sendEmpty"
        id="sendEmpty"
        color="primary"
        class="theme-decernis__checkbox"
        (change)="sendEmpty = !sendEmpty"
        >Send alert email when there are zero results</mat-checkbox
      >
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--small newsletter__tooltip-icon"
        color="primary"
        svgIcon="info"
        matTooltip="Check to send mail even if there is no new data."
        matTooltipClass="theme-decernis__tooltip"
      ></mat-icon>
    </div>

    <footer class="form__footer">
      <div class="container">
        <div class="form__footerInner">
          <button
            class="theme-decernis__button"
            mat-button
            mat-stroked-button
            color="primary"
            type="button"
            (click)="onCancelClick()"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="cancel"></mat-icon>
            Cancel
          </button>
          <button
            [disabled]="isFormValid()"
            class="theme-decernis__button"
            mat-button
            mat-flat-button
            color="primary"
            type="submit"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="save"></mat-icon>
            Save
          </button>
        </div>
      </div>
    </footer>
  </form>
</div>
