import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tracking-google-analytics',
  templateUrl: './tracking-google-analytics.component.html',
  styleUrls: [],
})
export class TrackingGoogleAnalyticsComponent implements OnInit {
  @Input() googleGlobalSiteTag: string;

  analyticsEmbeddedScriptSource: string;
  analyticsEmbeddedScript: string;

  constructor() {}

  ngOnInit() {
    this.analyticsEmbeddedScriptSource = `https://www.googletagmanager.com/gtag/js?id=${this.googleGlobalSiteTag}`;
    this.analyticsEmbeddedScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', '${this.googleGlobalSiteTag}');
    `;
  }
}
