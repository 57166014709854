import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  MAT_DATE_FORMATS,
  DateAdapter,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateOutput } from 'app/interfaces';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class DatepickerComponent implements OnInit {
  DEFAULT_ID = 'date';
  DEFAULT_PLACEHOLDER = 'Choose a date';

  @Input() value: Date;
  @Input() id: string;
  @Input() basicStyles: boolean;
  @Input() placeholder: string;
  @Input() min: Date;
  @Input() disabled = false;
  @Output() onChange: EventEmitter<DateOutput> = new EventEmitter();

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = this.DEFAULT_PLACEHOLDER;
    }
  }

  onDateChange(event: MatDatepickerInputEvent<any>) {
    const id = this.id ? this.id : this.DEFAULT_ID;
    let date;

    if (event.value) {
      date = event.value.toDate();
    } else {
      date = null;
    }

    this.onChange.emit({ date, id });
  }
}
