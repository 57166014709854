import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionsDataSource } from 'app/components/subscriptions-table/subscriptions.ds';
import { IServerSideDataSourceFactory } from 'app/data-source/base-server-side-data-source';
import { EventSubscriptions } from 'app/interfaces';

@Injectable()
export class SubscriptionsDataSourceFactory
  implements IServerSideDataSourceFactory<EventSubscriptions> {
  public getDataSource(
    rest,
    location: Location,
    router: Router,
    activatedRoute: ActivatedRoute
  ): SubscriptionsDataSource {
    return new SubscriptionsDataSource(rest);
  }
}
