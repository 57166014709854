import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileHandlerService {
  constructor() {}

  handleFile(file: File): Observable<any> {
    const reader = new FileReader();

    return new Observable(observer => {
      reader.onload = () => {
        observer.next(reader.result);
      };
      reader.readAsText(file);
    });
  }
}
