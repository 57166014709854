<dropzone
  #dropzone
  class="dropzone-container"
  [config]="config"
  [message]="message"
  (removedFile)="dzRemovedFile($event)"
  (success)="dzSuccess($event)"
  (sending)="dzSending()"
  (complete)="dzComplete($event)"
>
</dropzone>
