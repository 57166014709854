import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Chip } from 'app/interfaces';
import { ChipCreatorService } from 'app/services/chip-creator.service';
import { AbstractControl, FormControl } from '@angular/forms';

export enum ControlStatus {
  invalid = 'INVALID',
  valid = 'VALID',
}

@Component({
  selector: 'app-chips-select',
  templateUrl: './chips-select.component.html',
  styleUrls: ['./chips-select.component.scss'],
})
export class ChipsSelectComponent implements OnInit, OnChanges {
  @Output() chipListUpdate = new EventEmitter();
  @Output() valid = new EventEmitter<boolean>();
  @Input() chips: Chip[] = [];
  @Input() disabled: boolean;
  @Input() useService: boolean;
  @Input() selectableOptions: Chip[] = [];
  @Input() isLoading = false;
  @Input() placeholder: string;
  @Input() control: FormControl = new FormControl('');
  @Input() hideText = false;
  @Input() iconName: string;
  @Input() hintMessage: string;

  @ViewChild('chipList', { static: true }) chipListElement;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  filteredOptions: Chip[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  searchInputControl: FormControl = new FormControl('', {
    updateOn: 'blur',
    validators: this.emptyValidator(),
  });
  searchInputErrorKey = 'notEmpty';

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<
    HTMLInputElement
  >;

  constructor(private chipCreator: ChipCreatorService) {}

  ngOnInit(): void {
    if (!this.hintMessage) {
      this.hintMessage = this.getDefaultHintMessage();
    }

    this.searchInput.nativeElement.addEventListener('input', e => {
      const target = e.target as HTMLInputElement;
      this.filteredOptions = this.selectableOptions.filter(
        option =>
          option.name.toLowerCase().indexOf(target.value.toLowerCase()) === 0
      );
    });

    this.bindSearchInputControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectableOptions) {
      this.filteredOptions = changes.selectableOptions.currentValue;
    }
  }

  bindSearchInputControl() {
    // We have to manually set error to control in order to display it in <mat-error>
    this.searchInputControl.statusChanges.subscribe((status: ControlStatus) => {
      let valid = true;
      this.control.updateValueAndValidity();
      if (status === ControlStatus.invalid) {
        this.control.setErrors({
          ...this.control.errors,
          [this.searchInputErrorKey]: true,
        });
        valid = false;
      } else {
        const errors = this.control.errors;

        if (errors) {
          delete errors[this.searchInputErrorKey];
        }

        this.control.setErrors(errors);
      }
      this.chipListElement.errorState = this.control.hasError;
      this.valid.emit(valid);
    });
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;
    // prevent add chips value if uses service
    if (this.useService) {
      return;
    }

    if ((value || '').trim()) {
      this.chips.push(this.chipCreator.create(value));
    }

    if (input) {
      input.value = '';
    }

    this.searchInputControl.setValue('');
    this.emit();
  }

  addChipFromAutocomplete(event: MatAutocompleteSelectedEvent): void {
    const { value, viewValue } = event.option;
    this.chips.push(this.chipCreator.create({ name: viewValue, value }));
    this.selectableOptions = this.selectableOptions.filter(
      option => option.name !== viewValue
    );
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.blur();
    this.searchInputControl.setValue('');
    this.emit();
  }

  remove(chip: Chip): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
      this.selectableOptions.push(this.chipCreator.create(chip));
      this.selectableOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    this.emit();
  }

  compareFn(option: string, selected: string) {
    return option.trim().toLowerCase() === selected;
  }

  emit(): void {
    this.chipListUpdate.emit(this.chips);
    this.control.setValue(this.chips.map((chip: Chip) => chip.value));
    this.searchInputControl.updateValueAndValidity();
    this.control.markAsDirty();
    this.chipListElement.errorState = this.control.hasError;
  }

  inputPlaceholder(): string {
    let placeholder = '';
    if (!this.placeholder) {
      if (!this.chips.length) {
        if (!this.useService) {
          placeholder = 'Type';
        } else {
          placeholder = 'Type to search...';
        }
      }
    } else {
      placeholder = this.placeholder;
    }
    return placeholder;
  }

  getDefaultHintMessage() {
    return `Use <span class="bold">COMMA</span> or <span class="bold">ENTER</span> at
    the end of the ready text value to add it to the search query.`;
  }

  emptyValidator() {
    return (control: AbstractControl) => {
      const chips = this.chips.map(chip => chip.value);
      return control.value && !chips.includes(control.value)
        ? { [this.searchInputErrorKey]: true }
        : null;
    };
  }
}
