import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { Company } from 'app/interfaces';
import { CompanyService } from '../company/company.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  company$: Observable<Company[]>;
  isContentAdmin$: Observable<boolean>;

  constructor(private companyService: CompanyService) {}

  isContentAdmin() {
    return this.companyService.get().pipe(
      map(data => !!data.length),
      catchError(err => {
        // 403 Means the user is not Content Admin
        if (err.status === 403) {
          return of(false);
        }
        return throwError(err);
      }),

      shareReplay()
    );
  }
}
