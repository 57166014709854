import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import {
  DialogOptionActions,
  DialogTitle,
  AdditionalDialogData,
  Periodicity,
} from 'app/interfaces';

import { NewsletterSendNowService } from 'app/services/newsletter/analytics-newsletter.service';
import { NewsletterDataSource } from 'app/components/newsletter/newsletter.ds';
import { NewsletterDataSourceFactory } from 'app/components/newsletter/newsletter.ds.factory';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { PeriodicityService } from 'app/services/newsletter/periodicity.service';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { IssueNewsletterService } from 'app/services/newsletter/issue-newsletter.service';
import { WindowToken } from 'app/services/utils/window';
import { ResetRouteService } from 'app/services/router/reset-route.service';
import { ColumnConfig } from 'app/components/ui/data-table/data-table.interfaces';
import { cloneDeep } from 'lodash';
import { columnConfig } from './issue-newsletter-column-config';

@Component({
  selector: 'app-issue-newsletter-table',
  templateUrl: './issue-newsletter-table.component.html',
  styleUrls: ['./issue-newsletter-table.component.scss'],
  providers: [ResetRouteService],
})
export class IssueNewsletterTableComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject();
  dataSource: NewsletterDataSource;
  displayedColumns: string[] = [
    'type',
    'title',
    'description',
    'sendEmpty',
    'manage',
  ];
  periodicity$: Observable<Periodicity[]>;
  dataLoading = false;
  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };
  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];

  columnConfig: ColumnConfig[];

  constructor(
    private location: Location,
    private dataSourceFactory: NewsletterDataSourceFactory,
    private newsletterItems: IssueNewsletterService,
    private newsletterSend: NewsletterSendNowService,
    private router: Router,
    private periodicityS: PeriodicityService,
    private dialog: MatDialog,
    private resetRoute: ResetRouteService,
    private activatedRoute: ActivatedRoute,
    @Inject(WindowToken) private window: Window
  ) {}

  ngOnInit() {
    this.columnConfig = cloneDeep(columnConfig);
    this.dataLoading = true;
    this.periodicity$ = this.periodicityS.list().pipe(shareReplay());
    this.resetRoute.configure('/issues', this.onReset.bind(this));
    this.initDataSource();
    this.dataSource.loadData({
      offset: this.getOffsetParam(),
    });
  }

  onReset() {
    this.initDataSource();
    this.dataSource.loadData({
      offset: this.getOffsetParam(),
    });
  }

  /**
   * Load data for table
   */
  initDataSource() {
    this.dataSource = this.dataSourceFactory.getDataSource(
      this.newsletterItems,
      this.location,
      this.router,
      this.activatedRoute
    );

    this.dataSource.loading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => {
        if (!loading) {
          this.window.scrollTo(0, 0);
        }
      });
  }

  redirectToEdit(id) {
    this.router.navigate([`/issues/newsletter/${id}`]);
  }

  getPeriodicityName(id: string): Observable<string> {
    return this.periodicity$.pipe(
      map(list => {
        const entry = list.find(item => item.id === +id);
        if (entry) {
          return entry.name;
        }
      })
    );
  }

  /**
   * Function to call confirm dialog box
   * @return reference to dialog box object
   */
  callConfirmDialog() {
    const message = 'Are you sure you want to delete newsletter permanently?';
    const title: DialogTitle = {
      iconName: 'delete',
      text: 'Delete newsletter',
    };
    const optionalActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionalActions);
  }

  callDelete(newsletter) {
    let msg = '';
    const confirmDialog = this.callConfirmDialog();
    let dialogTitle = this.errorDialogTitle;
    let optionActions = this.defaultDialogOptionActions;

    confirmDialog
      .afterClosed()
      .subscribe(({ confirmed }: AdditionalDialogData) => {
        if (confirmed) {
          this.newsletterItems.delete(newsletter.id).subscribe(
            (response: HttpResponse<any>) => {
              if (response.ok) {
                msg = `Newsletter ${newsletter.title} has been deleted`;
                dialogTitle = this.successDialogTitle;
                optionActions = [
                  {
                    actionAfterClose: true,
                    cssClass: 'mat-stroked-button mat-primary',
                    description: 'Close',
                  },
                ];
              } else {
                msg = `Unexpected error: ${response.body.detail}`;
                this.callDialog(msg, this.errorDialogTitle, optionActions);
              }
            },
            errors => {
              msg = DialogComponent.parseErrorResponse(errors);
              this.callDialog(msg, this.errorDialogTitle, optionActions);
            },
            () => {
              this.callDialog(msg, dialogTitle, optionActions)
                .afterClosed()
                .subscribe(() => {
                  this.dataSource.reset();
                  this.dataSource.loadData();
                });
            }
          );
        }
      });
  }

  /**
   * Function to call dialog box
   * @message: string
   * @title: DialogTitle object
   * @optionActions: DialogOptionActions object
   * @return reference to dialog box
   */
  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  sendNow(newsletter) {
    let msg = '';
    this.dataLoading = true;
    const optionalActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
    ];
    const title: DialogTitle = {
      iconName: 'info',
      text: 'Send newsletter',
    };
    this.newsletterSend.sendNowIssue(newsletter.id).subscribe(
      (response: HttpResponse<any>) => {
        if (response.ok) {
          msg = `Newsletter ${newsletter.title} has been sent`;
        } else {
          msg = `Unexpected error: ${response.body.detail}`;
          this.callDialog(msg, this.errorDialogTitle, optionalActions);
        }
      },
      errors => {
        msg = DialogComponent.parseErrorResponse(errors);
        this.callDialog(msg, this.errorDialogTitle, optionalActions);
        this.dataLoading = false;
      },
      () => {
        this.dataLoading = false;
        this.callDialog(msg, title, optionalActions);
      }
    );
  }

  getOffsetParam(): string {
    return new URLSearchParams(window.location.search).get('offset');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
