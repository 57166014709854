import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompanyTitleService {
  _companyTitle: string;
  base = 'Praedixi';

  constructor() {
    this._companyTitle = this.base;
  }

  set companyTitle(companyTitle) {
    this._companyTitle = `${companyTitle} ${this.base}`;
  }

  get companyTitle() {
    return this._companyTitle;
  }
}
