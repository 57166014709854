import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  iconsConfig = [
    {
      name: 'add_circle',
      path: `${environment.basePath}assets/icons/add_circle.svg`,
    },
    {
      name: 'adjust',
      path: `${environment.basePath}assets/icons/adjust.svg`,
    },
    { name: 'block', path: `${environment.basePath}assets/icons/block.svg` },
    {
      name: 'cancel',
      path: `${environment.basePath}assets/icons/cancel.svg`,
    },
    {
      name: 'delete',
      path: `${environment.basePath}assets/icons/delete.svg`,
    },
    { name: 'info', path: `${environment.basePath}assets/icons/info.svg` },
    {
      name: 'list-bulleted',
      path: `${environment.basePath}assets/icons/list-bulleted.svg`,
    },
    {
      name: 'remove_circle',
      path: `${environment.basePath}assets/icons/remove_circle.svg`,
    },
    { name: 'add', path: `${environment.basePath}assets/icons/add.svg` },
    { name: 'save', path: `${environment.basePath}assets/icons/save.svg` },
    { name: 'edit', path: `${environment.basePath}assets/icons/edit.svg` },
    { name: 'close', path: `${environment.basePath}assets/icons/close.svg` },
    {
      name: 'download',
      path: `${environment.basePath}assets/icons/download.svg`,
    },
    {
      name: 'upload',
      path: `${environment.basePath}assets/icons/upload.svg`,
    },
    {
      name: 'list-bulleted',
      path: `${environment.basePath}assets/icons/list-bulleted.svg`,
    },
    {
      name: 'email',
      path: `${environment.basePath}assets/icons/email.svg`,
    },
    {
      name: 'check',
      path: `${environment.basePath}assets/icons/check.svg`,
    },
    {
      name: 'check-no-rounded',
      path: `${environment.basePath}assets/icons/check-no-rounded.svg`,
    },
    {
      name: 'draft',
      path: `${environment.basePath}assets/icons/draft.svg`,
    },
    {
      name: 'save-edit',
      path: `${environment.basePath}assets/icons/save-edit.svg`,
    },
    {
      name: 'calendar',
      path: `${environment.basePath}assets/icons/calendar.svg`,
    },
    {
      name: 'cog',
      path: `${environment.basePath}assets/icons/cog.svg`,
    },
    {
      name: 'eye',
      path: `${environment.basePath}assets/icons/eye.svg`,
    },
    {
      name: '12h-clock',
      path: `${environment.basePath}assets/icons/12h-clock.svg`,
    },
    {
      name: '24h-clock',
      path: `${environment.basePath}assets/icons/24h-clock.svg`,
    },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  load() {
    this.iconsConfig.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }
}
