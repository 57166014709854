import { SelectOptionsConfig } from '../profile/select-option-config';
import { SearchQueryFilterData } from 'app/interfaces';

const filtersToRemove = ['key_terms', 'date', 'domain', 'url', 'title'];
const selectOptions = SelectOptionsConfig.filter(
  option => !filtersToRemove.includes(option.safeName)
).map(option => {
  return {
    ...option,
    onlyOneInstance: true,
  };
});

const FiltersConfig: SearchQueryFilterData = {
  selectOptions,
  operatorOptions: {
    selectedValue: '',
    options: [
      {
        value: 'include',
        iconName: 'adjust',
      },
      {
        value: 'exclude',
        iconName: 'block',
      },
    ],
  },
  queryRowsOptions: {
    title: 'Tags',
    queryRowsList: [
      {
        selectedOption: '',
        chips: [],
        _valid: true,
      },
    ],
  },
};

export { FiltersConfig };
