import { Injectable } from '@angular/core';
import { Chip } from 'app/interfaces';

interface ChipConfig {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChipCreatorService {
  create(config: string | ChipConfig): Chip {
    return typeof config === 'string'
      ? { name: config, value: config }
      : { name: config.name, value: config.value };
  }

  formatName(name: string): string {
    return name.length > 45
      ? `${name.substr(0, 25)} ... ${name.substr(-10)}`
      : name;
  }
}
