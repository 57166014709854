import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { environment } from 'env/environment';

@Injectable()
export class AnalyticsNewsletterService {
  newsletterEndpoint =
    environment.apiUrls.news_ws + '/notifications/analyticsuserpreferences';
  constructor(private rest: RestService) {}

  list(args: any) {
    return this.rest.get(this.newsletterEndpoint, { limit: 50, ...args });
  }

  create(data) {
    return this.rest.post(this.newsletterEndpoint, data);
  }

  getNewslleter(id) {
    return this.rest.get(this.newsletterEndpoint + '/' + id, { limit: 50 });
  }

  edit(data, id) {
    return this.rest.put(this.newsletterEndpoint + '/' + id, data);
  }

  delete(id) {
    return this.rest.delete(this.newsletterEndpoint + '/' + id);
  }
}

@Injectable()
export class NewsletterSendNowService {
  newsletterSendEndpoint =
    environment.apiUrls.news_ws + '/notifications/send-now';

  newsletterSendNowIssueEndpoint =
    environment.apiUrls.news_ws + '/issue/send-now';

  constructor(private rest: RestService) {}

  sendNow(id) {
    return this.rest.post(this.newsletterSendEndpoint, { id: id });
  }

  sendNowIssue(id) {
    return this.rest.post(this.newsletterSendNowIssueEndpoint, { id: id });
  }
}
