import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'GMTTimezone',
})
@Injectable()
export class TimezonePipe implements PipeTransform {
  transform(value: string): string {
    const prefix = value[0];
    const hour = value.slice(1, 3);
    const minutes = value.slice(3, 5);
    return `(GMT${prefix}${hour}:${minutes})`;
  }
}
