import { Component } from '@angular/core';

@Component({
  selector: 'app-google-translate-widget',
  templateUrl: './google-translate-widget.component.html',
  styleUrls: ['./google-translate-widget.component.scss'],
})
export class GoogleTranslateWidgetComponent {
  gFunction = `function googleTranslateElementInit() {
          new google.translate.TranslateElement({
            pageLanguage: 'en',
            layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
            multilanguagePage: true
            },
          'google_translate_element'); }`;
  gStript = `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
  gStyleModal = `'<style>'+
             '.goog-te-menu2 {'+
                 'border-radius:4px; !important;' +
                 'border: none;'+
                 'font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;' +
                 'color: ' +
             '}'+
         '</style>'`;

  styleScriptWidget = `
  function changeGoogleWidgetStyle() {
  if(goo = document.getElementById(':0.targetLanguage')) {
       goo.style.borderRadius='4px';
       goo.style.border='1px solid #aaa';
       goo.style.marginBottom='4px';
       goo.style.minHeight='32px';
       goo.style.fontFamily='Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif';
       widgetValue = document.getElementsByClassName('goog-te-menu-value')[0]
       widgetValue.style.fontSize='14px';
       widgetValue.style.color='#555555';
       widgetValue.style.marginTop='10px';
       widgetValue.getElementsByTagName('span')[0].style.marginLeft='-20px';
       document.getElementsByClassName('goog-te-gadget-icon')[0].style.opacity = '0';
       document.getElementsByClassName('goog-te-gadget-icon')[0].style.marginTop='10px';
       } else {
          setTimeout(changeGoogleWidgetStyle, 50);
          }
       }
  changeGoogleWidgetStyle();
  `;
}
