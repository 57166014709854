import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translatePeriodicity',
})
export class TranslatePeriodicityPipe implements PipeTransform {
  transform(value: any, periodicity: any) {
    if (periodicity && value) {
      const aa = periodicity.find(x => x.id === +value);
      if (aa) {
        return aa['name'];
      } else {
        return '';
      }
    }
  }
}
