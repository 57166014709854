import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { PeriodicityService } from 'app/services/newsletter/periodicity.service';
import {
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  Chip,
  Periodicity,
  IssueType,
} from 'app/interfaces';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { Observable } from 'rxjs';
import { IssueTypeServiceService } from 'app/services/issues/issue-type-service.service';
import { IssueNewsletterService } from 'app/services/newsletter/issue-newsletter.service';
import { DialogRedirectService } from 'app/services/dialog/dialog-redirect.service';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { Location } from '@angular/common';

@Component({
  selector: 'app-issue-newsletter-form',
  templateUrl: './issue-newsletter-form.component.html',
  styleUrls: ['./issue-newsletter-form.component.scss'],
})
export class IssueNewsletterFormComponent implements OnInit {
  DEFAULT_TIME = '09:00';
  DEFAULT_TIMEZONE = momentTimezone.tz.guess();

  issueType$: Observable<IssueType[]>;
  periodicity$: Observable<Periodicity[]>;
  editedObjData;

  chips: Chip[] = [];
  sendEmpty = false;
  formdata: FormGroup;
  initialTime: string;

  iconName: string;
  pageTitle: string;

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };

  issueNewsletterEndpoint = '/notifications/issueuserpreferences';

  constructor(
    private fb: FormBuilder,
    private issueType: IssueTypeServiceService,
    private newsletterS: IssueNewsletterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private periodicityS: PeriodicityService,
    private dialogRedirect: DialogRedirectService,
    private location: Location
  ) {}

  ngOnInit() {
    this.issueType$ = this.issueType.get();
    this.periodicity$ = this.periodicityS.list();

    this.formdata = this.fb.group({
      title: ['', Validators.required],
      description_text: ['', Validators.maxLength(5000)],
      periodicity: ['', Validators.required],
      issue_type: ['', Validators.required],
      sendEmpty: [false],
      selected_time: [''],
      timezone: [''],
    });

    this.getParams();
  }

  onTimeChanged(time: string): void {
    let UTCDate = '';

    const isTwelveFormat = (timeString: string) => {
      const lastCharacter = +timeString.charAt(timeString.length - 1);
      return isNaN(lastCharacter);
    };

    if (isTwelveFormat(time)) {
      UTCDate = moment.utc(time, 'h:m A').format();
    } else {
      const hour = +time.slice(0, 2);
      const minute = +time.slice(3, 5);
      UTCDate = moment.utc({ hour, minute }).format();
    }

    this.formdata.controls.selected_time.setValue(UTCDate);
    this.formdata.markAsDirty();
  }

  onSaveClick(data) {
    this.callConfirmDialog(!this.editedObjData)
      .afterClosed()
      .subscribe(({ confirmed }: AdditionalDialogData) => {
        if (confirmed) {
          if (!this.editedObjData) {
            this.create(data);
          } else {
            this.update(data);
          }
        }
      });
  }

  update(data) {
    let msg = '';
    let dialogTitle: DialogTitle;
    let optionActions: DialogOptionActions[];

    this.newsletterS.edit(data, this.editedObjData.id).subscribe(
      (response: HttpResponse<any>) => {
        msg = `Newsletter ${response.body.title} updated`;
        dialogTitle = this.successDialogTitle;
        optionActions = [
          {
            actionAfterClose: true,
            cssClass: 'mat-stroked-button mat-primary',
            description: 'Close',
          },
        ];
      },
      errors => {
        dialogTitle = this.errorDialogTitle;
        msg = DialogComponent.parseErrorResponse(errors);
        this.callDialog(msg, dialogTitle, optionActions);
      },
      () => {
        this.callDialog(msg, dialogTitle, optionActions);
        this.router.navigate(['issues'], { queryParams: { tab: 'subscribe' } });
      }
    );
  }

  /**
   * Create new newsletter object and navigate to newsletter list
   * @param preparedData validated form data
   */
  create(preparedData) {
    let msg = '';
    let dialogTitle: DialogTitle;
    let optionActions: DialogOptionActions[];

    this.newsletterS.create(preparedData).subscribe(
      (response: HttpResponse<any>) => {
        msg = `Newsletter ${response.body.title} created`;
        dialogTitle = this.successDialogTitle;
        optionActions = [
          {
            actionAfterClose: true,
            cssClass: 'mat-stroked-button mat-primary',
            description: 'Close',
          },
        ];
      },
      errors => {
        dialogTitle = this.errorDialogTitle;
        msg = DialogComponent.parseErrorResponse(errors);
        this.callDialog(msg, dialogTitle, optionActions);
      },
      () => {
        this.callDialog(msg, dialogTitle, optionActions);
        this.router.navigate(['issues'], { queryParams: { tab: 'subscribe' } });
      }
    );
  }

  /**
   * Get edited object id from url and ask news_ws api about object details
   */
  getParams() {
    const ID = this.activatedRoute.snapshot.params.id;
    if (ID) {
      this.newsletterS.getNewslleter(ID).subscribe(
        response => {
          this.editedObjData = response.body;
          this.iconName = 'edit';
          this.pageTitle = 'Edit ' + this.editedObjData.title;
        },
        errors => {
          if (errors.status === 404) {
            this.dialogRedirect.callDialog('/issues', { tab: 'subscribe' });
          }
        },
        () => {
          this.populateDataFromUrl();
        }
      );
    } else {
      this.iconName = 'add';
      this.pageTitle = 'Add Newsletter';
      this.formdata.controls.timezone.setValue(this.DEFAULT_TIMEZONE);
      this.initialTime = this.DEFAULT_TIME;
      this.formdata.controls.selected_time.setValue(
        moment.utc({ hour: 9 }).format()
      );
    }
  }

  /**
   * Populate data for form inputs
   */
  populateDataFromUrl(): void {
    const data = this.editedObjData;

    this.formdata.patchValue(data);

    if (this.editedObjData.selected_time) {
      const dateObj = moment(this.editedObjData.selected_time).utc();
      const hours = dateObj.hours();
      const minutes = dateObj.minutes();
      this.initialTime = `${hours}:${minutes}`;
    }
  }

  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  onCancelClick() {
    this.isDirty() ? this.callCancelDialog() : this.location.back();
  }

  isDirty(): boolean {
    return this.formdata.dirty;
  }

  isFormValid(): boolean {
    return this.formdata.invalid || !this.isDirty();
  }

  callConfirmDialog(createNew?: boolean) {
    const message = 'Are you sure you want to save the newsletter?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    if (createNew) {
      title = {
        iconName: 'add',
        text: 'Save newsletter',
      };
    } else {
      title = {
        iconName: 'edit',
        text: 'Update newsletter',
      };
    }
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionActions);
  }

  callCancelDialog(): void {
    const message =
      'Are you sure you want to discard changes in the newsletter?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    title = {
      iconName: 'cancel',
      text: 'Cancel',
    };
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Return to newsletter',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Yes, discard changes',
      },
    ];
    const ref = this.callDialog(message, title, optionActions);
    ref.afterClosed().subscribe(({ confirmed }: AdditionalDialogData) => {
      if (confirmed) {
        this.location.back();
      }
    });
  }
}
