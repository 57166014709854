import { ColumnConfig } from 'app/components/ui/data-table/data-table.interfaces';

const columnConfig: ColumnConfig[] = [
  {
    key: 'type',
    name: 'Type',
    cellTemplate: true,
    style: {
      'min-width': '100px',
    },
  },
  {
    key: 'title',
    name: 'Title',
  },
  {
    key: 'description_text',
    name: 'Description',
  },
  {
    key: 'sendEmpty',
    name: 'Send empty',
    cellTemplate: true,
    style: {
      'min-width': '100px',
    },
  },
  {
    key: 'manage',
    name: 'Manage',
    cellTemplate: true,
  },
];

export { columnConfig };
