export const environment = {
  production: false,
  apiUrls: {
    hello: '/decernis.news_ws5/api/auth/hello?version=2.0',
    news_ws: '/decernis.news_ws5/api',
    oauth2: '/decernis.news_ws5/api/auth/oauth2',
    oauth: '/decernis.news_ws5/api/auth',
    config: '/decernis.news_ws5/api/config',
  },
  highlightsPageLimit: 20,
  redirectToOriginAfterLogout: true,
  redirectToOriginAfterLogoutExtraPath: '',
  basePath: '/',
  logoutUrl: 'https://staging-auth2.decernis.com/logout?next=',
  // piwik settings
  piwik: false, // enable disable piwik tracking
  piwikServerUrl: 'metrics.decernis.com', // piwik server
  // page identifiers used by the piwik to track them
  piwikSiteIdgcSearch: 9, // id for gcSearch site
  piwikSiteIdAnalytics: 6, // id for the rest sites in application
  tinyMCEAPIKey: 'hy1co6i8un7h2iy3tf1xzern3zksop3qn5i6jvb4znffd50h',
  trackedByGoogle: true,
  googleGlobalSiteTag: '',
  // zendesk
  jwtUrl: 'https://staging-auth2.decernis.com/api/zendesk_jwt_widget',
  zendeskCategory: '360005225732',
};
