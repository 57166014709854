import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SolrQueryParserService {
  constructor() {}

  parseQueryStringToKeyTermsTree(solrQuery) {
    let query = this.removeUnnecessaryParenthesis(solrQuery);
    const groupRegex = new RegExp(
      /(AND NOT|NOT|AND|OR)?\s?\(+([a-zA-Z].*?)\)/,
      'g'
    );

    const checkMatchGroup = queryMatch =>
      Array.from(queryMatch.matchAll(groupRegex)).length;
    const queryList = [];

    while (query) {
      if (checkMatchGroup(query) !== 0) {
        const keyTerms = Array.from(query.matchAll(groupRegex))
          .filter(
            ([fullMatch, operator]) =>
              fullMatch.includes('key_terms') &&
              operator !== 'NOT' &&
              operator !== 'AND NOT'
          )
          .map(([fullMatch]) => this.getKeyTerms(fullMatch));

        queryList.push(keyTerms);
        query = query.replace(groupRegex, '');
      } else {
        queryList.push(this.getKeyTerms(query));
        query = '';
      }
    }
    return queryList;
  }

  getKeyTerms(queryString): any[] {
    const keyTermRegex = new RegExp(
      /(AND NOT|NOT|AND|OR)?\s?\(?key_terms:\s?"(.*?)"/,
      'g'
    );

    return Array.from(queryString.matchAll(keyTermRegex))
      .filter(
        fullMatch =>
          fullMatch[0].includes('key_terms') &&
          fullMatch[1] !== 'NOT' &&
          fullMatch[1] !== 'AND NOT'
      )
      .map(fullMatch => {
        const keyTerm = fullMatch[2];
        return keyTerm;
      });
  }

  removeUnnecessaryParenthesis(queryString) {
    const removePair = string =>
      string.replace(
        /(\(+)((?!(AND NOT|NOT|AND|OR)).)*(\)+)/g,
        (fullMatch, left, right) => {
          const min = Math.min(left.length, right.length);
          fullMatch = fullMatch.slice(min, fullMatch.length - min);
          return fullMatch;
        }
      );

    return removePair(queryString);
  }

  generateKeyTermsList(queryString): string[] {
    return _.flattenDeep(this.parseQueryStringToKeyTermsTree(queryString));
  }
}
