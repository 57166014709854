import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColumnConfig, ColumnOutput } from 'app/interfaces';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-column-picker',
  templateUrl: './column-picker.component.html',
  styleUrls: ['./column-picker.component.scss'],
})
export class ColumnPickerComponent implements OnInit {
  @Output() onChange: EventEmitter<ColumnOutput> = new EventEmitter();
  @Input() columnConfig: ColumnConfig[];

  constructor() {}

  ngOnInit() {}

  onCheckboxChange($event: MatCheckboxChange, key: string) {
    this.onChange.emit({ checked: $event.checked, key });
  }
}
