<ng-container *ngIf="multiple; else withoutChips">
  <mat-form-field
    class="theme-decernis__form-field theme-decernis__form-field--with-chips"
    appearance="outline"
  >
    <mat-chip-list class="theme-decernis__chip-list" #chipList>
      <app-chip
        *ngFor="let chip of chipsList"
        [selectable]="selectable"
        [removable]="removable"
        [name]="chip.name"
        color="accent"
        (removed)="remove(chip)"
      >
      </app-chip>
      <input
        matInput
        #keyTermInput
        [formControl]="searchInputControl"
        [placeholder]="inputPlaceholder()"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        [matAutocomplete]="keyTermAuto"
      />
      <ng-container *ngIf="withExtraActions">
        <mat-icon
          *ngIf="chipsList.length"
          matSuffix
          svgIcon="save"
          color="primary"
          class="theme-decernis__icon theme-decernis__icon--medium"
          matTooltip="Download to file"
          (click)="downloadKeyTerms()"
        >
        </mat-icon>
        <mat-icon
          matSuffix
          svgIcon="upload"
          color="primary"
          (click)="importKeyTerms()"
          class="theme-decernis__icon theme-decernis__icon--medium"
          matTooltip="Import from file"
        >
        </mat-icon>
      </ng-container>
    </mat-chip-list>

    <mat-error *ngIf="searchInputControl.errors?.notEmpty">
      Please set correct value
    </mat-error>

    <mat-autocomplete
      #keyTermAuto="matAutocomplete"
      (optionSelected)="optionSelected($event.option.value)"
    >
      <mat-option *ngIf="isLoading" class="is-loading">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-option>

      <ng-container *ngIf="!isLoading">
        <h5 class="theme-decernis__autocomplete-header">
          SEARCH BY THE SUBSTANCE NAME, CAS NUMBER OR ITS SYNONYMS
        </h5>
        <div class="theme-decernis_autocomplete-body">
          <mat-table
            [dataSource]="keyTermArray"
            class="theme-decernis__autocomplete-table"
          >
            <ng-container matColumnDef="key_term">
              <mat-header-cell *matHeaderCellDef>Key Terms</mat-header-cell>
              <mat-cell *matCellDef="let opt">
                {{ opt.key_term }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="synonym">
              <mat-header-cell *matHeaderCellDef>Synonyms</mat-header-cell>
              <mat-cell *matCellDef="let opt">
                <span
                  [innerHTML]="opt.synonym | highlight: params.search"
                ></span>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedAutocompleteColumns"
            ></mat-header-row>
            <mat-option
              *matRowDef="let opt; columns: displayedAutocompleteColumns"
              [value]="opt.key_term"
            >
              <mat-row></mat-row>
            </mat-option>
          </mat-table>
        </div>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>

<ng-template #withoutChips>
  <mat-form-field class="theme-decernis__form-field" appearance="outline">
    <input
      matInput
      placeholder="Add Key Term"
      [matAutocomplete]="keyTermAuto"
      #keyTermInput
    />
    <mat-icon matSuffix svgIcon="add"></mat-icon>
    <mat-autocomplete
      #keyTermAuto="matAutocomplete"
      (optionSelected)="optionSelected($event.option.value)"
    >
      <mat-option *ngIf="isLoading" class="is-loading">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <h5 class="theme-decernis__autocomplete-header">
          SEARCH BY THE SUBSTANCE NAME, CAS NUMBER OR ITS SYNONYMS
        </h5>
        <div class="theme-decernis_autocomplete-body">
          <mat-table
            [dataSource]="keyTermArray"
            class="theme-decernis__autocomplete-table"
          >
            <ng-container matColumnDef="key_term">
              <mat-header-cell *matHeaderCellDef>Key Terms</mat-header-cell>
              <mat-cell *matCellDef="let opt">
                {{ opt.key_term }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="synonym">
              <mat-header-cell *matHeaderCellDef>Synonyms</mat-header-cell>
              <mat-cell *matCellDef="let opt">
                <span
                  [innerHTML]="opt.synonym | highlight: params.search"
                ></span>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedAutocompleteColumns"
            ></mat-header-row>
            <mat-option
              *matRowDef="let opt; columns: displayedAutocompleteColumns"
              [value]="opt.key_term"
            >
              <mat-row></mat-row>
            </mat-option>
          </mat-table>
        </div>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
