import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { Observable } from 'rxjs';
import { Highlight, HttpResponseBodyWithPagination } from 'app/interfaces';
import { RestService } from 'sso-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentHighlightService {
  constructor(private rest: RestService) {}

  getData(docID: string[], keyTerm: string[]): Observable<Highlight[]> {
    const APIUrl = `${environment.apiUrls.news_ws}/analytics/match_terms`;
    const payload = {
      ids: docID,
      key_terms: keyTerm,
    };
    return this.rest
      .post<HttpResponseBodyWithPagination<Highlight[]>>(APIUrl, payload)
      .pipe(map(response => response.body.results));
  }
}
