import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  open(message: string) {
    const snackBarConfg = new MatSnackBarConfig();
    snackBarConfg.panelClass = ['mat-snack-bar'];
    snackBarConfg.duration = 60000;
    this.snackBar.open(message, 'Close', snackBarConfg);
  }
}
