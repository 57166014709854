import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  TimezoneService,
  Timezone,
} from 'app/services/timezone/timezone.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Observable } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-timezone-picker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './timezone-picker.component.html',
  styleUrls: ['./timezone-picker.component.scss'],
})
export class TimezonePickerComponent implements OnInit {
  @Output() timezoneSelected: EventEmitter<Timezone> = new EventEmitter();
  @Input() control: FormControl;
  timezones: Timezone[];
  timezones$: Observable<Timezone[]>;
  filterTimezoneCtrl: FormControl = new FormControl();
  filteredTimezone: ReplaySubject<Timezone[]> = new ReplaySubject<Timezone[]>(
    1
  );
  @ViewChild('timezoneSelect') timezoneSelect: MatSelect;

  constructor(private timezoneService: TimezoneService) {}

  ngOnInit() {
    this.timezoneService.getTimezones().subscribe(timezones => {
      this.timezones = timezones;
      this.filteredTimezone.next(timezones);
    });

    this.filterTimezoneCtrl.valueChanges.subscribe(() => {
      this.filterTimezones();
    });
  }

  onSelectChange(event: MatSelectChange) {
    this.timezoneSelected.emit(event.value);
  }

  filterTimezones() {
    if (!this.timezones) {
      return;
    }

    let search = this.filterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezone.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTimezone.next(
      this.timezones.filter(zone => {
        return (
          zone.timezone.toLowerCase().indexOf(search) > -1 ||
          zone.offset.toLowerCase().indexOf(search) > -1
        );
      })
    );
  }
}
