import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  Input,
  ViewChild,
} from '@angular/core';
import { KeyTermDropdownComponent } from 'app/components/ui/key-term-dropdown/key-term-dropdown.component';

import { Chip } from 'app/interfaces';
import { ChipCreatorService } from 'app/services/chip-creator.service';
import { Subscription } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-key-terms-select',
  styleUrls: ['./key-terms-select.component.scss'],
  templateUrl: './key-terms-select.component.html',
})
export class KeyTermsSelectComponent {
  @Output() valid: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedOutput: EventEmitter<Chip[]> = new EventEmitter();
  @ViewChild('keyTermDropdown', { static: true })
  keyTermDropdownComponent: KeyTermDropdownComponent;
  chipList: Chip[] = [];
  clearOnSelect = true;
  blurOnSelect = true;
  multiple = true;
  navigationSubscription: Subscription;

  @Input() set initialKeyTerms(keyTerms: string[]) {
    this.insertInitialVales(keyTerms);
  }

  constructor(private chipCreator: ChipCreatorService) {}

  insertInitialVales(keyTerms: string[]) {
    this.chipList = keyTerms.map(term => this.chipCreator.create(term));
    this.selectedOutput.emit(this.chipList);
  }

  optionSelected(items: Chip[]) {
    this.selectedOutput.emit(items);
  }

  onKeyTermsValidUpdate(valid: boolean) {
    this.valid.emit(valid);
  }

  /**
   * Clear key term input in select component
   */
  reset(): void {
    this.keyTermDropdownComponent.reset();
    this.chipList = [];
  }
}
