import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ResetRouteService } from 'app/services/router/reset-route.service';
import { OptOutService } from 'app/services/issues/issue-subscriptions.service';
import { HttpResponse } from '@angular/common/http';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { DialogOptionActions, DialogTitle } from 'app/interfaces';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-issue-home',
  templateUrl: './issue-home.component.html',
  styleUrls: ['./issue-home.component.scss'],
  providers: [ResetRouteService],
})
export class IssueHomeComponent implements OnInit {
  selectedTab = 0;
  tabs = [
    {
      name: 'view',
      label: 'View',
    },
    {
      name: 'subscribe',
      label: 'Subscribe',
    },
  ];

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };
  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];

  constructor(
    private location: Location,
    private resetRoute: ResetRouteService,
    private optOut: OptOutService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setActiveTab();
    this.checkIfCallOptOut();
    this.resetRoute.configure('/issues', this.changeTab.bind(this, 0));
  }

  setActiveTab() {
    new URLSearchParams(window.location.search).forEach((value, key) => {
      if (key === 'tab' && value === 'subscribe') {
        this.selectedTab = 1;
      }
    });
  }

  checkIfCallOptOut() {
    new URLSearchParams(window.location.search).forEach((value, key) => {
      if (key === 'token' && value.length === 32) {
        this.optOutAction(value);
      }
    });
  }

  /**
   * Function to call dialog box
   * @message: string
   * @title: DialogTitle object
   * @optionActions: DialogOptionActions object
   * @return reference to dialog box
   */
  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  optOutAction(token) {
    let msg = '';
    const optionalActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
    ];
    const title: DialogTitle = {
      iconName: 'info',
      text: 'Send newsletter',
    };
    this.optOut.optOutFromSubscription(token).subscribe(
      (response: HttpResponse<any>) => {
        if (response.ok) {
          msg = `You have unsubscribed from the newsletter`;
        } else {
          msg = `Unexpected error: ${response.body.detail}`;
          this.callDialog(msg, this.errorDialogTitle, optionalActions);
        }
      },
      errors => {
        msg = DialogComponent.parseErrorResponse(errors);
        this.callDialog(msg, this.errorDialogTitle, optionalActions);
      },
      () => {
        this.callDialog(msg, title, optionalActions);
        this.location.replaceState(this.location.path().split('?')[0], '');
      }
    );
  }

  changeTab(index: number) {
    const tab = this.tabs[index];
    this.selectedTab = index;
    this.location.replaceState('issues', `tab=${tab.name}`);
  }
}
