import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'env/environment';
import {
  DropzoneConfigInterface,
  DropzoneComponent,
} from 'ngx-dropzone-wrapper';
import { filePreviewTemplate } from './file-preview-template';

interface File {
  file_name: string;
  uploaded_file: string;
  id: number;
}

interface InvalidFile {
  name: string;
  status: string;
  upload: {
    uuid: string;
  };
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  @Input() maxTotalSize = 10;
  @Input() maxFilesize = 10;
  @Output() onProcessingStart = new EventEmitter();
  @Output() onProcessingEnd = new EventEmitter();
  @Output() onProcessingSuccess = new EventEmitter();
  @Output() onFilesChange: EventEmitter<File[]> = new EventEmitter();
  @Output() onInvalidFiles = new EventEmitter<InvalidFile[]>();

  @ViewChild('dropzone', { static: true }) dropzone: DropzoneComponent;

  message = 'Drag and drop or browse your files';

  config: DropzoneConfigInterface = {
    accept: this.dzAccept.bind(this),
    clickable: true,
    maxFilesize: this.maxFilesize,
    autoProcessQueue: true,
    addRemoveLinks: true,
    acceptedFiles: '.pdf,.csv,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.png,.jpeg',
    url: `${environment.apiUrls.news_ws}/issue/attachment`,
    filesizeBase: 1024,
    paramName: 'uploaded_file',
    dictFileTooBig: `File is too big. Max filesize: ${this.maxFilesize}MB.`,
    createImageThumbnails: false,
    previewTemplate: filePreviewTemplate,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    },
    params: (files, xhr, chunk) => {
      const [last] = [...files].reverse();
      const name = last.name;
      return {
        file_name: name,
      };
    },
  };

  public files: File[] = [];
  invalidFiles: InvalidFile[] = [];
  totalFilesSize = 0;

  @Input() set initialFiles(files: File[]) {
    if (files && this.files.length !== files.length) {
      this.mockFiles(files);
    }
  }

  constructor() {}

  ngOnInit() {}

  mockFiles(files: File[]) {
    const dropzone = this.dropzone.directiveRef.dropzone();
    if (dropzone) {
      files.forEach(file => {
        const mockFile = {
          name: file.file_name,
          size: 22345,
          type: `image/jpg`,
          accepted: true,
          dataURL: file.uploaded_file,
        };

        dropzone.emit('addedfile', mockFile);
        dropzone.emit('complete', mockFile);
        dropzone.files.push(mockFile);
      });

      this.files = files;
    }
  }

  dzAccept(file, done) {
    if (!file.size) {
      done('Empty files are not allowed');
      return;
    }

    if (this.maxTotalSize) {
      if (this.totalFilesSize + file.size > this.maxTotalSize * 1024 * 1024) {
        done(
          'Your attachments exceeded the maximum ' +
            this.maxTotalSize +
            ' MB. ' +
            'Please remove some of the files.'
        );
        return;
      }
    }

    done();
    this.totalFilesSize += file.size;
  }

  dzSuccess([file, response, progress]): void {
    this.files.push({
      file_name: response.filename || file.name,
      uploaded_file: response.uploaded_file,
      id: response.id,
    });
    this.emit();
    this.onProcessingSuccess.emit();
  }

  dzRemovedFile(file): void {
    this.invalidFiles = this.invalidFiles.filter(
      fileEntry => fileEntry.upload.uuid !== file.upload.uuid
    );
    this.onInvalidFiles.emit(this.invalidFiles);
    if (!file.accepted) {
      return;
    }

    this.files = this.files.filter(
      fileEntry => fileEntry.file_name !== file.name
    );
    this.emit();
  }

  dzSending(): void {
    this.onProcessingStart.emit();
  }

  dzComplete(event): void {
    if (event.status === 'error') {
      this.invalidFiles.push(event);
    }
    this.onInvalidFiles.emit(this.invalidFiles);
    this.onProcessingEnd.emit();
  }

  emit(): void {
    this.onFilesChange.emit(this.files);
    this.onInvalidFiles.emit(this.invalidFiles);
  }
}
