import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AsyncTracker,
  AsyncTrackerFactory,
} from '../async-tracker/async-tracker-factory.service';

@Component({
  selector: 'app-loading',
  styles: [],
  animations: [
    trigger('showState', [
      state(
        '1',
        style({
          display: 'block',
          opacity: 1,
        })
      ),
      state(
        '0',
        style({
          display: 'none',
          opacity: 0,
        })
      ),
      transition('1 => 0', animate('300ms ease-in')),
      transition('0 => 1', animate('300ms ease-out')),
    ]),
  ],
  template: `
    <div
      class="text-center"
      class="loading-indicator"
      [class.shown]="show"
      [@showState]="show"
    >
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
      <br />
      <p class="text-muted text-center">{{ text }}</p>
    </div>
  `,
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() text = 'Loading...';
  @Input() show = true;
  asyncTracker: AsyncTracker;

  @Input() set busy(value: Array<any>) {
    this.busyList = value;
    this.asyncTracker.clear();
    for (const busyThing of this.busyList) {
      if (!busyThing) {
        continue;
      }
      this.asyncTracker.add(busyThing);
    }
  }

  get busy(): Array<any> {
    return this.busyList;
  }

  private busyList = [];
  private asyncActiveSubscription: Subscription;

  constructor(private asyncTrackerFactory: AsyncTrackerFactory) {}

  ngOnInit() {
    this.asyncTracker = this.asyncTrackerFactory.create();
    // Modify the `show` property only if we're actually working on a
    // promise list
    this.asyncActiveSubscription = this.asyncTracker.active$.subscribe(
      value => {
        if (this.busyList.length) {
          this.show = value;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.asyncActiveSubscription) {
      this.asyncActiveSubscription.unsubscribe();
    }
  }
}
