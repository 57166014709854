import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  AfterViewInit,
} from '@angular/core';
import {
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  ColumnOutput,
  Profile,
} from 'app/interfaces';
import { ProfilesDataSourceService } from 'app/services/profile/profiles-data-source.service';
import { MatPaginator } from '@angular/material/paginator';
import { ProfilesColumnConfig } from './profiles-column-config';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { ColumnFilterValue } from 'app/interfaces';
import { ProfilesV2Service } from 'app/services/analytics/profile.service';
import { WindowToken } from 'app/services/utils/window';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { DialogComponent } from '../ui/dialog/dialog.component';
import { ColumnPickerService } from 'app/components/ui/data-table/column-picker/column-picker.service';
import { ResetRouteService } from 'app/services/router/reset-route.service';
import { ColumnConfig } from '../ui/data-table/data-table.interfaces';
import { Location } from '@angular/common';
import { RequestArgs } from '../newsletter/newsletter.ds';
import { AuthService } from 'sso-angular';

@Component({
  selector: 'app-profiles-table',
  templateUrl: './profiles-table.component.html',
  styleUrls: ['./profiles-table.component.scss'],
  providers: [ResetRouteService],
})
export class ProfilesTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  DEFAULT_SORTING_KEY = 'title';
  DEFAULT_DIRECTION_KEY: 'asc' | 'desc' = 'asc';
  DEFAULT_PREFIX = '';

  sortName: string;
  sortDirection: 'asc' | 'desc';

  columnConfig: ColumnConfig[];
  routePath = 'profiles';
  localStorageColumnKey = 'profiles_columns';
  filters: ColumnFilterValue = {};
  sorting: ColumnFilterValue;
  dataSource: ProfilesDataSourceService;
  baseParams: RequestArgs = {
    limit: '50',
    offset: '0',
    ordering: this.DEFAULT_PREFIX + this.DEFAULT_SORTING_KEY,
  };

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };

  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];
  userHasPermissionsToShareProfile = false;

  constructor(
    private router: Router,
    private profileService: ProfilesV2Service,
    private dialog: MatDialog,
    private columnDialog: ColumnPickerService,
    private resetRoute: ResetRouteService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private auth: AuthService,
    @Inject(WindowToken) private window: Window
  ) {}

  ngOnInit() {
    this.initColumnConfig();
    this.initDataSource();
    this.initFilters();
    this.userHasPermissionsToShareProfile = this.auth.checkPermission(
      'analytics.share_profile_perm'
    );
  }

  ngAfterViewInit() {
    this.resetRoute.configure('/profiles', this.onReInit.bind(this));
    this.dataSource.loadData(this.dataSource.generateArgs());
  }

  onReInit() {
    this.initColumnConfig();
    this.sortDirection = this.DEFAULT_DIRECTION_KEY;
    this.sortName = this.DEFAULT_SORTING_KEY;
    this.dataSource.reset();
    this.dataSource.loadData(this.dataSource.generateArgs());
  }

  initDataSource() {
    this.dataSource = new ProfilesDataSourceService(
      this.profileService,
      this.window,
      this.router,
      this.activatedRoute,
      this.location
    );

    this.dataSource.loading$.subscribe(loading => {
      if (!loading) {
        this.window.scrollTo(0, 0);
      }
    });

    const sortInUrl = this.getSortingName();
    if (this.getSortingName()) {
      if (sortInUrl.startsWith('-')) {
        this.sortDirection = 'desc';
        this.sortName = sortInUrl.substring(1);
      } else {
        this.sortDirection = 'asc';
        this.sortName = sortInUrl;
      }
    } else {
      this.sortDirection = this.DEFAULT_DIRECTION_KEY;
      this.sortName = this.DEFAULT_SORTING_KEY;
    }

    this.dataSource.defaultSortDirection = this.DEFAULT_DIRECTION_KEY;
    this.dataSource.defaultSortName = this.DEFAULT_SORTING_KEY;
  }

  initColumnConfig(): void {
    this.columnConfig = _.cloneDeep(ProfilesColumnConfig);
    const visibleColumns = JSON.parse(
      localStorage.getItem(this.localStorageColumnKey)
    );

    const areColumnKeysValid =
      visibleColumns &&
      visibleColumns.every(columnName =>
        this.columnConfig.map(column => column.key).includes(columnName)
      );

    if (areColumnKeysValid) {
      this.columnConfig.map(config => {
        config.hide = !visibleColumns.includes(config.key);
      });

      this.columnConfig = [...this.columnConfig];
    }
  }

  initFilters() {
    const urlParams = this.getParamsFromUrl();

    of(urlParams).subscribe(params => {
      this.filters = params;

      Object.keys(params).forEach(key => {
        const config = this.columnConfig.find(col => col.filter.name === key);
        if (config) {
          config.filter.value = params[key];
        }
      });
    });
  }

  getSortingName(): string {
    return new URLSearchParams(window.location.search).get('ordering');
  }

  getParamsFromUrl() {
    const params = {};

    new URLSearchParams(window.location.search).forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  editProfile(id: number) {
    this.router.navigateByUrl(`/${this.routePath}/${id}`, {
      state: { fromProfilePage: true },
    });
  }

  deleteProfile(id: number) {
    let msg = '';
    let dialogTitle = this.errorDialogTitle;
    let optionActions = this.defaultDialogOptionActions;
    this.callConfirmDialog()
      .afterClosed()
      .pipe(
        switchMap((dialogData: AdditionalDialogData) => {
          if (dialogData && dialogData.confirmed) {
            return this.profileService.delete(id);
          } else {
            throwError('could-not-delete');
          }
        })
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.ok) {
            msg = 'Profile has been deleted';
            dialogTitle = this.successDialogTitle;
            optionActions = [
              {
                actionAfterClose: true,
                cssClass: 'mat-stroked-button mat-primary',
                description: 'Close',
                id: 'delete-dialog',
              },
            ];
          } else {
            msg = `Unexpected error: ${response.status} ${response.statusText}`;
            this.callDialog(msg, dialogTitle, optionActions);
          }
        },
        error => {
          msg = DialogComponent.parseErrorResponse(error);
          this.callDialog(msg, dialogTitle, optionActions);
        },
        () => {
          this.callDialog(msg, dialogTitle, optionActions)
            .afterClosed()
            .subscribe(() => {
              this.dataSource.reset();
              this.dataSource.loadData(this.dataSource.generateArgs());
            });
        }
      );
  }

  callConfirmDialog() {
    const message = 'Are you sure you want to delete profile permanently?';
    const title: DialogTitle = {
      iconName: 'delete',
      text: 'Delete issue',
    };
    const optionalActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionalActions);
  }

  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  openColumnPicker() {
    this.columnDialog
      .callDialog(this.columnConfig)
      .afterClosed()
      .subscribe((dialogData: AdditionalDialogData) => {
        if (dialogData) {
          const { confirmed, columnOptions } = dialogData;
          if (confirmed) {
            this.applyNewColumnSettings(columnOptions);
          }
        }
      });
  }

  applyNewColumnSettings(columnOptions: ColumnOutput[]) {
    Object.keys(columnOptions).forEach(key => {
      const value = !columnOptions[key];
      const column = this.columnConfig.find(col => col.key === key);

      if (!value) {
        column.filter.value = '';
      }

      column.hide = value;
    });
    this.columnConfig = [...this.columnConfig];
    this.saveColumnConfig();
  }

  saveColumnConfig() {
    const config = this.columnConfig
      .filter(col => !col.hide)
      .map(col => col.key);
    localStorage.setItem(this.localStorageColumnKey, JSON.stringify(config));
  }

  permissionToModify(element: Profile) {
    if (element.shared === true && !this.userHasPermissionsToShareProfile) {
      return true;
    }
  }
}
