import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'env/environment';
import { RestService } from 'sso-angular';
import { SelectOptionsConfig } from 'app/components/profile/select-option-config';
import { SelectOption } from 'app/interfaces';
import { HttpResponse } from '@angular/common/http';

export class Dictionary {
  id: string;
  values: Select2Dict[];
}

export interface Select2Dict {
  id: number;
  text: string;
}

@Injectable()
export class MetadataService {
  private limit = 1000;
  metadataEndpoint: string = environment.apiUrls.news_ws;

  constructor(private rest: RestService) {}

  /**
   * Generates rest endpoint
   *
   * @private
   * @param {string} path endpoint name
   * @param {string} [query] additional filters
   * @returns {string}
   * @memberof MetadataService
   */
  private getUrl(path: string, query?: string): string {
    let url = this.metadataEndpoint + '/' + path;
    url += `?limit=${this.limit}`;
    if (query) {
      url += '&' + query;
    }

    return url;
  }

  /**
   * Fetch data from RestService
   *
   * @param {string} metadata metdata name
   * @returns {Observable}
   * @memberof MetadataService
   */
  getData(metadata: string): Observable<HttpResponse<any>> {
    const path = this.mapNameToPath(metadata);
    return this.rest.get(this.getUrl(path));
  }

  mapNameToPath(name: string): string {
    return SelectOptionsConfig.find(
      (option: SelectOption) => option.safeName === name
    ).serviceEndpoint;
  }
}
