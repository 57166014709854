import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';

@Injectable()
export class UsersService {
  usersEndpoint: string = environment.apiUrls.oauth + '/users';

  constructor(private rest: RestService) {}

  list() {
    return this.rest.get(this.usersEndpoint, {
      limit: 10000,
      ordering: 'username',
    });
  }
}
