<mat-form-field
  hideRequiredMarker
  [ngClass]="{
    'theme-decernis__form-field': true,
    'theme-decernis__form-field--as-filter': basicStyles
  }"
  appearance="outline"
>
  <mat-label *ngIf="!basicStyles">{{ placeholder }}</mat-label>
  <ng-container>
    <input
      matInput
      [matDatepicker]="picker"
      [min]="min"
      [placeholder]="basicStyles ? placeholder : null"
      [value]="value"
      [disabled]="disabled"
      (dateChange)="onDateChange($event)"
    />
  </ng-container>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
