import { Injectable } from '@angular/core';

/**
 * General SOLR (not application specific) utilities
 */
@Injectable()
export class SolrUtilsService {
  private escapePattern = /([;!*+|()\[\]{}\^~?:"])/g;

  constructor() {}

  escape(text: string) {
    if (text !== null) {
      return text.replace(this.escapePattern, encodeURIComponent('\\$1'));
    } else {
      return '';
    }
  }

  /**
   * Concatenate queries using supplied operator.
   *
   * @param queries queries to be concatenated
   * @param metadata string (domain, text, title etc.)
   * @param operator 'OR' or 'AND'
   * @param exclude string "NOT " operator uses to exclude metadata value
   */
  concatQueries(
    queries: string[],
    metadata: string,
    operator: string,
    exclude?: string
  ) {
    const nonEmptyQueries = queries.filter(query => Boolean(query));

    if (nonEmptyQueries.length === 0) {
      return null;
    }
    let metadataValues = nonEmptyQueries
      .map(value => `${metadata}: "${value}"`)
      .join(` ${operator.toUpperCase()} `);
    if (nonEmptyQueries.length > 1) {
      metadataValues = `(${metadataValues})`;
    }
    return `${exclude}${metadataValues}`;
  }

  /**
   * Field term query
   * For use when querying field by multiple values. like this:
   * fieldName:(term1 term2)
   *
   * @param terms
   * @param use_AND should AND operator be used
   * @param group query using brackets
   * @return string with SOLR query
   */
  fieldTermQuery(
    terms: string[],
    use_AND: boolean = true,
    group?: boolean
  ): string {
    const escapedKeyTerms = terms.map(
      keyTerm =>
        (use_AND && terms.length !== 1 ? '+' : '') + `"${this.escape(keyTerm)}"`
    );
    if (use_AND && group) {
      return terms.map(value => `${value}`).join(' AND ');
    } else if (use_AND) {
      return escapedKeyTerms.join(' AND');
    } else {
      return escapedKeyTerms.join(' ');
    }
  }
}
