import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { environment } from 'env/environment';

@Injectable({
  providedIn: 'root',
})
export class KeyTermsDataService {
  constructor(private rest: RestService) {}

  public getSearchItems(data: object) {
    const url = environment.apiUrls.news_ws + '/lexicon/synonyms';
    return this.rest.get(url, data);
  }
}
