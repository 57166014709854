import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';
import { Observable } from 'rxjs';

@Injectable()
export class ProfilesV2Service {
  profileEndpoint = environment.apiUrls.news_ws + '/analytics/profiles';
  constructor(private rest: RestService) {}

  create(data) {
    return this.rest.post(this.profileEndpoint, data);
  }

  getProfiles(args?: any): Observable<any> {
    return this.rest.get(this.profileEndpoint, { ...args });
  }

  delete(id) {
    return this.rest.delete(this.profileEndpoint + '/' + id);
  }

  detail(id) {
    return this.rest.get(this.profileEndpoint + '/' + id);
  }

  updateProfile(id, data) {
    return this.rest.put(this.profileEndpoint + '/' + id, data);
  }
}
