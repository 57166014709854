import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, hightlight: string): any {
    return text.replace(hightlight, `<span class="bold">${hightlight}</span>`);
  }
}
