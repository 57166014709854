import { Injectable } from '@angular/core';
import {
  of,
  Observable,
  throwError,
  Subject,
  BehaviorSubject,
  AsyncSubject,
} from 'rxjs';
import {
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  Profile,
} from 'app/interfaces';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FileHandlerService } from '../file-handler/file-handler.service';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { switchMap, map } from 'rxjs/operators';
import * as _ from 'lodash';

interface ProfileData {
  profile: Profile;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileImportService {
  private importFileSource = new BehaviorSubject<any>({});
  fileImported$ = this.importFileSource.asObservable();

  constructor(
    private dialog: MatDialog,
    private fileHandler: FileHandlerService
  ) {}

  import(): Observable<any> {
    return this.callImportDialog()
      .afterClosed()
      .pipe(
        switchMap((dialogData?: AdditionalDialogData) => {
          if (dialogData && dialogData.confirmed) {
            return this.fileHandler.handleFile(dialogData.file);
          } else {
            return throwError('not-confirmed');
          }
        }),
        switchMap(profileData => {
          const data: ProfileData = JSON.parse(profileData);
          const isValid = this.validateProfile(data);
          if (isValid) {
            return of(data);
          } else {
            return throwError('not-valid-profile');
          }
        }),
        map((profileData: ProfileData) => {
          const modifiedProfile = {
            ...profileData,
            ...this.overwriteProfile(profileData.profile),
          };
          this.importFileSource.next(modifiedProfile);
        })
      );
  }

  validateProfile(profileData) {
    const validProfileKeys = [
      'full_title',
      'key_terms',
      'advanced_view_selected',
      'key_terms_string',
      'structure',
      'filter_query_string',
      'query_string',
    ];
    const isValidKeys = (profile: Profile) =>
      validProfileKeys.every(key => Object.keys(profile).includes(key));

    return isValidKeys(profileData.profile);
  }

  overwriteProfile(profile: Profile): Profile {
    profile.private = false;
    return profile;
  }

  callImportDialog() {
    const message = 'To import profile select JSON file.';
    const title: DialogTitle = {
      iconName: 'upload',
      text: 'Import profile',
    };
    const optionActions: DialogOptionActions[] = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Cancel',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Import',
      },
    ];
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      inputOptions: {
        type: 'file',
        placeholder: 'Browse from your computer',
        iconName: 'upload',
        validFileTypes: ['json'],
      },
      optionActions,
    };

    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }
}
