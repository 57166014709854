import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProfileUtilsService {
  messageEvent: Subject<any> = new Subject();
  selectProfileEvent: Subject<any> = new Subject();
  updateProfileEvent: Subject<any> = new Subject();
  reloadProfileEvent: Subject<any> = new Subject();

  callMessageAdd(value) {
    this.messageEvent.next(value);
  }

  callSelectProfile(value) {
    this.selectProfileEvent.next(value);
  }

  callUpdateInfo(value) {
    this.updateProfileEvent.next(value);
  }

  callReloadProfiles() {
    this.reloadProfileEvent.next(true);
  }
}
