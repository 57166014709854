import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { environment } from 'env/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ClientConfig } from 'app/interfaces';
import { map, delay, retryWhen, take, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientConfigService {
  constructor(private rest: RestService) {}

  cache: Observable<ClientConfig>;
  configSubject = new ReplaySubject<ClientConfig>(1);

  getConfig(): Observable<ClientConfig> {
    if (this.cache) {
      return this.cache;
    }

    return (this.cache = this.fetchConfig());
  }

  fetchConfig(): Observable<ClientConfig> {
    return this.rest.get(environment.apiUrls.config).pipe(
      delay(1000),
      shareReplay(1),
      retryWhen(errors => errors.pipe(delay(1000), take(10))),
      map((response: HttpResponse<ClientConfig>) => response.body)
    );
  }
}
