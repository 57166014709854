import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsletterDataSource } from 'app/components/newsletter/newsletter.ds';
import { IServerSideDataSourceFactory } from 'app/data-source/base-server-side-data-source';
import { Newsletter } from 'app/interfaces';

@Injectable()
export class NewsletterDataSourceFactory
  implements IServerSideDataSourceFactory<Newsletter> {
  public getDataSource(
    rest,
    location: Location,
    router: Router,
    activatedRoute: ActivatedRoute
  ): NewsletterDataSource {
    return new NewsletterDataSource(rest, location, router, activatedRoute);
  }
}
