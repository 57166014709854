import { Component } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  styles: [],
  template: `
    <div class="container">
      <div class="row">
        <div class="error-template">
          <h1>Oops!</h1>
          <h2>404 Not Found</h2>
          <div class="error-details">
            Sorry, an error has occured, Requested page not found!<br />
          </div>
        </div>
      </div>
    </div>
  `,
})
export class PageNotFoundComponent {
  constructor() {}
}
