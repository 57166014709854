import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export class NavbarItems {
  route: string;
  title: string;
  isStatic = false;
  openInNewTab = false;
  hoverMsg = '';

  constructor(
    route: string,
    title: string,
    isStatic = false,
    openInNewTab = false,
    hoverMsg = ''
  ) {
    this.route = route;
    this.title = title;
    this.isStatic = isStatic;
    this.openInNewTab = openInNewTab;
    this.hoverMsg = hoverMsg;
  }

  get target() {
    return this.openInNewTab ? '_blank' : '_self';
  }
}

@Component({
  selector: 'app-navbar',
  styles: [],
  template: `
    <ul class="nav navbar-nav">
      <ng-container *ngFor="let item of items">
        <li title="{{ item.hoverMsg || item.title }}">
          <a
            *ngIf="item.isStatic"
            target="{{ item.target }}"
            href="{{ item.route }}"
            >{{ item.title }}</a
          >
          <a
            *ngIf="!item.isStatic"
            routerLink="{{ item.route }}"
            routerLinkActive="active"
            >{{ item.title }}</a
          >
        </li>
      </ng-container>
    </ul>
  `,
})
export class NavBarComponent {
  @Input()
  items: NavbarItems[] = [];

  router: Router;
}
