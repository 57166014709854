<app-loading [show]="showLoader"></app-loading>

<div class="profile-form mat-typography wrapper">
  <div class="form__header">
    <h2>
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--big"
        svgIcon="{{ iconName }}"
      ></mat-icon>
      {{ pageTitle }}
    </h2>
  </div>

  <div class="form__section">
    <form [formGroup]="issueForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-4 col-xs-12">
          <mat-form-field
            class="theme-decernis__form-field"
            appearance="outline"
            hideRequiredMarker
          >
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" />
            <mat-error
              align="end"
              *ngIf="issueForm.controls.title.errors?.required"
              >Title is required</mat-error
            >
            <mat-error
              align="end"
              *ngIf="issueForm.controls.title.errors?.maxlength"
              >Title is too long</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-xs-12">
          <mat-form-field
            class="theme-decernis__form-field"
            appearance="outline"
          >
            <mat-label>Issue type</mat-label>
            <mat-select
              class="theme-decerins__select"
              panelClass="theme-decernis__selectPanel"
              disableOptionCentering
              formControlName="issue_type"
            >
              <mat-option
                *ngFor="let option of issueType$ | async"
                [value]="option.id"
              >
                {{ option.issue_type }}
              </mat-option>
            </mat-select>

            <mat-error
              align="end"
              *ngIf="issueForm.controls.issue_type.errors?.required"
              >Issue type is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-xs-12">
          <div class="form__section form__section--withTitle">
            <h3 class="form__title form__title--small">Publish date:</h3>

            <ng-container *ngIf="!isReadOnly; else datepicker">
              <mat-radio-group
                aria-label="Select a publish date"
                (change)="onPublishDateSelect($event)"
              >
                <mat-radio-button
                  *ngFor="let radio of radioOptions"
                  class="theme-decernis__radio"
                  [value]="radio.value"
                  [checked]="radio.selected"
                  color="primary"
                  >{{ radio.name }}</mat-radio-button
                >
              </mat-radio-group>
              <div *ngIf="showDatePicker" class="form__datePicker">
                <app-datepicker
                  [value]="issueForm.controls.publish.value"
                  [min]="datepickerMin"
                  (onChange)="onDatepickerChange($event)"
                ></app-datepicker>
              </div>
            </ng-container>
            <ng-template #datepicker>
              <app-datepicker
                [value]="issueForm.controls.publish.value"
                [min]="datepickerMin"
                (onChange)="onDatepickerChange($event)"
              ></app-datepicker>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 col-xs-12">
          <div
            class="form__section form__section--withTitle form__section--big"
          >
            <h3 class="form__title">Content</h3>
            <editor
              [apiKey]="tinyMCEConfig.apiKey"
              [init]="tinyMCEConfig.config"
              formControlName="content"
            ></editor>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 col-xs-12">
          <div
            class="form__section form__section--withTitle form__section--big"
          >
            <h3 class="form__title">
              <span>Attachments</span>
              <mat-spinner
                *ngIf="issueForm.controls.attachments.errors?.filesProcessing"
                class="issue__loader"
                diameter="20"
              ></mat-spinner>
            </h3>

            <app-file-uploader
              [initialFiles]="issueForm.controls.attachments.value"
              (onFilesChange)="onFilesChange($event)"
              (onProcessingStart)="onFileProcesingStart()"
              (onProcessingSuccess)="onFileProcessingSuccess()"
              (onInvalidFiles)="attachmentError($event)"
            ></app-file-uploader>
          </div>
        </div>
      </div>

      <div class="form__section form__section--big">
        <div class="row">
          <div class="col-md-8 col-xs-12">
            <app-filters
              *ngIf="filtersConfig"
              [selectOptions]="filtersConfig.selectOptions"
              [rowsOptions]="filtersConfig.queryRowsOptions"
              [queryRowsList]="filtersConfig.queryRowsOptions.queryRowsList"
              (rowsUpdate)="rowsUpdate($event, i)"
              (searchInputsAreValid)="areTagsValid($event, i)"
            ></app-filters>
          </div>
        </div>
      </div>

      <ng-container *ngIf="canEditSubs">
        <div class="row">
          <div class="col-md-8 col-xs-12">
            <div
              class="form__section form__section--withTitle form__section--big"
            >
              <h3 class="form__title">Subscribers</h3>

              <mat-form-field
                class="theme-decernis__form-field"
                appearance="outline"
              >
                <mat-label>Choose company</mat-label>
                <mat-select
                  #companySelect
                  class="theme-decerins__select"
                  panelClass="theme-decernis__selectPanel"
                  formControlName="companies"
                  multiple
                >
                  <app-select-search
                    [formControl]="filterCompanyCtrl"
                  ></app-select-search>
                  <mat-option
                    *ngFor="let company of filteredCompany | async"
                    [value]="company"
                  >
                    {{ company.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  align="end"
                  *ngIf="issueForm.controls.companies.errors?.required"
                  >You have to select at least one company</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>

      <footer class="form__footer">
        <div class="container">
          <div class="form__footerInner">
            <button
              class="theme-decernis__button"
              mat-button
              mat-stroked-button
              color="primary"
              type="button"
              (click)="onCancelClick()"
            >
              <mat-icon
                class="theme-decernis__icon"
                svgIcon="cancel"
              ></mat-icon>
              Cancel
            </button>

            <ng-container *ngIf="!isReadOnly">
              <button
                class="theme-decernis__button"
                mat-button
                mat-stroked-button
                color="primary"
                type="button"
                [disabled]="isInvalid()"
                (click)="onSubmit()"
              >
                <mat-icon
                  class="theme-decernis__icon"
                  svgIcon="save"
                ></mat-icon>
                Save
              </button>
              <button
                class="theme-decernis__button"
                mat-button
                mat-flat-button
                color="primary"
                type="button"
                [disabled]="isInvalid()"
                (click)="onSubmit(true)"
              >
                <mat-icon
                  class="theme-decernis__icon"
                  svgIcon="upload"
                ></mat-icon>
                Publish
              </button>
            </ng-container>
          </div>
        </div>
      </footer>
    </form>
  </div>
</div>
