import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NumberOnlyDirective } from 'app/directives/number.directive';
import { CommonElementsModule } from 'app/components/common/common-elements.module';
import { SsoAngularModule } from 'sso-angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AppComponent } from './app.component';
import { appRoutes } from './routes';
import { DateRemoveUTCPipe } from './pipes/date-remove-utc.pipe';
import { DocumentsService } from './services/analytics/documents.service';
import { DocumentsExportService } from './services/analytics/document-export.service';
import { KeyTermsSelectComponent } from './components/key-terms-select/key-terms-select.component';
import { LexiconService } from './services/analytics/lexicon.service';
import { AnalyticsTableComponent } from './components/analytics-table/analytics-table.component';
import { QueryService } from './services/analytics/query.service';
import { SolrUtilsService } from './services/solr-utils/solr-utils.service';
import { ScriptComponent } from './components/script-loader/script-loader.component';
import { GoogleTranslateWidgetComponent } from './components/ui/google-translate-widget/google-translate-widget.component';
import { ZendeskWidgetComponent } from './components/zendesk-widget/zendesk-widget.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfilesV2Service } from './services/analytics/profile.service';
import { UsersService } from './services/analytics/users.service';
import { GroupsService } from './services/analytics/groups.service';
import { ProfileUtilsService } from './services/profile-utils.service';
import { MetadataService } from './services/analytics/metadata.service';
import { NewsletterDataSourceFactory } from 'app/components/newsletter/newsletter.ds.factory';
import { TranslatePeriodicityPipe } from 'app/pipes/periodicicity.pipe';
import { LanguagesService } from 'app/services/newsletter/languages.service';
import {
  NewsletterSendNowService,
  AnalyticsNewsletterService,
} from 'app/services/newsletter/analytics-newsletter.service';
import { IssueNewsletterService } from 'app/services/newsletter/issue-newsletter.service';
import { PeriodicityService } from 'app/services/newsletter/periodicity.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

// Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { SearchQueryFiltersComponent } from 'app/components/ui/search-query-filters/search-query-filters.component';
import { QueryUtilsService } from './services/query-utils.service';
import { PiwikScriptComponent } from './components/piwik/piwik.component';
import { environment } from 'env/environment';
import { ChipsSelectComponent } from './components/ui/chips-select/chips-select.component';
import { DialogComponent } from './components/ui/dialog/dialog.component';
import { KeyTermDatatableComponent } from './components/key-term-datatable/key-term-datatable.component';
import { IconNamePipe } from './pipes/icon-name.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { KeyTermDropdownComponent } from './components/ui/key-term-dropdown/key-term-dropdown.component';
import { SearchPreviewComponent } from './components/ui/search-preview/search-preview.component';
import { FileUploadComponent } from './components/ui/file-upload/file-upload.component';
import { ChipComponent } from './components/ui/chip/chip.component';
import { IssueManagementComponent } from 'app/components/issue-management/issue-management.component';
import { IssueSingleComponent } from './components/issue-single/issue-single.component';
import { FiltersComponent } from './components/ui/filters/filters.component';
import { FileUploaderComponent } from './components/ui/file-uploader/file-uploader.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ColumnPickerComponent } from './components/ui/column-picker/column-picker.component';
import { IssueNewsletterFormComponent } from './components/newsletter/issue-newsletter-form/issue-newsletter-form.component';
import { AnalyticsNewsletterFormComponent } from './components/newsletter/analytics-newsletter-form/analytics-newsletter-form.component';
import { AnalyticsNewsletterTableComponent } from './components/newsletter/analytics-newsletter-table/analytics-newsletter-table.component';
import { IssueNewsletterTableComponent } from './components/newsletter/issue-newsletter-table/issue-newsletter-table.component';
import { TagListComponent } from './components/ui/tag-list/tag-list.component';
import { SelectSearchComponent } from './components/ui/select-search/select-search.component';
import { IssueHomeComponent } from './components/issue-home/issue-home.component';
import { IssueDisplayComponent } from './components/issue-display/issue-display.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AnalyticsSearchFiltersComponent } from './components/analytics-search-filters/analytics-search-filters.component';
import { WindowToken, windowProvider } from './services/utils/window';
import { ProfilesTableComponent } from './components/profiles-table/profiles-table.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { ProfilePickerComponent } from './components/ui/profile-picker/profile-picker.component';
import { TimepickerComponent } from './components/ui/timepicker/timepicker.component';
import { TimezonePickerComponent } from './components/ui/timezone-picker/timezone-picker.component';
import { TimezonePipe } from './services/timezone/timezone.pipe';
import { ResetRouteService } from './services/router/reset-route.service';
import { DataTableModule } from './components/ui/data-table/data-table.module';
import { TrackingGoogleAnalyticsComponent } from './components/tracking-google-analytics/tracking-google-analytics.component';
import { OptOutService } from './services/issues/issue-subscriptions.service';
import { SubscriptionsTableComponent } from './components/subscriptions-table/subscriptions-table.component';
import { EventSubscriptions } from './services/events/subscriptions.service';
import { SubscriptionsDataSourceFactory } from './components/subscriptions-table/subscriptions.ds.factory';

@NgModule({
  declarations: [
    AppComponent,
    DateRemoveUTCPipe,
    TimezonePipe,
    KeyTermsSelectComponent,
    AnalyticsTableComponent,
    ScriptComponent,
    GoogleTranslateWidgetComponent,
    ProfileComponent,
    PiwikScriptComponent,
    SearchQueryFiltersComponent,
    ChipsSelectComponent,
    DialogComponent,
    KeyTermDatatableComponent,
    IconNamePipe,
    HighlightPipe,
    KeyTermDropdownComponent,
    TranslatePeriodicityPipe,
    AnalyticsNewsletterFormComponent,
    SearchPreviewComponent,
    NumberOnlyDirective,
    FileUploadComponent,
    ChipComponent,
    IssueManagementComponent,
    IssueSingleComponent,
    FiltersComponent,
    FileUploaderComponent,
    ColumnPickerComponent,
    IssueNewsletterFormComponent,
    AnalyticsNewsletterTableComponent,
    IssueNewsletterTableComponent,
    TagListComponent,
    TrackingGoogleAnalyticsComponent,
    SelectSearchComponent,
    IssueHomeComponent,
    IssueDisplayComponent,
    AnalyticsComponent,
    AnalyticsSearchFiltersComponent,
    ProfilesTableComponent,
    ProfilePickerComponent,
    TimepickerComponent,
    TimezonePickerComponent,
    ZendeskWidgetComponent,
    SubscriptionsTableComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
    SsoAngularModule.configure(environment),
    CommonElementsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatExpansionModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatChipsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSortModule,
    MatTabsModule,
    MomentDateModule,
    EditorModule,
    DropzoneModule,
    MatSelectInfiniteScrollModule,
    NgxMaterialTimepickerModule,
    DataTableModule,
  ],
  providers: [
    DocumentsService,
    DocumentsExportService,
    LexiconService,
    QueryService,
    SolrUtilsService,
    ProfilesV2Service,
    UsersService,
    GroupsService,
    ProfileUtilsService,
    MetadataService,
    QueryUtilsService,
    NewsletterDataSourceFactory,
    LanguagesService,
    NewsletterSendNowService,
    IssueNewsletterService,
    AnalyticsNewsletterService,
    PeriodicityService,
    ResetRouteService,
    OptOutService,
    EventSubscriptions,
    SubscriptionsDataSourceFactory,
    { provide: WindowToken, useFactory: windowProvider },
  ],
  entryComponents: [DialogComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
