import { SearchQueryFilterData } from 'app/interfaces';
import { SelectOptionsConfig } from './select-option-config';

const SearchQueryFiltersInitialConfig: SearchQueryFilterData[] = [
  {
    selectOptions: SelectOptionsConfig.filter(
      options => options.safeName === 'key_terms'
    ),
    operatorOptions: {
      selectedValue: 'include',
      options: [
        {
          value: 'include',
          iconName: 'adjust',
        },
        {
          value: 'exclude',
          iconName: 'block',
        },
      ],
    },
    logicalOperators: {
      selectedValue: 'any',
      options: [
        {
          value: 'any',
          name: 'Any',
        },
        {
          value: 'all',
          name: 'All',
        },
      ],
    },
    queryRowsOptions: {
      title: 'Key terms',
      tooltip: `Key Term may be CAS number or substance name. The Key Terms automatically expand the query with synonyms.
      When used in conjunction with query string ‘AND’ operator is used between user supplied query and key terms synonyms.`,
      queryRowsList: [
        {
          selectedOption: 'key_terms',
          operator: 'include',
          logicalOperator: 'any',
          chips: [],
          _variableSelectDisabled: false,
          _logicalOperatorDisabled: false,
          _valid: true,
        },
      ],
      defaultQueryRowConfig: {
        selectedOption: 'key_terms',
        operator: 'include',
        logicalOperator: 'any',
        chips: [],
        _variableSelectDisabled: false,
        _logicalOperatorDisabled: false,
        _valid: true,
      },
    },
  },
  {
    selectOptions: SelectOptionsConfig.filter(
      option => option.safeName !== 'key_terms'
    ),
    logicalOperators: {
      selectedValue: 'any',
      options: [
        {
          value: 'any',
          name: 'Any',
        },
        {
          value: 'all',
          name: 'All',
        },
      ],
    },
    operatorOptions: {
      selectedValue: '',
      options: [
        {
          value: 'include',
          iconName: 'adjust',
        },
        {
          value: 'exclude',
          iconName: 'block',
        },
      ],
    },
    queryRowsOptions: {
      title: 'Filters',
      queryRowsList: [
        {
          selectedOption: '',
          operator: 'include',
          logicalOperator: 'any',
          chips: [],
          _variableSelectDisabled: true,
          _logicalOperatorDisabled: false,
          _valid: true,
        },
      ],
    },
  },
];

export { SearchQueryFiltersInitialConfig };
