import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { FiltersConfig } from 'app/components/issue-single/filters-config';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { environment } from 'env/environment';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import {
  DateOutput,
  IssueType,
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  Issue,
  SearchQueryFilterData,
  Company,
  Tag,
} from 'app/interfaces';
import { IssueTypeServiceService } from 'app/services/issues/issue-type-service.service';
import {
  Observable,
  forkJoin,
  of,
  Subscription,
  ReplaySubject,
  race,
} from 'rxjs';
import { shareReplay, map, tap, take } from 'rxjs/operators';
import { IssuesCrudService } from 'app/services/issues/issues-crud.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { CompanyService } from 'app/services/company/company.service';
import { UserPermissionsService } from 'app/services/user/user-permissions.service';
import { DialogRedirectService } from 'app/services/dialog/dialog-redirect.service';

@Component({
  selector: 'app-issue-single',
  templateUrl: './issue-single.component.html',
  styleUrls: ['./issue-single.component.scss'],
})
export class IssueSingleComponent implements OnInit, OnDestroy {
  issueForm: FormGroup;
  issueType$: Observable<IssueType[]>;
  issueID: number;

  company$: Observable<Company[]>;
  company: Company[];
  filteredCompany: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  filterCompanyCtrl: FormControl = new FormControl();
  initalCompanies: Company[];
  isContentAdmin$: Observable<boolean>;
  contentAdminSub: Subscription;
  isReadonly$: Subscription;
  isReadOnly = false;
  isEditPage: boolean;
  canEditSubs: boolean;

  pageTitle: string;
  iconName: string;
  showLoader = false;
  filesProcessing = 0;

  tinyMCEConfig = {
    apiKey: environment.tinyMCEAPIKey,
    config: {
      plugins: 'image link lists preview table',
      toolbar: `formatselect | bold italic underline | bullist numlist alignleft aligncenter alignright alignjustify
      | image link table | preview`,
      menubar: false,
      content_css: `${environment.basePath}/assets/vendor/tinyMCEStyles.css`,
    },
  };

  filtersConfig: SearchQueryFilterData;
  areFiltersValid = true;
  radioOptions = [
    { name: 'Now', value: 'now', selected: true },
    { name: 'Schedule at', value: 'schedule', selected: false },
  ];

  showDatePicker = false;
  datepickerMin = new Date();

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };

  defaultDialogOptionActions: DialogOptionActions[] = [
    {
      cssClass: 'mat-stroked-button mat-primary',
      description: 'Close',
    },
  ];

  @ViewChild('companySelect') companySelect: MatSelect;

  constructor(
    private fb: FormBuilder,
    private issueTypeService: IssueTypeServiceService,
    private issueCrudService: IssuesCrudService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private companyService: CompanyService,
    private userPermissions: UserPermissionsService,
    private dialogRedirect: DialogRedirectService
  ) {}

  ngOnInit() {
    this.issueType$ = this.issueTypeService.get().pipe(shareReplay());
    this.company$ = this.companyService.get().pipe(shareReplay());

    this.issueForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(1000)]],
      issue_type: ['', [Validators.required]],
      content: [''],
      tags: [[]],
      publish: [null],
      attachments: [[], this.noFilesAreProcessed()],
      shared: [false],
    });

    this.issueID = parseInt(this.route.snapshot.params.id, 10);
    this.isContentAdmin$ = this.userPermissions.isContentAdmin().pipe(
      tap(isContentAdmin => {
        this.issueForm.controls.shared.setValue(isContentAdmin);
      }),
      shareReplay()
    );

    if (!isNaN(this.issueID)) {
      this.isEditPage = true;
      this.setupEditPage(this.issueID);
    } else {
      this.pageTitle = 'New issue';
      this.iconName = 'add_circle';
      this.filtersConfig = _.cloneDeep(FiltersConfig);
      this.contentAdminSub = this.isContentAdmin$.subscribe(isContentAdmin => {
        this.canEditSubs = isContentAdmin;

        if (isContentAdmin) {
          this.issueForm.addControl(
            'companies',
            this.fb.control([], Validators.required)
          );
          this.setCompanies(true);
        }
      });
    }
  }

  issueGetService(issueID): Observable<Issue> {
    return this.issueCrudService
      .get(issueID)
      .pipe(map((response: HttpResponse<Issue>) => response.body));
  }

  setupEditPage(issueID: number) {
    this.showLoader = true;
    this.issueGetService(issueID).subscribe(
      (issue: Issue) => {
        const data = issue;

        this.contentAdminSub = this.isContentAdmin$.subscribe(
          isContentAdmin => {
            if (isContentAdmin && data.shared) {
              this.issueForm.addControl(
                'companies',
                this.fb.control([], Validators.required)
              );
              this.setCompanies();
            }
            this.issueForm.patchValue(data);
          }
        );

        this.isReadonly$ = forkJoin([
          this.isContentAdmin$,
          of(data.shared),
        ]).subscribe(([isContentAdmin, isSharedIssue]) => {
          this.isReadOnly = !isContentAdmin && isSharedIssue;
          this.canEditSubs = isContentAdmin && isSharedIssue;

          if (this.isReadOnly) {
            this.router.navigate([`issues/display/${issueID}`]);
          }
        });

        if (data.tags === null) {
          data.tags = [];
        }

        if (data.tags.length && data.tags[0].selectedOption) {
          const queryRowsList = data.tags.map(queryRow => {
            return {
              ...queryRow,
              _variableSelectDisabled: false,
              _useService: this.isRowUsingService(queryRow.selectedOption),
            };
          });

          this.filtersConfig = {
            ..._.cloneDeep(FiltersConfig),
            queryRowsOptions: {
              title: 'Tags',
              queryRowsList,
            },
          };
        } else {
          this.filtersConfig = _.cloneDeep(FiltersConfig);
        }

        this.pageTitle = `Edit ${data.title}`;
        this.iconName = 'add_circle';

        const isScheduled = data.status.toLowerCase() === 'scheduled';
        this.showDatePicker = isScheduled;
        this.radioOptions.forEach(option => {
          if (isScheduled) {
            option.selected = !option.selected;
          }
        });
        this.showLoader = false;
      },
      error => {
        if (error.status === 404) {
          this.dialogRedirect.callDialog('/issues');
        }

        this.showLoader = false;
      }
    );
  }

  setCompanies(isAddPage?: boolean) {
    this.company$.subscribe(company => {
      this.company = company;
      this.filteredCompany.next(company);

      if (isAddPage) {
        this.setCurrentCompany();
      }
    });

    this.filterCompanyCtrl.valueChanges.subscribe(() => {
      this.filterCompany();
    });

    this.filteredCompany.pipe(take(1)).subscribe(() => {
      this.companySelect.compareWith = (a: Company, b: Company) =>
        a.id === b.id;
    });
  }

  setCurrentCompany() {
    const currentCompany = localStorage.getItem('current_company');
    const companyOpt = this.company.find(
      company => company.name === currentCompany
    );
    if (companyOpt) {
      this.issueForm.controls.companies.setValue([companyOpt]);
    }
  }

  filterCompany() {
    if (!this.company) {
      return;
    }

    let search = this.filterCompanyCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.company.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCompany.next(
      this.company.filter(
        profile => profile.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  isRowUsingService(rowOptionName: string) {
    return FiltersConfig.selectOptions.find(
      option => option.safeName === rowOptionName
    ).useService;
  }

  onSubmit(publish?: boolean) {
    const value = { ...this.issueForm.value };
    const filtered = this.filterQueryField(value);
    const data = this.applyDateValue(filtered, publish);
    const isEditPage = this.isEditPage;

    this.callConfirmDialog(!this.isEditPage, publish)
      .afterClosed()
      .subscribe((dialogData: AdditionalDialogData) => {
        if (dialogData && dialogData.confirmed) {
          let msg, dialogTitle;

          if (!isEditPage) {
            this.issueCrudService.create(data).subscribe(
              (response: HttpResponse<Issue>) => {
                msg = `Issue ${response.body.title} created`;
                dialogTitle = this.successDialogTitle;
              },
              errors => {
                msg = DialogComponent.parseErrorResponse(errors);
                dialogTitle = this.errorDialogTitle;
                this.callDialog(
                  msg,
                  dialogTitle,
                  this.defaultDialogOptionActions
                );
              },
              () => {
                this.callDialog(
                  msg,
                  dialogTitle,
                  this.defaultDialogOptionActions
                );
                this.router.navigate([`/issues`]);
              }
            );
          } else {
            this.issueCrudService.update(this.issueID, data).subscribe(
              (response: HttpResponse<Issue>) => {
                msg = `Issue ${response.body.title} edited`;
                dialogTitle = this.successDialogTitle;
              },
              errors => {
                msg = DialogComponent.parseErrorResponse(errors);
                dialogTitle = this.errorDialogTitle;
                this.callDialog(
                  msg,
                  dialogTitle,
                  this.defaultDialogOptionActions
                );
              },
              () => {
                this.callDialog(
                  msg,
                  dialogTitle,
                  this.defaultDialogOptionActions
                );
                this.router.navigate([`/issues`]);
              }
            );
          }
        }
      });
  }

  applyDateValue(formData: Issue, publishNow: boolean) {
    if (publishNow && !this.showDatePicker) {
      formData.publish = new Date();
    } else if (!publishNow) {
      formData.publish = null;
    }

    return formData;
  }

  filterQueryField(formData: Issue): Issue {
    formData.tags = formData.tags.filter(
      (tag: Tag) => tag.selectedOption && tag.chips.length
    );
    return formData;
  }

  rowsUpdate(tags) {
    this.issueForm.patchValue({ tags });
  }

  onPublishDateSelect(event: MatRadioChange) {
    this.showDatePicker = event.value === 'schedule';
  }

  onDatepickerChange(date: DateOutput) {
    if (date.date) {
      this.issueForm.patchValue({ publish: date.date });
    }
  }

  noFilesAreProcessed() {
    return (control: AbstractControl) => {
      return this.filesProcessing !== 0 ? { filesProcessing: true } : null;
    };
  }

  onFilesChange(files: File[]) {
    this.issueForm.patchValue({ attachments: files });
  }

  onFileProcesingStart() {
    this.filesProcessing++;
    this.issueForm.controls.attachments.updateValueAndValidity();
  }

  attachmentError(event) {
    if (!!event.length) {
      this.issueForm.controls.attachments.setErrors({ error: true });
    } else {
      this.issueForm.controls.attachments.setErrors({ error: null });
      this.issueForm.controls.attachments.updateValueAndValidity();
    }
  }

  onFileProcessingSuccess() {
    this.filesProcessing--;
    this.issueForm.controls.attachments.updateValueAndValidity();
  }

  isInvalid(): boolean {
    return this.issueForm.invalid || !this.areTagsValid();
  }

  areTagsValid() {
    return this.filtersConfig.queryRowsOptions.queryRowsList.every(
      row => row._valid
    );
  }

  mapIssueType(issueID: number) {
    return this.issueType$.pipe(
      map(issues => {
        const found = issues.find(issue => issue.id === issueID);
        if (found) {
          return found.issue_type;
        }
      })
    );
  }

  compareCompanies(first: Company, second: Company) {
    return first.id === second.id;
  }

  callConfirmDialog(createNew: boolean, publish: boolean) {
    const message = publish
      ? 'Are you sure you want to publish the issue?'
      : 'Are you sure you want to save the issue?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    if (createNew) {
      const text = publish ? 'Publish issue' : 'Save issue';
      title = {
        iconName: 'add',
        text,
      };
    } else {
      title = {
        iconName: 'edit',
        text: 'Update issue',
      };
    }
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionActions);
  }

  onCancelClick() {
    this.isDirty() ? this.callCancelDialog() : this.location.back();
  }

  isDirty(): boolean {
    return this.issueForm.dirty;
  }

  callCancelDialog(): void {
    const message = 'Are you sure you want to discard changes in the issue?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    title = {
      iconName: 'cancel',
      text: 'Cancel',
    };
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Return to issue',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Yes, discard changes',
      },
    ];
    const ref = this.callDialog(message, title, optionActions);
    ref.afterClosed().subscribe(({ confirmed }: AdditionalDialogData) => {
      if (confirmed) {
        this.router.navigate(['/issues']);
      }
    });
  }

  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  ngOnDestroy() {
    if (this.contentAdminSub) {
      this.contentAdminSub.unsubscribe();
    }
  }
}
