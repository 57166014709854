import { filter, take } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, CurrentCompanyService } from 'sso-angular';
import { CompanyTitleService } from 'app/services/company-title.service';
import { Subscription, combineLatest } from 'rxjs';
import { ClientConfigService } from 'app/services/client-config.service';
import { ClientConfig } from './interfaces';
import { IconService } from './services/icon.service';
import { environment } from 'env/environment';
import { NavbarItems } from './components/common/navbar/navbar.component';
declare let gtag: Function;

interface MenuItem {
  route: string;
  title: string;
  isStatic?: boolean;
  openInNewTab?: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  embedded: boolean;
  title = 'Praedixi';
  companyTitle: string;
  currentCompanySubscription: Subscription;

  menuItems: MenuItem[] = [
    {
      title: 'Analytics',
      route: 'analytics',
    },
    {
      title: 'Profiles',
      route: 'profiles',
    },
    {
      title: 'Newsletter',
      route: 'newsletter',
    },
  ];
  isLogged: boolean;
  piwikSiteId = environment.piwikSiteIdAnalytics;
  piwik = environment.piwik;
  trackedByGoogle = environment.trackedByGoogle;
  googleTagManagerId = environment.googleGlobalSiteTag;
  company: string = null;
  username: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentCompanyService: CurrentCompanyService,
    private companyTitleService: CompanyTitleService,
    private clientConfig: ClientConfigService,
    private iconService: IconService,
    private auth: AuthService
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        gtag('event', 'navigation', { page_path: y.url });
      }
    });
  }
  ngOnInit() {
    this.iconService.load();
    this.getUserAndCompany();

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .forEach(e => {
        this.embedded = this.route.root.firstChild.snapshot.data['embedded'];
        this.isLogged = this.auth.loggedIn();
      });

    this.currentCompanySubscription = this.currentCompanyService.currentCompany$.subscribe(
      company => {
        if (!company) {
          return;
        }

        this.companyTitleService.companyTitle = company;
        this.companyTitle = this.companyTitleService.companyTitle;

        this.clientConfig.getConfig().subscribe((config: ClientConfig) => {
          const {
            application_v5_title,
            issue_management_view,
            resource_url,
          } = config;

          if (application_v5_title) {
            this.companyTitle = application_v5_title;
          }

          const displayIssueManagement =
            issue_management_view.toLowerCase() === 'true';
          if (displayIssueManagement) {
            this.menuItems.push(new NavbarItems('issues', 'Issue Management'));
          }

          if (resource_url) {
            this.menuItems.push(
              new NavbarItems(resource_url, 'Resource', true, true)
            );
          }
        });
      }
    );
  }

  getUserAndCompany() {
    combineLatest([
      this.auth.currentUsername$,
      this.currentCompanyService.currentCompany$,
    ])
      .pipe(take(5))
      .subscribe(([username, company]) => {
        if (username && company) {
          this.username = username;
          this.company = company;
        }
      });
  }

  ngOnDestroy(): void {
    this.currentCompanySubscription.unsubscribe();
  }
}
