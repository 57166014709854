import { Injectable, Inject } from '@angular/core';
import { QueryService } from '../analytics/query.service';
import { SolrUtilsService } from '../solr-utils/solr-utils.service';
import { SearchParams, URLDocumentSearchParams } from 'app/interfaces';
import { Observable, of, throwError } from 'rxjs';
import { RegionService } from '../analytics/region.service';
import { ProfilesV2Service } from 'app/services/analytics/profile.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { DialogRedirectService } from '../dialog/dialog-redirect.service';
import { WindowToken } from '../utils/window';

export interface Param {
  name: string;
  target: string;
  values?: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentUrlParamsService {
  aviableUrlParams: Param[] = [
    {
      name: 'keyTerms',
      target: 'q',
    },
    {
      name: 'analyticsFilters',
      target: 'q',
    },
    {
      name: 'keyTermsFromProfile',
      target: 'q',
    },
    {
      name: 'profile',
      target: 'profile_id',
    },
    {
      name: 'region',
      target: 'field_filter',
    },
    {
      name: 'date',
      target: 'fq',
    },
    {
      name: 'title',
      target: 'field_filter',
    },
    {
      name: 'country',
      target: 'field_filter',
    },
    {
      name: 'agency',
      target: 'field_filter',
    },
    {
      name: 'category',
      target: 'field_filter',
    },
    {
      name: 'ruletype',
      target: 'field_filter',
    },
    {
      name: 'group_name',
      target: 'field_filter',
    },
    {
      name: 'subgroup_name',
      target: 'field_filter',
    },
    {
      name: 'type_name',
      target: 'field_filter',
    },
    {
      name: 'ordering',
      target: 'ordering',
    },
    {
      name: 'dateFrom',
      target: 'fq',
    },
    {
      name: 'dateTo',
      target: 'fq',
    },
    {
      name: 'offset',
      target: 'offset',
    },
  ]; // Update List
  constructor(
    private queryService: QueryService,
    private solrUtils: SolrUtilsService,
    private regionService: RegionService,
    private profileService: ProfilesV2Service,
    private dialogRedirect: DialogRedirectService,
    @Inject(WindowToken) private window: Window
  ) {}

  formatQueryParams(queryParams: SearchParams) {
    const params = {};
    Object.entries(queryParams).forEach(([name, value]) => {
      switch (name) {
        case 'field_filter':
          params[name] = this.formatFieldFilter(value);
          break;

        case 'offset':
          params[name] = value[0].offset;
          break;

        case 'ordering':
          params[name] = value[0].ordering;
          break;

        case 'fq':
          const dateFrom = value.find(
            dateObj => Object.keys(dateObj)[0] === 'dateFrom'
          )
            ? value.find(dateObj => Object.keys(dateObj)[0] === 'dateFrom')
                .dateFrom
            : '';
          const dateTo = value.find(
            dateObj => Object.keys(dateObj)[0] === 'dateTo'
          )
            ? value.find(dateObj => Object.keys(dateObj)[0] === 'dateTo').dateTo
            : '';

          params[name] = this.queryService.dateFilter(dateFrom, dateTo);
          break;

        case 'q':
          params[name] = this.formatQueryFilter(value);
          break;

        case 'profile_id':
          params[name] = value[0].profile;
          break;

        default:
          break;
      }
    });
    return params;
  }

  getQueryParams(): Observable<{ source: Param[]; queryParams: Object }> {
    const urlParams: Param[] = this.getUrlParams();
    const isRegionInParams = urlParams.find(param => param.name === 'region');
    const isProfileInParams = urlParams.find(param => param.name === 'profile');
    const params = {
      source: urlParams,
      queryParams: null,
    };

    if (!params.source.length) {
      return throwError('no-source-for-params');
    }

    return of(params).pipe(
      switchMap(paramObj => {
        if (isRegionInParams) {
          return this.regionService.getMap().pipe(
            map(regionMap => {
              const regionParam = paramObj.source.find(
                param => param.name === 'region'
              );
              regionParam.values = this.regionService.translateRegionName(
                isRegionInParams.values as string,
                regionMap
              );
              return paramObj;
            })
          );
        } else {
          return of(paramObj);
        }
      }),
      switchMap(paramObj => {
        if (isProfileInParams) {
          return this.profileService.detail(isProfileInParams.values).pipe(
            map((profile: HttpResponse<any>) => {
              const keyTerms = profile.body.key_terms;
              if (keyTerms.length) {
                paramObj.source.push({
                  name: 'keyTermsFromProfile',
                  target: 'q',
                  values: keyTerms,
                });
              }

              return paramObj;
            }),
            catchError(error => {
              if (error.status === 404) {
                this.dialogRedirect.callDialog('/analytics');
              }
              return throwError(error);
            })
          );
        } else {
          return of(paramObj);
        }
      }),
      map(paramObj => {
        paramObj.queryParams = this.formatQueryParams(
          this.buildQueryParams(paramObj.source)
        );
        return paramObj;
      })
    );
  }

  buildQueryParams(urlParams) {
    return urlParams.reduce((prev, { name, target, values }) => {
      const queryParamsValues = this.getOutputValues(target, name, values);
      if (!prev[target]) {
        prev[target] = [queryParamsValues];
      } else {
        prev[target].push(queryParamsValues);
      }
      return prev;
    }, {});
  }

  getOutputValues(targetKey, name, values) {
    let output;
    switch (targetKey) {
      case 'offset':
        output = { [name]: +values };
        break;
      default:
        output = { [name]: values };
        break;
    }

    return output;
  }

  getUrlParams(): Param[] {
    const urlParams = new URLSearchParams(this.window.location.search);
    const params = [];

    this.aviableUrlParams.forEach(({ name, target }) => {
      let values: string | string[];
      if (name !== 'keyTerms') {
        values = urlParams.get(name);
      } else {
        values = urlParams.getAll(name);
      }

      if (urlParams.has(name)) {
        params.push({ name, target, values });
      }
    });

    return params;
  }

  formatFieldFilter(fieldFilter: URLDocumentSearchParams[]) {
    const filter = {};
    fieldFilter.map(val => {
      const key = Object.keys(val)[0];
      const filterValue = val[key];
      filter[key] = filterValue;
    });
    return JSON.stringify(filter);
  }

  formatQueryFilter(queryFilter): string {
    let query = '';

    const isAnalyticsFiltersPresent = queryFilter.find(
      filter => Object.keys(filter)[0] === 'analyticsFilters'
    );

    const keyTerms = queryFilter.find(
      filter => Object.keys(filter)[0] === 'keyTerms'
    );

    const analyticsFiltersQuery = isAnalyticsFiltersPresent
      ? isAnalyticsFiltersPresent.analyticsFilters
      : '';

    if (analyticsFiltersQuery) {
      query = `(${analyticsFiltersQuery})`;
    }

    if (keyTerms) {
      const keyTermQuery = `key_terms:(${this.solrUtils.fieldTermQuery(
        keyTerms.keyTerms
      )})`;

      if (analyticsFiltersQuery) {
        query = `(${analyticsFiltersQuery}) AND (${keyTermQuery})`;
      } else {
        query = keyTermQuery;
      }
    }

    return query;
  }
}
