import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AnalyticsSearchFiltersComponent } from '../analytics-search-filters/analytics-search-filters.component';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AnalyticsTableComponent } from '../analytics-table/analytics-table.component';
import { SolrUtilsService } from 'app/services/solr-utils/solr-utils.service';
import {
  Chip,
  QueryRow,
  SearchQueryFilterData,
  DialogTitle,
} from 'app/interfaces';
import { FiltersConfig } from 'app/components/analytics-search-filters/filters-config';
import { SelectOptionsConfig } from '../profile/select-option-config';
import { ChipCreatorService } from 'app/services/chip-creator.service';
import { DialogComponent } from '../ui/dialog/dialog.component';
import * as moment from 'moment';
import { ResetRouteService } from 'app/services/router/reset-route.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [ResetRouteService],
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
  @ViewChild('searchFilter', { static: true })
  searchFilter: AnalyticsSearchFiltersComponent;
  @ViewChild('tableFilter', { static: true })
  tableFilter: AnalyticsTableComponent;

  routePath = 'analytics';
  searchForm: FormGroup;
  initialKeyTerms: string[] = [];
  filtersConfig: SearchQueryFilterData;
  dateFormat = 'YYYY-MM-DD';
  DEFAULT_OPERATOR = 'AND';
  operatorMap = {
    OR: 'any',
    AND: 'all',
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private solrUtils: SolrUtilsService,
    private chipCreator: ChipCreatorService,
    private resetRoute: ResetRouteService
  ) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      dateFrom: [''],
      dateTo: [''],
      profile: [null],
    });

    this.filtersConfig = _.cloneDeep(FiltersConfig);
    this.setVariablesFromUrl();

    if (this.searchForm.get('profile').value) {
      this.searchFilter.selectedColumnID = [1];
    }
  }

  ngAfterViewInit() {
    this.resetRoute.configure('/analytics', this.onReInit.bind(this));

    if (location.search) {
      this.tableFilter.load();
    }
  }

  onReInit() {
    if (this.searchForm) {
      this.searchForm.reset();
    }

    if (this.dialog.openDialogs.length) {
      if (
        this.dialog.openDialogs[0].componentInstance.optionActions &&
        this.dialog.openDialogs[0].componentInstance.optionActions[0].id !==
          'delete-dialog'
      ) {
        this.dialog.closeAll();
      }
    }

    this.filtersConfig = _.cloneDeep(FiltersConfig);
    this.filtersConfig.queryRowsOptions.queryRowsList.map(queryRow => {
      const selectedOption = queryRow.selectedOption;
      if (selectedOption) {
        const isUsingService = () => this.isRowUsingService(selectedOption);
        queryRow._variableSelectDisabled = false;
        queryRow._useService = isUsingService();
        queryRow._logicalOperatorDisabled = isUsingService();
        queryRow.logicalOperator = this.operatorMap[this.DEFAULT_OPERATOR];
      } else {
        queryRow._useService = true;
        queryRow._variableSelectDisabled = true;
        queryRow._logicalOperatorDisabled = true;
        queryRow.logicalOperator = this.operatorMap[this.DEFAULT_OPERATOR];
      }
    });

    this.searchFilter.reset();
    this.tableFilter.reset();
  }

  setVariablesFromUrl(): void {
    const urlParams = new URLSearchParams(location.search);
    const formParams = {};

    this.tableFilter.initColumnConfig();
    this.tableFilter.readColumnsVisibility();

    if (
      urlParams.get('profile') &&
      (urlParams.get('keyTerms') || urlParams.get('analyticsFilters'))
    ) {
      const dialogResponseConfig = new MatDialogConfig();
      const title: DialogTitle = {
        iconName: 'block',
        text: 'Invalid params',
      };

      dialogResponseConfig.width = '620px';
      dialogResponseConfig.data = {
        description: 'Invalid URL params.',
        title,
      };
      this.dialog
        .open(DialogComponent, dialogResponseConfig)
        .afterClosed()
        .subscribe(() => {
          this.onReInit();
        });
      return;
    }

    urlParams.forEach((value, key) => {
      if (key === 'dateFrom' || key === 'dateTo') {
        this.searchForm.controls[key].setValue(new Date(value));
      } else if (key === 'keyTerms') {
        this.initialKeyTerms = urlParams.getAll('keyTerms');
      } else if (key === 'profile') {
        this.searchForm.controls[key].setValue(+value);
      } else if (key === 'offset') {
        this.tableFilter.paginator.pageIndex = +value / 50;
      } else if (key === 'analyticsFilters') {
        this.filtersConfig = this.mapUrlToAnalyticsFilters(
          urlParams.get('analyticsFilters')
        );
      } else {
        formParams[key] = value;
      }
    });

    this.tableFilter.setTableFilters(formParams);
    this.searchForm.patchValue(formParams);
  }

  onSubmit() {
    let args = {};

    if (this.searchFilter.selectedColumnID.includes(0)) {
      const analyticsFilters = this.formatAnalyticsFilters(
        this.searchFilter.filters
      );

      args['keyTerms'] = this.searchFilter.keyTerms;
      args['analyticsFilters'] = analyticsFilters;
    } else if (this.searchFilter.selectedColumnID.includes(1)) {
      args['profile'] = this.searchForm.get('profile').value.toString();
    } else {
      console.error('There is no search args');
    }

    args = {
      ...args,
      dateFrom: this.searchForm.get('dateFrom').value,
      dateTo: this.searchForm.get('dateTo').value,
      ...this.tableFilter.getFilterParams(),
      ...this.tableFilter.getSortingParam(),
    };

    const formatted = _.omitBy(this.formatDate(args), _.isEmpty);
    this.sendToAPI(formatted);
  }

  sendToAPI(filters) {
    this.router
      .navigate([`/${this.routePath}`], {
        queryParams: filters,
      })
      .then(() => {
        this.tableFilter.expandedElements = [];
        this.tableFilter.expandAll = false;
        this.tableFilter.load();
      });
  }

  formatAnalyticsFilters(filters: QueryRow[]): string {
    let results = '';

    const operatorMap = {
      any: 'OR',
      all: 'AND',
    };

    const concatenated = filters.map(filter => {
      const values = filter.chips.map((chip: Chip) => chip.value);
      const metadata = filter.selectedOption;
      const operator = '';
      const logicalOperator = operatorMap[filter.logicalOperator];

      if (!metadata) {
        return '';
      }

      return this.solrUtils.concatQueries(
        values,
        metadata,
        logicalOperator,
        operator
      );
    });

    if (concatenated.length) {
      results = this.solrUtils.fieldTermQuery(concatenated, true, true);
    }

    return results;
  }

  mapUrlToAnalyticsFilters(filter: string): SearchQueryFilterData {
    const tags = [];
    const filterName = SelectOptionsConfig.map(option => option.safeName);
    filterName.forEach(name => {
      const filterRegex = new RegExp(`(${name}):\\s?"(.*?)"\\s?(OR|AND)?`, 'g');
      Array.from(filter.matchAll(filterRegex)).map(fullMatch => {
        const selectedOption = fullMatch[1];
        const value = fullMatch[2];
        const operator = fullMatch[3] || this.DEFAULT_OPERATOR;
        const isOptionInArray = tags.find(
          tag => tag.selectedOption === selectedOption
        );

        if (!isOptionInArray) {
          tags.push({
            selectedOption,
            logicalOperator: this.operatorMap[operator],
            chips: [this.chipCreator.create(value)],
          });
        } else {
          isOptionInArray.chips.push(this.chipCreator.create(value));
        }
      });
    });

    const config = _.cloneDeep(FiltersConfig);
    config.queryRowsOptions.queryRowsList = tags;
    config.queryRowsOptions.queryRowsList.map(queryRow => {
      queryRow._variableSelectDisabled = false;
      queryRow._useService = this.isRowUsingService(queryRow.selectedOption);
    });

    return config;
  }

  isRowUsingService(rowOptionName: string): boolean {
    return FiltersConfig.selectOptions.find(
      option => option.safeName === rowOptionName
    ).useService;
  }

  formatDate(filters): object {
    for (const [key, value] of Object.entries(filters)) {
      const filterValue = value as Date;
      if (filterValue && (key === 'dateFrom' || key === 'dateTo')) {
        filters[key] = moment(value).format(this.dateFormat);
      }
    }

    return filters;
  }

  isDirty(): boolean {
    if (this.searchFilter.selectedColumnID.includes(0)) {
      return !!(
        this.searchFilter.keyTerms.length ||
        this.searchFilter.filters.filter(
          tag => tag.selectedOption && tag.chips.length
        ).length
      );
    } else if (this.searchFilter.selectedColumnID.includes(1)) {
      return !!this.searchForm.get('profile').value;
    } else {
      return false;
    }
  }
}
