import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})

/* This is a wrapper for ngx-material-timepicker (docs: https://github.com/Agranom/ngx-material-timepicker) */
export class TimepickerComponent implements OnInit, AfterViewInit {
  @Output() opened: EventEmitter<any> = new EventEmitter();
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() timeChanged: EventEmitter<string> = new EventEmitter();
  @Input() hint: string;
  @ViewChild('picker', { static: true }) picker;

  value: string;
  format: 12 | 24 = 24;
  LOCAL_STORAGE_KEY = 'timepickerFormat';

  @Input() set initialTime(time: string) {
    this.value = time;
  }

  ngOnInit() {
    const storageFormatOption = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (
      (storageFormatOption && +storageFormatOption === 24) ||
      +storageFormatOption === 12
    ) {
      const selectedFormat = +storageFormatOption as 12 | 24;
      this.format = selectedFormat;
    }
  }

  ngAfterViewInit() {
    if (this.format === 24) {
      this.setLocale('en-GB');
    } else {
      this.setLocale('en-US');
    }
  }

  changeClock() {
    let selectedFormat: 12 | 24;

    /* We shuffle locales because of a bug, when if you select MIDNIGHT:mm on default locale it returns 24:mm instead of 00:mm */
    let selectedLocale: 'en-GB' | 'en-US';

    if (this.format === 12) {
      selectedFormat = 24;
      selectedLocale = 'en-GB';
    } else {
      selectedFormat = 12;
      selectedLocale = 'en-US';
    }

    localStorage.setItem(this.LOCAL_STORAGE_KEY, selectedFormat.toString());
    this.setLocale(selectedLocale);
    this.format = selectedFormat;
  }

  setLocale(locale: 'en-GB' | 'en-US') {
    /* Check if this is needed in feature release of a package (last checked version: 5.5.1) */
    this.picker.timepickerInput.locale = locale;
  }

  onOpen() {
    this.opened.emit();
  }

  onClose() {
    this.closed.emit();
  }

  onTimeChanged(time: string) {
    /* This is related to forcing locale bug, remove this if statement when resolved */
    if (+time.substr(0, 2) === 24) {
      time = '00' + time.slice(2);
    }

    this.timeChanged.emit(time);
  }
}
