<mat-tab-group
  [selectedIndex]="selectedTab"
  (selectedIndexChange)="changeTab($event)"
>
  <mat-tab label="View">
    <app-issue-management></app-issue-management>
  </mat-tab>
  <mat-tab label="Subscribe">
    <app-issue-newsletter-table></app-issue-newsletter-table>
  </mat-tab>
</mat-tab-group>
