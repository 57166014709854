import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';

@Injectable({
  providedIn: 'root',
})
export class IssuesCrudService {
  issueEndpoint = environment.apiUrls.news_ws + '/issue/issue';
  constructor(private rest: RestService) {}

  create(data) {
    return this.rest.post(this.issueEndpoint, data);
  }

  getAll(filters?) {
    return this.rest.get(this.issueEndpoint, { limit: 50, ...filters });
  }

  get(id) {
    return this.rest.get(this.issueEndpoint + '/' + id);
  }

  delete(id) {
    return this.rest.delete(this.issueEndpoint + '/' + id);
  }

  update(id, data) {
    return this.rest.put(this.issueEndpoint + '/' + id, data);
  }
}
