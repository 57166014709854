import { formatDate } from '@angular/common';
import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import {
  MatDialogConfig,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { debounceTime, tap, switchMap, map } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  KeyTerm,
  Chip,
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
} from 'app/interfaces';
import { KeyTermsDataService } from 'app/services/key-terms-data.service';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { FileHandlerService } from 'app/services/file-handler/file-handler.service';
import { ChipCreatorService } from 'app/services/chip-creator.service';
import { KeyTermValidationService } from 'app/services/key-term-validation.service';
import { SnackBarService } from 'app/services/snackbar.service';
import { AbstractControl, FormControl } from '@angular/forms';

export enum ControlStatus {
  invalid = 'INVALID',
  valid = 'VALID',
}

@Component({
  selector: 'app-key-term-dropdown',
  templateUrl: './key-term-dropdown.component.html',
  styleUrls: ['./key-term-dropdown.component.scss'],
})
export class KeyTermDropdownComponent implements OnDestroy, AfterViewInit {
  @Output() valid = new EventEmitter<boolean>();
  @Output() selectChange: EventEmitter<Array<Chip>> = new EventEmitter();
  @Input() clearOnSelect: boolean;
  @Input() blurOnSelect: boolean;
  @Input() multiple = false;
  @Input() withExtraActions: boolean;
  @Input() set initialChips(chips: Array<Chip>) {
    this.chipsList = chips;
  }

  @ViewChild('chipList') chipListElement;
  @ViewChild('keyTermInput') set content(content: ElementRef) {
    this.keyTermInput = content;
    if (!this.searchObservable) {
      this.searchObservable = this.createSearchObservable().subscribe(
        (response: HttpResponse<any>) => {
          this.keyTermArray = response.body.results;
        }
      );
    }
  }

  separator = '\n';
  keyTermInput: ElementRef;
  isLoading: boolean;
  searchObservable: Subscription;
  selectedKeyTerms: Array<KeyTerm>;
  keyTermArray: Array<KeyTerm>;
  chipsList: Array<Chip> = [];
  removable = true;
  addOnBlur = false;
  params = {
    offset: 0,
    search: '',
    limit: 10,
  };
  searchInputControl: FormControl = new FormControl('', {
    updateOn: 'blur',
    validators: this.emptyValidator(),
  });
  searchInputErrorKey = 'notEmpty';

  readonly displayedAutocompleteColumns = ['key_term', 'synonym'];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dataService: KeyTermsDataService,
    private fileHandler: FileHandlerService,
    private dialog: MatDialog,
    private chipCreator: ChipCreatorService,
    private keyTermValidator: KeyTermValidationService,
    private snackBar: SnackBarService
  ) {}

  ngAfterViewInit() {
    this.bindSearchInputControl();
  }

  bindSearchInputControl() {
    // We have to manually set errorState to chipListElement in order to display it in <mat-error>
    this.searchInputControl.statusChanges.subscribe((status: ControlStatus) => {
      this.chipListElement.errorState = this.searchInputControl.hasError;
      this.valid.emit(status === ControlStatus.valid);
    });
  }

  /**
   * Clear key term input
   */
  reset(): void {
    if (this.keyTermInput) {
      this.keyTermInput.nativeElement.value = '';
      this.searchInputControl.setValue('');
    }
  }

  optionSelected(term: string): void {
    if (this.clearOnSelect) {
      this.keyTermInput.nativeElement.value = '';
    }

    if (!this.multiple) {
      this.selectChange.emit([this.chipCreator.create(term)]);
    } else {
      this.add(term);
      this.selectChange.emit(this.chipsList);
    }

    if (this.blurOnSelect) {
      this.keyTermInput.nativeElement.blur();
    }
  }

  createSearchObservable(): Observable<object> {
    return fromEvent(this.keyTermInput.nativeElement, 'input').pipe(
      debounceTime(300),
      tap(() => (this.isLoading = true)),
      switchMap((event: any) => {
        this.params.search = event.target.value;
        return this.dataService.getSearchItems(this.params).pipe(
          tap(() => {
            this.isLoading = false;
          })
        );
      })
    );
  }

  add(chipName: string): void {
    if (chipName) {
      this.chipsList = _.uniqBy(
        [...this.chipsList, this.chipCreator.create(chipName)],
        'name'
      );
    }
  }

  remove(chip: Chip): void {
    const index = this.chipsList.indexOf(chip);

    if (index >= 0) {
      this.chipsList.splice(index, 1);
    }
    this.selectChange.emit(this.chipsList);
  }

  inputPlaceholder(): string {
    return !this.chipsList.length ? 'Add Key Term' : '';
  }

  importKeyTerms(): void {
    this.callImportDialog()
      .afterClosed()
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap((data: AdditionalDialogData) => {
          if (data) {
            const { confirmed, file } = data;
            if (confirmed) {
              return this.fileHandler.handleFile(file);
            }
          }
        }),
        map(keyTermString => this.formatKeyTermResponse(keyTermString)),
        switchMap(formatted =>
          this.keyTermValidator.validateKeyTerms(formatted)
        ),
        map(({ correct, incorrect }) => {
          if (!correct.length) {
            this.snackBar.open(`All key terms were invalid or file was empty`);
          } else if (incorrect.length) {
            this.snackBar.open(`Invalid key terms: ${incorrect.join(', ')}`);
          }

          return correct;
        })
      )
      .subscribe(
        (chips: string[]) => {
          chips.forEach(chip => {
            this.add(chip);
          });
          this.isLoading = false;
          this.selectChange.emit(this.chipsList);
        },
        error => {
          this.isLoading = false;
          console.error(error);
        }
      );
  }

  callImportDialog(): MatDialogRef<any> {
    const message = `To import key terms select CSV file.
    Each key term should be in a separate line to import them successfully.`;
    const title: DialogTitle = {
      iconName: 'upload',
      text: 'Import',
    };
    const optionActions: Array<DialogOptionActions> = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Cancel',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Import',
      },
    ];
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      inputOptions: {
        type: 'file',
        placeholder: 'Browse from your computer',
        iconName: 'upload',
        validFileTypes: ['csv'],
      },
      optionActions,
    };

    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  downloadKeyTerms(): void {
    const dataToDownload = [].concat(this.chipsList.map(chip => [chip.name]));
    const randomString = formatDate(new Date(), 'yyyy/MM/dd, h:mm', 'en');
    const fileName = `keyterms_decernis_profile_${randomString}.csv`;
    this.exportToCsv(fileName, dataToDownload);
  }

  exportToCsv(filename, rows) {
    const processRow = function(row) {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }

        if (j > 0) {
          finalVal += ',';
        }

        finalVal += result;
      }
      return finalVal + '\n';
    };

    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  formatKeyTermResponse(keyTermString: string): string[] {
    return keyTermString
      .split(this.separator)
      .filter(chip => chip)
      .map(chip => chip.trim());
  }

  emptyValidator() {
    return (control: AbstractControl) => {
      const chips = this.chipsList.map(chip => chip.value);
      return control.value && !chips.includes(control.value)
        ? { [this.searchInputErrorKey]: true }
        : null;
    };
  }

  ngOnDestroy(): void {
    if (this.searchObservable) {
      this.searchObservable.unsubscribe();
    }
  }
}
