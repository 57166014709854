import { ColumnConfig } from 'app/interfaces';

const ColumnConfigData: ColumnConfig[] = [
  {
    key: 'date',
    name: 'Publication Date',
    hide: false,
    locked: false,
    sortable: true,
    filter: {
      type: 'date',
      value: '',
      name: 'date',
      hide: false,
    },
  },
  {
    key: 'title',
    name: 'Citation',
    sortingKey: 'title_s',
    hide: false,
    locked: false,
    sortable: true,
    bold: true,
    filter: {
      type: 'text',
      value: '',
      name: 'title',
      hide: false,
    },
  },
  {
    key: 'region',
    name: 'Region',
    hide: false,
    locked: false,
    sortable: true,
    sortingKey: 'region_s',
    filter: {
      type: 'select',
      value: '',
      name: 'region',
      hide: false,
      selectable: [],
    },
  },
  {
    key: 'country',
    name: 'Country',
    hide: false,
    locked: false,
    sortable: true,
    sortingKey: 'country_s',
    filter: {
      type: 'select',
      value: '',
      name: 'country',
      hide: false,
      selectable: [],
    },
  },
  {
    key: 'agency',
    name: 'Agency',
    hide: false,
    locked: false,
    sortable: true,
    sortingKey: 'agency_s',
    filter: {
      type: 'select',
      value: '',
      name: 'agency',
      hide: false,
      selectable: [],
    },
  },
  {
    key: 'category',
    name: 'Category',
    hide: false,
    locked: false,
    sortable: true,
    sortingKey: 'category_s',
    filter: {
      type: 'select',
      value: '',
      name: 'category',
      hide: false,
      selectable: [],
    },
  },
  {
    key: 'ruletype',
    name: 'Ruletype',
    hide: false,
    locked: false,
    sortable: false,
    filter: {
      type: 'text',
      value: '',
      name: 'ruletype',
      hide: true,
    },
  },
  {
    key: 'group_name',
    name: 'Group',
    hide: false,
    locked: false,
    sortable: false,
    filter: {
      type: 'text',
      value: '',
      name: 'group_name',
      hide: false,
      selectable: [],
    },
  },
  {
    key: 'subgroup_name',
    name: 'Subgroup',
    hide: false,
    locked: false,
    sortable: false,
    filter: {
      type: 'text',
      value: '',
      name: 'subgroup_name',
      hide: true,
    },
  },
  {
    key: 'type_name',
    name: 'Type',
    hide: false,
    locked: false,
    sortable: false,
    filter: {
      type: 'text',
      value: '',
      name: 'type_name',
      hide: false,
      selectable: [],
    },
  },
];

export { ColumnConfigData };
