<div *ngIf="embedded; else notembedded">
  <router-outlet></router-outlet>
</div>
<ng-template #notembedded>
  <app-dom-base
    [appName]="title"
    [companyTitle]="companyTitle"
    [items]="menuItems"
  >
    <router-outlet></router-outlet>
  </app-dom-base>

  <app-piwik
    *ngIf="piwik && username && company"
    userId="{{ username }}"
    customerId="{{ company }}"
    siteId="{{ piwikSiteId }}"
  ></app-piwik>

  <app-tracking-google-analytics
    *ngIf="trackedByGoogle && googleTagManagerId"
    [googleGlobalSiteTag]="googleTagManagerId"
  ></app-tracking-google-analytics>
  <app-zendesk-widget *ngIf="username && company"> </app-zendesk-widget>
</ng-template>
