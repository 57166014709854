import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  AbstractControl,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

import { LanguagesService } from 'app/services/newsletter/languages.service';
import { AnalyticsNewsletterService } from 'app/services/newsletter/analytics-newsletter.service';
import { PeriodicityService } from 'app/services/newsletter/periodicity.service';
import { ProfilesV2Service } from 'app/services/analytics/profile.service';
import {
  DialogTitle,
  DialogOptionActions,
  AdditionalDialogData,
  Chip,
  Periodicity,
  Language,
  Profile,
  HttpResponseBodyWithPagination,
} from 'app/interfaces';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { EmailDomainWhitelistService } from 'app/services/newsletter/email-domain-whitelist.service';
import { map, shareReplay } from 'rxjs/operators';
import { ChipCreatorService } from 'app/services/chip-creator.service';
import { Observable, ReplaySubject } from 'rxjs';
import { DialogRedirectService } from 'app/services/dialog/dialog-redirect.service';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { Location } from '@angular/common';

@Component({
  selector: 'app-news-newsletter-form',
  templateUrl: './analytics-newsletter-form.component.html',
  styleUrls: ['./analytics-newsletter-form.component.scss'],
})
export class AnalyticsNewsletterFormComponent implements OnInit {
  DEFAULT_TIME = '09:00';
  DEFAULT_TIMEZONE = momentTimezone.tz.guess();

  periodicity$: Observable<Periodicity[]>;
  languages$: Observable<Language[]>;
  editedObjData;

  chips: Chip[] = [];
  sendEmpty = false;
  formdata: FormGroup;
  initialTime: string;

  iconName: string;
  pageTitle: string;

  errorDialogTitle: DialogTitle = {
    iconName: 'cancel',
    text: 'Error',
  };
  successDialogTitle: DialogTitle = {
    iconName: 'info',
    text: 'Success',
  };

  profiles: Profile[];
  profiles$: Observable<Profile[]> = this.getProfiles().pipe(
    map(profiles =>
      profiles.sort((a, b) =>
        a.full_title.toLowerCase() > b.full_title.toLowerCase() ? 1 : -1
      )
    ),
    shareReplay(1)
  );
  filteredProfiles: ReplaySubject<Profile[]> = new ReplaySubject<Profile[]>(1);
  filterProfileCtrl: FormControl = new FormControl();
  initialPorfileIds: number[];

  constructor(
    private fb: FormBuilder,
    private periodicityS: PeriodicityService,
    private profilesS: ProfilesV2Service,
    private newsletterS: AnalyticsNewsletterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private languagesS: LanguagesService,
    private domainWhitelist: EmailDomainWhitelistService,
    private dialog: MatDialog,
    private chipCreator: ChipCreatorService,
    private dialogRedirect: DialogRedirectService,
    private location: Location
  ) {}

  ngOnInit() {
    this.periodicity$ = this.periodicityS.list();
    this.languages$ = this.languagesS.list();
    this.formdata = this.fb.group({
      title: ['', Validators.required],
      description_text: ['', Validators.maxLength(5000)],
      periodicity: [null, Validators.required],
      data: this.fb.group({
        categories: [[], [Validators.required, this.maxLengthArray(10)]],
        cc_emails: [
          [],
          this.emailLength(25),
          this.emailDomainWhitelist.bind(this),
        ],
      }),
      include_summary: [false],
      include_match_terms: [false],
      include_key_terms: [false],
      send_empty: [false],
      language: [''],
      selected_time: [''],
      timezone: [''],
    });
    this.getParams();
  }

  onTimeChanged(time: string): void {
    let UTCDate = '';

    const isTwelveFormat = (timeString: string) => {
      const lastCharacter = +timeString.charAt(timeString.length - 1);
      return isNaN(lastCharacter);
    };

    if (isTwelveFormat(time)) {
      UTCDate = moment.utc(time, 'h:m A').format();
    } else {
      const hour = +time.slice(0, 2);
      const minute = +time.slice(3, 5);
      UTCDate = moment.utc({ hour, minute }).format();
    }

    this.formdata.controls.selected_time.setValue(UTCDate);
    this.formdata.markAsDirty();
  }

  onSaveClick(data) {
    this.callConfirmDialog(!this.editedObjData)
      .afterClosed()
      .subscribe(({ confirmed }: AdditionalDialogData) => {
        if (confirmed) {
          if (!this.editedObjData) {
            this.create(data);
          } else {
            this.update(data);
          }
        }
      });
  }

  /**
   * Update newsletter object and navigate to newsletter list
   * @param preparedData validated form data
   */
  update(preparedData) {
    let msg = '';
    let dialogTitle: DialogTitle;
    let optionActions: DialogOptionActions[];

    this.newsletterS.edit(preparedData, this.editedObjData.id).subscribe(
      (response: HttpResponse<any>) => {
        msg = `Newsletter ${response.body.title} updated`;
        dialogTitle = this.successDialogTitle;
        optionActions = [
          {
            actionAfterClose: true,
            cssClass: 'mat-stroked-button mat-primary',
            description: 'Close',
          },
        ];
      },
      errors => {
        dialogTitle = this.errorDialogTitle;
        msg = DialogComponent.parseErrorResponse(errors);
        this.callDialog(msg, dialogTitle, optionActions);
      },
      () => {
        this.callDialog(msg, dialogTitle, optionActions);
        this.router.navigate(['newsletter']);
      }
    );
  }

  /**
   * Create new newsletter object and navigate to newsletter list
   * @param preparedData validated form data
   */
  create(preparedData) {
    let msg = '';
    let dialogTitle: DialogTitle;
    let optionActions: DialogOptionActions[];

    this.newsletterS.create(preparedData).subscribe(
      (response: HttpResponse<any>) => {
        msg = `Newsletter ${response.body.title} created`;
        dialogTitle = this.successDialogTitle;
        optionActions = [
          {
            actionAfterClose: true,
            cssClass: 'mat-stroked-button mat-primary',
            description: 'Close',
          },
        ];
      },
      errors => {
        dialogTitle = this.errorDialogTitle;
        msg = DialogComponent.parseErrorResponse(errors);
        this.callDialog(msg, dialogTitle, optionActions);
      },
      () => {
        this.callDialog(msg, dialogTitle, optionActions);
        this.router.navigate(['newsletter']);
      }
    );
  }

  /**
   * Get edited object id from url and ask news_ws api about object details
   */
  getParams() {
    const ID = this.activatedRoute.snapshot.params.id;
    if (ID) {
      this.newsletterS.getNewslleter(ID).subscribe(
        response => {
          this.editedObjData = response.body;
          this.iconName = 'edit';
          this.pageTitle = 'Edit ' + this.editedObjData.title;
        },
        error => {
          if (error.status === 404) {
            this.dialogRedirect.callDialog('/newsletter');
          }
        },
        () => {
          this.populateDataFromUrl();
        }
      );
    } else {
      this.iconName = 'add';
      this.pageTitle = 'Add Newsletter';
      this.formdata.controls.timezone.setValue(this.DEFAULT_TIMEZONE);
      this.initialTime = this.DEFAULT_TIME;
      this.formdata.controls.selected_time.setValue(
        moment.utc({ hour: 9 }).format()
      );
    }
  }

  /**
   * Populate data for form inputs
   */
  populateDataFromUrl(): void {
    const { data } = this.editedObjData;

    if (data.cc_emails) {
      this.chips = data.cc_emails.map(email => {
        return this.chipCreator.create(email);
      });
    }
    this.initialPorfileIds = data.categories;
    this.formdata.patchValue(this.editedObjData);

    if (this.editedObjData.selected_time) {
      const dateObj = moment(this.editedObjData.selected_time).utc();
      const hours = dateObj.hours();
      const minutes = dateObj.minutes();
      this.initialTime = `${hours}:${minutes}`;
    }
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl) => {
      if (c.value.length <= max) {
        return null;
      }

      return { maxLengthArray: true, max };
    };
  }

  emailLength(maxLength) {
    return (control: AbstractControl) =>
      control.value.length <= maxLength
        ? null
        : { toManyEmails: true, maxLength };
  }

  emailDomainWhitelist(control: AbstractControl) {
    return this.domainWhitelist
      .checkDomainValidity(control.value)
      .pipe(
        map(([isValid, validDomains]) =>
          isValid ? null : { domainInvalid: true, validDomains }
        )
      );
  }

  getProfiles(): Observable<Profile[]> {
    const args = { limit: 5000 };
    return this.profilesS
      .getProfiles(args)
      .pipe(
        map(
          (response: HttpResponse<HttpResponseBodyWithPagination<Profile[]>>) =>
            response.body.results
        )
      );
  }

  callDialog(
    message: string,
    title?: DialogTitle,
    optionActions?: DialogOptionActions[]
  ): any {
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '620px';
    dialogResponseConfig.data = {
      description: message,
      confirm: true,
      title,
      optionActions,
    };
    return this.dialog.open(DialogComponent, dialogResponseConfig);
  }

  onCancelClick() {
    this.isDirty() ? this.callCancelDialog() : this.location.back();
  }

  isDirty(): boolean {
    return this.formdata.dirty;
  }

  isFormInvalid(): boolean {
    return this.formdata.invalid || !this.isDirty();
  }

  callConfirmDialog(createNew?: boolean) {
    const message = 'Are you sure you want to save the newsletter?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    if (createNew) {
      title = {
        iconName: 'add',
        text: 'Save newsletter',
      };
    } else {
      title = {
        iconName: 'edit',
        text: 'Update newsletter',
      };
    }
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Close',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Confirm',
      },
    ];
    return this.callDialog(message, title, optionActions);
  }

  callCancelDialog(): void {
    const message =
      'Are you sure you want to discard changes in the newsletter?';
    let title: DialogTitle;
    let optionActions: DialogOptionActions[];
    title = {
      iconName: 'cancel',
      text: 'Cancel',
    };
    optionActions = [
      {
        cssClass: 'mat-stroked-button mat-primary',
        description: 'Return to newsletter',
      },
      {
        actionAfterClose: true,
        cssClass: 'mat-raised-button mat-primary',
        description: 'Yes, discard changes',
      },
    ];
    const ref = this.callDialog(message, title, optionActions);
    ref.afterClosed().subscribe(({ confirmed }: AdditionalDialogData) => {
      if (confirmed) {
        this.location.back();
      }
    });
  }
}
