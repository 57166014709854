import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from 'env/environment';
import { RestService } from 'sso-angular';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class LexiconService {
  synonymsEndpoint: string = environment.apiUrls.news_ws + '/lexicon/synonyms';

  synonymsCache = {};

  constructor(private rest: RestService) {}

  /**
   * Fetch synonyms from LEXICON API and add key term (as it is always
   * a synonym for itself).
   *
   * @param keyTerm Key Term
   * @param limit max number of synonyms returned
   * @return promise of synonyms for Key Term
   */
  getSynonyms(keyTerm: string, limit: number = 1000) {
    return this.rest
      .get(
        this.synonymsEndpoint,
        {
          key_term: keyTerm,
          limit: limit,
        },
        {}
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body.results.map(
            synonym_entry => synonym_entry.synonym
          );
        })
      );
  }

  /**
   * Update Key Term synonyms cache.
   * @param keyTerms key terms
   * @param synonymsCache synonyms cache
   * @returns promise of updated synonyms cache
   */
  updateKeyTermsSynonyms(
    keyTerms: string[],
    synonymsCache = null
  ): Promise<Object> {
    const missingKeyTerms = [];
    if (synonymsCache === null) {
      synonymsCache = this.synonymsCache;
    }

    for (const keyTerm of keyTerms) {
      const synonyms = synonymsCache[keyTerm];
      if (synonyms != null) {
        synonymsCache[keyTerm] = synonyms;
      } else {
        missingKeyTerms.push(keyTerm);
      }
    }

    const synonymsPromises = missingKeyTerms.map(keyTerm =>
      this.getSynonyms(keyTerm)
        .toPromise()
        .then(synonyms => {
          synonymsCache[keyTerm] = synonyms;
        })
    );

    return Promise.all(synonymsPromises).then(() => synonymsCache);
  }
}
