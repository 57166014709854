import { ColumnConfig } from '../ui/data-table/data-table.interfaces';

const ProfilesColumnConfig: ColumnConfig[] = [
  {
    key: 'full_title',
    name: 'Title',
    hide: false,
    locked: true,
    sortable: true,
    sortingKey: 'title',
    cellTemplate: true,
    filter: {
      type: 'text',
      value: '',
      name: 'title',
      hide: false,
    },
    style: {
      'min-width': '200px',
    },
  },
  {
    key: 'description',
    name: 'Description',
    hide: false,
    locked: false,
    sortable: true,
    filter: {
      type: 'text',
      value: '',
      name: 'description',
      hide: false,
    },
    style: {
      'min-width': '200px',
    },
  },
  {
    key: 'username',
    name: 'Username',
    hide: false,
    locked: false,
    sortable: true,
    sortingKey: 'user__username',
    filter: {
      type: 'text',
      value: '',
      name: 'user__username',
      hide: false,
    },
    style: {
      'min-width': '150px',
    },
  },
  {
    key: 'private',
    name: 'Profile',
    hide: true,
    locked: false,
    sortable: true,
    cellTemplate: true,
    filter: {
      type: 'select',
      value: '',
      name: 'private',
      selectable: [
        { name: 'Public', value: 'false' },
        { name: 'Private', value: 'true' },
      ],
      hide: false,
    },
    style: {
      'min-width': '180px',
    },
  },
  {
    key: 'shared',
    name: 'Shared',
    hide: true,
    locked: false,
    sortable: true,
    cellTemplate: true,
    filter: {
      type: 'select',
      value: '',
      name: 'shared',
      selectable: [
        { name: 'Shared', value: 'true' },
        { name: 'Default', value: 'false' },
      ],
      hide: false,
    },
    style: {
      'min-width': '180px',
    },
  },
  {
    key: 'advanced_view_selected',
    name: 'Advanced profile',
    hide: true,
    locked: false,
    sortable: false,
    cellTemplate: true,
    filter: {
      type: 'select',
      value: '',
      name: 'advanced_view_selected',
      hide: false,
      selectable: [
        { name: 'Basic', value: 'false' },
        { name: 'Advanced', value: 'true' },
      ],
    },
    style: {
      'min-width': '180px',
    },
  },
  {
    key: 'created',
    name: 'Created',
    hide: true,
    locked: false,
    sortable: true,
    filter: {
      type: 'date',
      value: '',
      name: 'created',
      hide: false,
    },
    style: {
      'min-width': '150px',
    },
  },
  {
    key: 'update_date',
    name: 'Updated',
    hide: false,
    locked: false,
    sortable: true,
    filter: {
      type: 'date',
      value: '',
      name: 'update_date',
      hide: false,
    },
    style: {
      'min-width': '130px',
    },
  },
  {
    name: 'Manage',
    key: 'manage',
    sortable: false,
    locked: true,
    hide: false,
    cellTemplate: true,
    filter: {
      hide: false,
      type: 'submit',
      value: '',
      name: 'manage',
    },
    style: {
      'min-width': '210px',
    },
  },
];

export { ProfilesColumnConfig };
