import {
  SelectValue,
  ColumnConfig,
  DateOutput,
  ColumnFilterValue,
} from 'app/interfaces';
import { isObservable, Subject } from 'rxjs';

import * as _ from 'lodash';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  QueryList,
  OnDestroy,
  AfterViewInit,
  Input,
  Inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';

import { DocumentsExportService } from 'app/services/analytics/document-export.service';
import { MatPaginator } from '@angular/material/paginator';
import { Location } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpResponse } from '@angular/common/http';
import { ColumnFilter, TableData } from 'app/interfaces';
import { DocumentDataSourceService } from 'app/services/document/document-data-source.service';
import { MetadataService } from 'app/services/analytics/metadata.service';
import { ColumnConfigData } from './column-config-data';
import { takeWhile, takeUntil, tap } from 'rxjs/operators';
import { DocumentDataService } from 'app/services/document/document-data.service';
import { DocumentUrlParamsService } from 'app/services/document/document-url-params.service';
import { RegionService } from 'app/services/analytics/region.service';
import { WindowToken } from 'app/services/utils/window';
import { DocumentHighlightService } from 'app/services/document/document-highlight.service';
import { isEmpty } from 'lodash';
import { MatSort } from '@angular/material/sort';
import { LoadingComponent } from '../common/loading/loading.component';

interface ColumnConfigExtended extends ColumnConfig {
  autocomplete: boolean;
  autocompleteLoading: boolean;
  autocompleteValues: SelectValue[];
  autocompleteDisplayedValues: SelectValue[];
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-analytics-table',
  templateUrl: 'analytics-table.component.html',
  styleUrls: ['analytics-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class AnalyticsTableComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() parentForm: FormGroup;
  dataSource: DocumentDataSourceService;
  routePath = 'analytics';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('spinner', { static: true }) spinner: LoadingComponent;
  @ViewChildren('autocompleteInput') inputFilters: QueryList<ElementRef>;

  unsubscribe$: Subject<void> = new Subject();
  initialKeyTerms: string[] = [];
  expandAll: boolean;
  expandedElements: any[] = [];
  params = {};
  offset = 0;
  displayedColumns: string[];
  defaultColumns = ['date', 'title', 'region', 'country', 'agency', 'category'];
  searchLabel = 'Full Text';
  keyTerms = [];
  visibleColumns: ColumnConfigExtended[];
  filterColumns: string[];
  sorting: ColumnFilterValue;

  DEFAULT_SORTING_KEY = '-date';
  DEFAULT_DIRECTION_KEY: 'asc' | 'desc' = 'desc';
  sortActive: string;
  sortDirection = this.DEFAULT_DIRECTION_KEY;

  private storage_key = 'visible_columns';

  constructor(
    private documentsExport: DocumentsExportService,
    private location: Location,
    private metadata: MetadataService,
    private documentService: DocumentDataService,
    private urlParams: DocumentUrlParamsService,
    private regionService: RegionService,
    private highlightService: DocumentHighlightService,
    @Inject(WindowToken) private window: Window
  ) {}

  ngOnInit() {
    this.initDataSource();
    this.initColumnConfig();
    this.readColumnsVisibility();
    this.bindSorting();
  }

  ngAfterViewInit() {
    this.bindInputEvents();
  }

  public load(): void {
    this.dataSource.loadData();
  }

  reset() {
    this.initDataSource();
    this.resetTableFilters();
    this.resetSorting();
    this.expandAll = false;
  }

  resetSorting() {
    this.sorting = { ordering: this.DEFAULT_SORTING_KEY };
    this.sortActive = 'date';
    this.sortDirection = this.DEFAULT_DIRECTION_KEY;
  }

  bindSorting() {
    const sortingKey = 'ordering';
    this.sort.sortChange.subscribe(({ active, direction }) => {
      this.sortActive = active;
      this.sortDirection = direction;

      const sortingKeyInConfig = this.visibleColumns.find(
        config => config.sortingKey && config.key === active
      );
      const activeKey = sortingKeyInConfig
        ? sortingKeyInConfig.sortingKey
        : active;

      let sort = {};
      if (direction) {
        const dir = direction === 'desc' ? '-' : '';
        sort = {
          [sortingKey]: dir + activeKey,
        };
      } else {
        sort = { [sortingKey]: this.DEFAULT_SORTING_KEY };
      }
      this.sorting = sort;

      const params = new URLSearchParams(window.location.search);
      const sortingValue = this.sorting[sortingKey] as string;
      if (params.has('ordering')) {
        params.set('ordering', sortingValue);
      } else {
        params.append('ordering', sortingValue);
      }

      params.delete('offset');
      this.location.replaceState(this.routePath, params.toString());
      this.paginator.pageIndex = 0;
      this.dataSource.loadData();
    });
  }

  initDataSource() {
    this.dataSource = new DocumentDataSourceService(
      this.documentService,
      this.urlParams,
      this.regionService,
      this.highlightService,
      this.window
    );

    this.dataSource.loading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => {
        if (!loading) {
          this.window.scrollTo(0, 0);
        }
      });
  }

  initColumnConfig() {
    this.visibleColumns = _.cloneDeep(ColumnConfigData).map(col => {
      const newCol = col as ColumnConfigExtended;
      newCol.autocompleteValues = [];
      newCol.autocompleteDisplayedValues = [];
      newCol.autocompleteLoading = false;
      newCol.autocomplete = 'selectable' in col.filter;
      return newCol;
    });

    this.visibleColumns.map(col => {
      if (col.filter.selectable && isObservable(col.filter.selectable)) {
        this.getAutocomplete(col.key, col);
      }
    });
  }

  getFilterParams(): Object {
    const viewParams = {};
    this.visibleColumns.forEach((column: ColumnConfig) => {
      const { key } = column;
      const element = <HTMLInputElement>(
        document.querySelector(`[data-filter-field="${key}"]`)
      );
      if (element && element.value) {
        viewParams[key] = element.value;
      } else {
        if (viewParams[key]) {
          delete viewParams[key];
        }
      }
    });

    return _.omitBy(viewParams, value => !value);
  }

  getSortingParam(): ColumnFilterValue {
    return this.sorting;
  }

  resetTableFilters() {
    if (this.visibleColumns) {
      this.visibleColumns.forEach((column: ColumnConfig) => {
        const { key } = column;
        const element = <HTMLInputElement>(
          document.querySelector(`[data-filter-field="${key}"]`)
        );

        if (element) {
          element.value = '';
        }
      });
    }
  }

  setTableFilters(params: object) {
    this.params = params;
  }

  extendUrlByOffset(event?) {
    const offset = event.pageSize * event.pageIndex;

    let path = this.location.path();
    if (path.includes('offset')) {
      path = path.replace(/&?offset=([^&]+)&?/, `&offset=${offset}`);
    } else {
      path += `&offset=${offset}`;
    }

    this.location.replaceState(path);
    this.expandedElements = [];
    this.expandAll = false;
    this.dataSource.loadData();
  }

  onColumnSelectChange() {
    this.saveColumnsVisibility();
  }

  saveColumnsVisibility() {
    localStorage.setItem(
      this.storage_key,
      JSON.stringify(this.displayedColumns)
    );
    this.readColumnsVisibility();
  }

  bindInputEvents() {
    this.inputFilters.changes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters: QueryList<ElementRef>) => {
        filters.forEach(input => {
          const el = input.nativeElement;
          const fieldName = el.dataset.filterField;
          const column = this.visibleColumns.find(col => col.key === fieldName);

          if (!el.dataset.alreadyBound) {
            el.addEventListener('focus', () => {
              if (!column.autocompleteValues.length) {
                this.getAutocomplete(el.dataset.filterField, column);
              }
            });

            el.addEventListener('input', () => {
              column.autocompleteDisplayedValues = column.autocompleteValues.filter(
                autocomplete =>
                  autocomplete.name
                    .toLowerCase()
                    .includes(el.value.toLowerCase())
              );
            });
          }

          el.dataset.alreadyBound = true;
        });
      });
  }

  onDatepickerChange(value: DateOutput, name: string) {
    this.parentForm.controls[name].patchValue(value.date);
  }

  getAutocomplete(name: string, column: ColumnConfigExtended): void {
    column.autocompleteLoading = true;
    this.metadata
      .getData(name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: HttpResponse<any>) => {
          column.autocompleteValues = response.body.results
            .filter(autocomplete => autocomplete)
            .map(autocomplete => {
              if (typeof autocomplete === 'string') {
                return {
                  name: autocomplete,
                  value: autocomplete,
                };
              } else {
                const displayName = autocomplete.name || autocomplete.value;
                const value = autocomplete.value;

                return {
                  name: displayName,
                  value,
                };
              }
            })
            .sort((a: SelectValue, b: SelectValue) =>
              a.name > b.name ? 1 : -1
            );
          column.autocompleteDisplayedValues = column.autocompleteValues;
        },
        errors => {},
        () => (column.autocompleteLoading = false)
      );
  }

  readColumnsVisibility() {
    const savedSelectedColumns = localStorage.getItem(this.storage_key);
    if (savedSelectedColumns) {
      const columns = JSON.parse(savedSelectedColumns);
      this.displayedColumns = columns.filter((colName: string) =>
        this.visibleColumns.map(col => col.key).includes(colName)
      );
    } else {
      this.displayedColumns = this.defaultColumns;
    }

    this.filterColumns = this.displayedColumns.map(
      col => `filter-${this.getColumnFilterConfigByFilterKey(col).name}`
    );
  }

  isObservable(obj: any) {
    return isObservable(obj);
  }

  exportToXls(event, extended = false) {
    const requiredParams = this.dataSource.currParams;

    if (!_.isEmpty(requiredParams)) {
      this.dataSource.loadingSubject.next(true);
      delete requiredParams['limit'];
      delete requiredParams['offset'];
      if (extended) {
        _.extend(requiredParams, {
          keyterms: JSON.stringify(this.keyTerms),
          extended: true,
        });
      }
      this.documentsExport
        .query(requiredParams)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response: HttpResponse<any>) => {
          const contentDispositionHeader: string = response.headers.get(
            'Content-Disposition'
          );
          const contentType = 'application/vnd.ms-excel';
          let filename = 'analytics-report.xls';
          if (contentDispositionHeader) {
            const filenameRegexp = /filename="?([^";]*)/;
            filename = filenameRegexp.exec(contentDispositionHeader)[1];
          }
          const blob = new Blob([response.body], { type: contentType });
          saveAs(blob, filename);
          this.dataSource.loadingSubject.next(false);
        });
    }
  }

  toggleExpandAll() {
    this.expandAll = !this.expandAll;

    if (this.expandAll) {
      this.dataSource.dataSubject
        .pipe(
          takeWhile(() => this.expandAll),
          tap(data => {
            const rowWithoutHighlight = data.filter(row =>
              isEmpty(row.highlights)
            );
            if (rowWithoutHighlight.length) {
              this.dataSource.setHighlight(rowWithoutHighlight).subscribe();
            }
          })
        )
        .subscribe(data => {
          data.forEach((el: any) => {
            this.expandedElements.push(el);
          });
        });
    } else {
      this.expandedElements = [];
    }
  }

  toggleExpandSingle(row: TableData) {
    const index = this.expandedElements.indexOf(row);
    if (index > -1) {
      this.expandedElements.splice(index, 1);
    } else {
      this.expandedElements.push(row);
    }

    this.getMatchTerms(row);
  }

  getMatchTerms(row: TableData) {
    if (
      isEmpty(row.highlights) &&
      row.loadingHighlights$.getValue() === false
    ) {
      this.dataSource.setHighlight([row]).subscribe();
    }
  }

  getColumnConfigByKey(key: string): ColumnConfig {
    return this.visibleColumns.find(col => col.name === key);
  }

  getColumnFilterConfigByFilterKey(key: string): ColumnFilter {
    return this.getColumnConfigByFilterKey(key).filter;
  }

  getColumnConfigByFilterKey(key: string): ColumnConfig {
    const filterPrefix = 'filter-';
    return this.visibleColumns.find(
      col => col.filter.name === key.replace(filterPrefix, '')
    );
  }

  hideTable(): string {
    return this.dataSource.loading$ ? '' : 'hide';
  }

  isExpansionDetailRow(id: number, data: object): boolean {
    return data.hasOwnProperty('extended');
  }

  shouldRowBeExpanded(rowElement) {
    return this.expandedElements.indexOf(rowElement) !== -1
      ? 'expanded'
      : 'collapsed';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
