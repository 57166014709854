import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogTitle } from 'app/interfaces';
import { DialogComponent } from 'app/components/ui/dialog/dialog.component';
import { race, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DialogRedirectService {
  delay = 5000;

  constructor(private dialog: MatDialog, private router: Router) {}

  callDialog(redirectRoute: string, queryParams?: object): void {
    const message = 'You do not have access to this resource';
    const title: DialogTitle = {
      iconName: 'block',
      text: 'No Access',
    };
    const dialogResponseConfig = new MatDialogConfig();
    dialogResponseConfig.width = '320px';
    dialogResponseConfig.data = {
      description: message,
      title,
    };

    const dialogRef = this.dialog.open(DialogComponent, dialogResponseConfig);

    race(
      dialogRef.afterClosed(),
      of('delay').pipe(delay(this.delay))
    ).subscribe(() => {
      dialogRef.close();
      this.router.navigate([redirectRoute], { queryParams });
    });
  }
}
