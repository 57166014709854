import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { interval } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

export enum Reason {
  ClientSide = 'danger',
  ServerSide = 'warning',
}

export const errorMessages = {
  [Reason.ClientSide]:
    'There appears to be a problem with your internet connection.',
  [Reason.ServerSide]:
    'The backend seems to be offline, or is having issues. Please try again later.',
};

@Component({
  selector: 'app-connection-warning',
  styles: [
    `
      #connection-warning {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1100;
      }

      .alert {
        border-radius: 0;
        overflow: hidden;
        transition: height 0.3s ease-out, padding 0.3s ease-out;
        height: 50px;
        box-sizing: border-box;
      }

      .connectionErrorHidden {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
      }
    `,
  ],
  template: `
    <div
      id="connection-warning"
      class="alert alert-{{ reason }}"
      [class.connectionErrorHidden]="!showError"
    >
      <i class="fa fa-fw fa-warning"></i> {{ errorMessage }}
    </div>
  `,
})
export class ConnectionWarningComponent implements OnInit, OnDestroy {
  private readonly DEFAULT_INTERVAL = 5000;
  private readonly DEFAULT_TIMEOUT = 2000;
  private intervalSubscription;
  private httpSubscription;
  private pingUrl: string;
  private interval: number;
  private timeout: number;

  reason: Reason;
  showError = false;
  errorMessage: string;

  constructor(private http: HttpClient, @Inject('env') private environment) {
    this.pingUrl = environment.apiUrls.ping;
    this.interval = environment.pingInterval || this.DEFAULT_INTERVAL;
    this.timeout = environment.pingTimeout || this.DEFAULT_TIMEOUT;
  }

  ngOnInit() {
    if (!this.pingUrl) {
      // Ping URL is not set, connection checks will be disabled.
      return;
    }

    this.intervalSubscription = interval(this.interval).subscribe(() =>
      this.checkConnection()
    );
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  /**
   * Checks users connection by sending a request to the PING URL
   */
  checkConnection() {
    if (this.httpSubscription) {
      this.httpSubscription.unsubscribe();
    }
    this.httpSubscription = this.http
      .get(this.pingUrl)
      .pipe(
        timeout(this.timeout),
        catchError(error => this.handleError(error))
      )
      .subscribe(() => (this.showError = false));
    return this.httpSubscription;
  }

  /**
   * Handles error thrown by HttpClient when the response is invalid
   *
   * @param error - Error object instance
   */
  handleError(error: HttpErrorResponse): any {
    this.reason = Reason.ServerSide;
    const errorName: string = error.name;

    if (error.status === 0 || errorName === 'TimeoutError') {
      this.reason = Reason.ClientSide;
    }

    this.errorMessage = errorMessages[this.reason];
    this.showError = true;
    return throwError(error.statusText);
  }
}
