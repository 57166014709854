import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { environment } from 'env/environment';
import { Language } from 'app/interfaces';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LanguagesService {
  languageEndpoint = environment.apiUrls.news_ws + '/languages';
  constructor(private rest: RestService) {}

  list(): Observable<Language[]> {
    return this.rest.get(this.languageEndpoint).pipe(
      map(res => {
        return <Language[]>res.body;
      })
    );
  }
}
