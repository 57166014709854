import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AsyncTrackerFactory } from './async-tracker/async-tracker-factory.service';
import { ConnectionWarningComponent } from './connection-warning/connection-warning.component';
import { NowComponent } from './date-now/now.component';
import { DomBaseComponent } from './dom-base/dom-base.component';
import { LoadingComponent } from './loading/loading.component';
import { NavBarComponent } from './navbar/navbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    ConnectionWarningComponent,
    NowComponent,
    DomBaseComponent,
    NavBarComponent,
    PageNotFoundComponent,
    LoadingComponent,
  ],
  providers: [DatePipe, AsyncTrackerFactory],
  exports: [
    ConnectionWarningComponent,
    NowComponent,
    DomBaseComponent,
    NavBarComponent,
    PageNotFoundComponent,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
  ],
})
export class CommonElementsModule {}
