import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { environment } from 'env/environment';
import { map } from 'rxjs/operators';
import { HttpResponseBodyWithPagination, Periodicity } from 'app/interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class PeriodicityService {
  periodicityEndpoint =
    environment.apiUrls.news_ws + '/notifications/periodicity';
  constructor(private rest: RestService) {}

  list(): Observable<Periodicity[]> {
    return this.rest
      .get<HttpResponseBodyWithPagination<Periodicity[]>>(
        this.periodicityEndpoint,
        { limit: 5000 }
      )
      .pipe(map(res => res.body.results));
  }
}
