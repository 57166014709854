<mat-form-field
  class="theme-decernis__form-field theme-decernis__form-field--with-chips"
  appearance="outline"
>
  <mat-chip-list #chipList [disabled]="disabled" [compareWith]="compareFn">
    <app-chip
      *ngFor="let chip of chips"
      [selectable]="selectable"
      [removable]="removable"
      [name]="chip.name"
      [iconName]="iconName"
      color="accent"
      (removed)="remove(chip)"
    >
    </app-chip>
    <input
      #searchInput
      [formControl]="searchInputControl"
      [placeholder]="inputPlaceholder()"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      [matAutocomplete]="auto"
      (matChipInputTokenEnd)="add($event)"
      [matAutocompleteDisabled]="!useService"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="addChipFromAutocomplete($event)"
    >
      <mat-option *ngIf="isLoading; else optionList">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-option>

      <ng-template #optionList>
        <mat-option
          *ngFor="let option of filteredOptions"
          [value]="option.value"
          >{{ option.name }}</mat-option
        >
      </ng-template>
    </mat-autocomplete>
  </mat-chip-list>

  <mat-error *ngIf="control.errors?.domainInvalid">
    You have to provide email address with correct domain ({{
      control.errors?.validDomains.join(', ')
    }})
  </mat-error>

  <mat-error *ngIf="control.errors?.toManyEmails">
    You exceeded maximum email addresses available ({{
      control.errors?.maxLength
    }})
  </mat-error>

  <mat-error *ngIf="control.errors?.notEmpty">
    Please set correct value
  </mat-error>

  <mat-hint
    *ngIf="!useService && !disabled && !hideText"
    class="theme-decernis__hint"
    [innerHtml]="hintMessage"
    align="end"
  ></mat-hint>
</mat-form-field>
