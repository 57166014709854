import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of, forkJoin, throwError } from 'rxjs';
import {
  TableData,
  SearchParams,
  Highlight,
  HttpResponseBodyWithPagination,
} from 'app/interfaces';
import { DataSource } from '@angular/cdk/table';
import { tap, map, exhaustMap, switchMap, catchError } from 'rxjs/operators';
import { DocumentDataService } from 'app/services/document/document-data.service';
import {
  DocumentUrlParamsService,
  Param,
} from 'app/services/document/document-url-params.service';
import { RegionService } from '../analytics/region.service';
import { WindowToken } from '../utils/window';
import { DocumentHighlightService } from './document-highlight.service';
import * as _ from 'lodash';
import { uniq } from 'lodash';
import { environment } from 'env/environment';

export interface Synonyms {
  keyTerm: string;
  synonyms: string[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentDataSourceService implements DataSource<TableData> {
  dataSubject = new BehaviorSubject<TableData[]>([]);
  loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public dataLength = null;
  pageLimit = environment.highlightsPageLimit;

  baseParams = {
    offset: this.getPageOffset(),
    hl: false,
  };

  currParams: SearchParams = {
    offset: this.baseParams.offset,
  };

  public pageSize = 50;

  constructor(
    private dataService: DocumentDataService,
    private urlParams: DocumentUrlParamsService,
    private regionService: RegionService,
    private highlightService: DocumentHighlightService,
    @Inject(WindowToken) private window: Window
  ) {}

  loadData(params?) {
    this.getDataObservableChain().subscribe(
      tableData => {
        this.loadingSubject.next(false);
        return this.dataSubject.next(tableData);
      },
      error => {
        error === 'empty-response'
          ? (this.dataLength = 0)
          : (this.dataLength = null);

        const isHightlightError = error === 'hightlight';
        const sendEmptyArray = !isHightlightError;

        this.loadingSubject.next(false);

        if (sendEmptyArray) {
          this.dataSubject.next([]);
        }

        console.error(error);
      }
    );
  }

  getDataObservableChain(): Observable<any> {
    return this.urlParams.getQueryParams().pipe(
      tap(() => this.setInitalFlags()),
      exhaustMap(({ queryParams }) => this.dataSource(queryParams)),
      tap(response => {
        const data = response.results;
        this.dataSubject.next(data);
        this.loadingSubject.next(false);

        if (data.length) {
          this.dataLength = response.count;
        }
      }),
      map(response => response.results),
      switchMap(data => {
        if (data.length) {
          return this.modifyTableData(data);
        } else {
          return throwError('empty-response');
        }
      })
    );
  }

  setInitalFlags() {
    this.loadingSubject.next(true);
    this.dataSubject.next([]);
  }

  dataSource(
    queryParams
  ): Observable<HttpResponseBodyWithPagination<TableData[]>> {
    const mergedParams = { ...this.baseParams, ...queryParams };
    this.currParams = mergedParams;

    if (Object.keys(queryParams).length === 0) {
      this.dataLength = null;
      this.dataSubject.next([]);
      return throwError('empty-params');
    }

    return this.getDataFromService(mergedParams);
  }

  getDataFromService(
    params
  ): Observable<HttpResponseBodyWithPagination<TableData[]>> {
    return this.dataService
      .getData(params)
      .pipe(map(response => response.body));
  }

  getKeyTerms(urlParams: Param[]): Observable<string[]> {
    const keysToKeyTerms = ['keyTerms', 'keyTermsFromProfile'];
    const extractKeyTerms = (name: string) => {
      const keyTerms = urlParams.find(filter => filter.name === name);
      return keyTerms ? keyTerms.values : [];
    };
    let terms = [];
    keysToKeyTerms.forEach(
      name => (terms = [...terms, ...extractKeyTerms(name)])
    );
    return of(terms);
  }

  setHighlight(documents: TableData[]): Observable<TableData[]> {
    documents.map(document => {
      document.loadingHighlights$.next(true);
    });
    return this.urlParams.getQueryParams().pipe(
      exhaustMap(({ source }) => this.getKeyTerms(source)),
      exhaustMap(keyTerms =>
        forkJoin([this.getHighlights(documents, keyTerms), of(keyTerms)])
      ),
      switchMap(([highlight, keyTerms]) => {
        if (!_.isEmpty(highlight)) {
          Object.keys(highlight).map(sectionID => {
            const sectionToHighlight = documents.find(
              tableData => tableData.id === sectionID
            );
            sectionToHighlight.highlights = {
              content: highlight[sectionID].content,
            };
            sectionToHighlight.matchedKeyTerms = this.getMatchedKeyTerms(
              sectionToHighlight,
              keyTerms
            );

            sectionToHighlight.loadingHighlights$.next(false);
          });
        } else {
          return throwError('empty-highlights');
        }
        return of(documents);
      }),
      catchError(error => {
        if (
          error === 'no-key-terms' ||
          error === 'empty-highlights' ||
          error === 'highlight'
        ) {
          documents.map(document => {
            document.loadingHighlights$.next(false);
          });
        }
        return of(error);
      })
    );
  }

  getHighlights(
    documents: TableData[],
    keyTerms: string[]
  ): Observable<Highlight[]> {
    const pattern = /([;!*+|()\[\]{}\^~?:"])/g;
    const ids = documents.map(document => document.id);
    const documentKT: string[] = uniq(
      documents.map(document => document.key_terms).flat()
    );
    const formattedKeyTerms = keyTerms.map(term =>
      term.replace(pattern, encodeURIComponent('\\$1'))
    );

    /* Search only for key terms that are found in documents */
    const containKT = formattedKeyTerms.filter(keyTerm =>
      documentKT.includes(keyTerm)
    );

    if (!containKT.length) {
      return throwError('no-key-terms');
    }

    return this.createHighligtObservable(ids, containKT).pipe(
      catchError(error => {
        return throwError('highlight');
      })
    );
  }

  createHighligtObservable(
    documentId: string[],
    keyTerms: string[]
  ): Observable<Highlight[]> {
    const limit = this.getPageLimit();
    const highlightObs = (ids: string[]) =>
      this.highlightService.getData(ids, keyTerms);

    const result = documentId.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / limit);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return forkJoin(result.map(chunk => highlightObs(chunk))).pipe(
      map(response => Object.assign({}, ...response))
    );
  }

  getPageLimit(): number {
    let limit = null;
    if (!isNaN(this.pageLimit) && this.pageLimit <= this.pageSize) {
      limit = this.pageLimit;
    } else {
      limit = this.pageSize;
    }
    return limit;
  }

  modifyTableData(data: TableData[]): Observable<any> {
    data.forEach(entry => {
      entry.loadingHighlights$ = new BehaviorSubject(false);
    });

    return this.regionService.getMap().pipe(
      map(regionMap => {
        const rows = [];
        data.forEach((tableData: TableData) => {
          tableData.region = this.regionService.translateRegionCode(
            tableData.region,
            regionMap
          );
          rows.push(tableData);
        });
        return rows;
      })
    );
  }

  getMatchedKeyTerms(entry: TableData, keyTerms: string[]): string[] {
    const matchedKeyTerms = [];
    keyTerms.forEach(term => {
      if (entry.key_terms.includes(term)) {
        matchedKeyTerms.push(term);
      }
    });
    return matchedKeyTerms;
  }

  getPageOffset() {
    return +new URLSearchParams(this.window.location.search).get('offset') || 0;
  }

  connect(): Observable<TableData[]> {
    return this.dataSubject.asObservable();
  }

  disconnect() {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
}
