import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ClientConfig } from 'app/interfaces';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClientConfigService } from '../client-config.service';

export interface RouteWithExtraPermission {
  route: string;
  permissionFn: () => Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class AuthPermissionService implements CanActivate {
  routesWithExtraPermissions: RouteWithExtraPermission[] = [
    { route: '/issues', permissionFn: this.issueRoutePermission.bind(this) },
  ];

  constructor(
    private clientConfig: ClientConfigService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkRoutePermission(state).pipe(
      tap(permitted => {
        if (!permitted) {
          this.router.navigate(['not-found']);
        }
      })
    );
  }

  checkRoutePermission(route: RouterStateSnapshot): Observable<boolean> {
    const config = this.routesWithExtraPermissions.find(single =>
      route.url.startsWith(single.route)
    );
    if (config) {
      return config.permissionFn();
    }

    return of(true);
  }

  routeNeedsExtraPermissions(route: string): boolean {
    return this.routesWithExtraPermissions.some(single =>
      single.route.startsWith(route)
    );
  }

  issueRoutePermission(): Observable<boolean> {
    return this.clientConfig
      .getConfig()
      .pipe(
        map(
          (config: ClientConfig) =>
            config.issue_management_view &&
            config.issue_management_view.toLowerCase() === 'true'
        )
      );
  }
}
