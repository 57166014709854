<form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  <app-analytics-search-filters
    #searchFilter
    [initialKeyTerms]="initialKeyTerms"
    [filtersConfig]="filtersConfig"
    [parentForm]="searchForm"
    [isSearchLocked]="!isDirty() || searchForm.invalid"
  ></app-analytics-search-filters>

  <app-analytics-table
    #tableFilter
    [parentForm]="searchForm"
  ></app-analytics-table>
</form>
