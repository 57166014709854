import { Injectable } from '@angular/core';
import { Profile } from 'app/interfaces';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ProfileExportService {
  constructor() {}

  export(profile: Profile) {
    const modifiedProfile = this.modifyProfile(profile);
    const profileData = {
      profile: modifiedProfile,
      timestamp: Date.now(),
    };

    const dateString = formatDate(new Date(), 'yyyy/MM/dd, h:mm', 'en');
    const fileName = `${profile.full_title}-${dateString}.json`;

    const blob = new Blob([JSON.stringify(profileData)], {
      type: 'data:application/json;charset=utf-8',
    });

    if (navigator.msSaveBlob) {
      // IE, EDGE
      navigator.msSaveBlob(blob, fileName);
    } else {
      saveAs(blob, fileName);
    }
  }

  modifyProfile(profile: Profile): Profile {
    delete profile.private;
    delete profile.username;
    delete profile.id;
    return profile;
  }
}
