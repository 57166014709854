import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { KeyTerm, Chip } from 'app/interfaces';

@Component({
  selector: 'app-key-term-datatable',
  templateUrl: './key-term-datatable.component.html',
  styleUrls: ['./key-term-datatable.component.scss'],
})
export class KeyTermDatatableComponent implements OnInit {
  @Output() selectedOutput: EventEmitter<KeyTerm[]> = new EventEmitter();
  @Input() selectedKeyTerms: KeyTerm[];
  keyTermArray: KeyTerm[];
  params = {
    offset: 0,
    search: '',
    limit: 10,
    regex: true,
  };
  displayedTableColumns = ['position', 'key_term', 'actions'];
  tooltipPosition = 'right';
  keyTermMapping = {
    '=1': '1 Key Term',
    other: '# Key Terms',
  };
  clearOnSelect = true;
  blurOnSelect = true;

  constructor() {}

  ngOnInit() {}

  optionSelected(term: Chip[]): void {
    const keyTermName = term[0].name;

    const isUnique =
      this.selectedKeyTerms.find(
        singleTerm => singleTerm.key_term === keyTermName
      ) === undefined;

    if (isUnique) {
      this.selectedKeyTerms.push(this.createKeyTerm(keyTermName));
      this.refreshDataTable();
    }
  }

  deleteRow(index: number): void {
    this.selectedKeyTerms.splice(index, 1);
    this.refreshDataTable();
  }

  refreshDataTable(): void {
    this.selectedKeyTerms = [...this.selectedKeyTerms];
    this.selectedOutput.emit(this.selectedKeyTerms);
  }

  createKeyTerm(term: string): KeyTerm {
    return {
      key_term: term,
      synonym: '',
      url: '',
    };
  }
}
