import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

export interface Timezone {
  offset: string;
  region: string;
  timezone: string;
}

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  endpoint: string = environment.apiUrls.news_ws + '/notifications/timezones';

  constructor(private rest: RestService) {}

  getTimezones(): Observable<Timezone[]> {
    return this.rest.get(this.endpoint).pipe(
      map((response: HttpResponse<any>) => {
        const timezones = response.body.results as Timezone[];
        return timezones;
      })
    );
  }
}
