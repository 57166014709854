import { Injectable, Inject } from '@angular/core';
import {
  Profile,
  SearchParams,
  HttpResponseBodyWithPagination,
} from 'app/interfaces';
import { Observable } from 'rxjs';
import { WindowToken } from '../utils/window';
import { ProfilesV2Service } from '../analytics/profile.service';
import { HttpResponse } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { BaseServerSideDataSource } from 'app/data-source/base-server-side-data-source';
import { RequestArgs } from 'app/components/newsletter/newsletter.ds';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProfilesColumnConfig } from 'app/components/profiles-table/profiles-column-config';

@Injectable({
  providedIn: 'root',
})
export class ProfilesDataSourceService extends BaseServerSideDataSource<
  Profile
> {
  public dataLength = null;
  public pageSize = 50;
  public defaultSortDirection: string;
  public defaultSortName: string;

  offset = this.getPageOffset();
  dateFormat = 'YYYY-MM-DD';
  baseParams = {
    offset: this.getPageOffset(),
    hl: false,
  };

  currParams: SearchParams = {
    offset: this.baseParams.offset,
  };

  constructor(
    private profilesService: ProfilesV2Service,
    @Inject(WindowToken) private window: Window,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    super();
  }

  public loadData(params: RequestArgs = {}): void {
    this.loadingSubject.next(true);
    this.getProfiles(this.createSearchParams(params))
      .pipe(
        tap(
          (
            response: HttpResponse<HttpResponseBodyWithPagination<Profile[]>>
          ) => {
            this.dataSubject.next([]);
            this.dataLength = response.body.count;
          }
        ),
        map(
          (response: HttpResponse<HttpResponseBodyWithPagination<Profile[]>>) =>
            response.body.results
        )
      )
      .subscribe(
        (profileList: Profile[]) => {
          this.loadingSubject.next(false);
          this.dataSubject.next(this.formatProfiles(profileList));
        },
        error => {
          this.dataLength = 0;
          this.dataSubject.next([]);
          this.loadingSubject.next(false);
        }
      );
  }

  createSearchParams(params: RequestArgs): RequestArgs {
    let name: string, ordering: string, direction;
    const getDirection = (dir: string) => (dir === 'desc' ? '-' : '');

    if (!params.ordering) {
      if (params.active && params.direction) {
        name = params.active;
        direction = getDirection(params.direction);
      } else {
        name = this.defaultSortName;
        direction = getDirection(this.defaultSortDirection);
      }
      ordering = direction + name;
    } else {
      ordering = params.ordering;
    }

    delete params.active;
    delete params.direction;

    return { ...params, ordering };
  }

  formatProfiles(profileList: Profile[]): Profile[] {
    return profileList.map(profile => {
      let modifiedProfile = this.removeKeys(profile);
      modifiedProfile = this.mapDate(modifiedProfile);
      return modifiedProfile;
    });
  }

  removeKeys(profile: Profile): Profile {
    const onlyNeededKeys = (single: Profile) => {
      const {
        id,
        full_title,
        key_terms,
        key_terms_string,
        structure,
        advanced_view_selected,
        filter_query_string,
        username,
        query_string,
        description,
        created,
        update_date,
        company,
        shared,
        shared_to_companies,
      } = single;
      return {
        id,
        full_title,
        private: single.private,
        key_terms,
        advanced_view_selected,
        key_terms_string,
        structure,
        filter_query_string,
        username,
        query_string,
        description,
        created,
        update_date,
        company,
        shared,
        shared_to_companies,
      };
    };

    return onlyNeededKeys(profile);
  }

  mapDate(profile: Profile): Profile {
    const dateKeys = ['update_date', 'created'];
    dateKeys.forEach(key => {
      if (profile[key]) {
        profile[key] = moment(profile[key]).format(this.dateFormat);
      } else {
        profile[key] = '-';
      }
    });

    return profile;
  }

  getProfiles(
    params: RequestArgs = {}
  ): Observable<HttpResponse<HttpResponseBodyWithPagination<Profile[]>>> {
    const newArgs = {
      offset: this.offset.toString(),
      limit: this.pageSize.toString(),
      ...params,
    };
    return this.profilesService
      .getProfiles(newArgs)
      .pipe(tap(() => this.setVariablesToUrl(newArgs)));
  }

  setVariablesToUrl(args: RequestArgs) {
    const params = this.generateUrlParams(args);
    const url = this.router
      .createUrlTree([], { relativeTo: this.activatedRoute })
      .toString();

    this.location.go(url, params.toString());
  }

  generateUrlParams(args: RequestArgs): URLSearchParams {
    const setParam = (name: string, value: string) =>
      params.has(name) ? params.set(name, value) : params.append(name, value);

    const params = new URLSearchParams();
    const offset = args.offset
      ? args.offset.toString()
      : this.offset.toString();

    let sort: string;
    if (!args.ordering) {
      sort = this.getSortingKey(
        this.defaultSortName,
        this.defaultSortDirection
      );
    } else {
      sort = args.ordering;
    }

    Object.entries(args).forEach(([key, val]) => {
      setParam(key, val);
    });

    setParam('offset', offset);
    setParam('ordering', sort);
    return params;
  }

  getSortingKey(name: string, direction: string): string {
    let sortingKey: string, directionKey: string, directionValue: string;
    if (name && direction) {
      const option = ProfilesColumnConfig.find(
        col => col.sortingKey === name || col.key === name
      );
      sortingKey = option.sortingKey ? option.sortingKey : option.key;
      directionKey = direction;
    } else {
      sortingKey = this.defaultSortName;
      directionKey = this.defaultSortDirection;
    }

    directionValue = directionKey === 'desc' ? '-' : '';
    return directionValue + sortingKey;
  }

  getPageOffset() {
    return +new URLSearchParams(this.window.location.search).get('offset') || 0;
  }

  connect(): Observable<Profile[]> {
    return this.dataSubject.asObservable();
  }

  disconnect() {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
}
