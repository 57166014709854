import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRemoveUTC',
})
export class DateRemoveUTCPipe implements PipeTransform {
  /**
   * Parse string to Date and remove UTC timezone (interpret as local)
   *
   * @param value date string
   * @param args
   * @return {Date} date with timezone removed
   */
  transform(value, args?: any): Date {
    const valueDate = new Date(value);
    return new Date(
      valueDate.getUTCFullYear(),
      valueDate.getUTCMonth(),
      valueDate.getUTCDate(),
      valueDate.getUTCHours(),
      valueDate.getUTCMinutes(),
      valueDate.getUTCSeconds()
    );
  }
}
