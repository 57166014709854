import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from 'env/environment';
import { Observable } from 'rxjs';
import { RestService } from 'sso-angular';
import { HttpResponse, HttpParams } from '@angular/common/http';

interface Document {
  id: string;
  highlights;
  key_terms: string[];

  $matchedKeyTerms: string[];
}

/**
 * Documents querying
 */
@Injectable()
export class DocumentsService {
  documentsEndpoint: string =
    environment.apiUrls.news_ws + '/analytics/documents';

  constructor(private rest: RestService) {}

  query(params) {
    const searchParams = new HttpParams({ fromObject: params });
    return this.rest.post(
      this.documentsEndpoint,
      searchParams,
      {},
      {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }
    );
  }

  /**
   * Annotate matching key terms subset
   * @param docs
   * @param keyTerms
   * @returns annotated docs
   */
  setMatchedKeyTerms(docs: Document[], keyTerms: Iterable<string>): Document[] {
    const keyTermsSet = new Set(keyTerms);
    docs.forEach(doc => {
      doc.$matchedKeyTerms = doc.key_terms.filter(keyTerm =>
        keyTermsSet.has(keyTerm)
      );
    });
    return docs;
  }

  /**
   * Get highlights for docs identified by supplied ID.
   *
   * @param docIds: IDs of docs to highlight using query
   * @param query: SOLR query used for highlighting
   */
  highlightsOnly(docIds: number[], query: string): Observable<Document[]> {
    const params = {
      fields: 'id',
      fq: 'id:(' + docIds.join(' ') + ')',
      hl: true,
      q: query,
    };

    return this.query(params).pipe(
      map((response: HttpResponse<any>) => response.body.results)
    );
  }
}
