import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DialogTitle,
  DialogOptionActions,
  InputOptions,
  AdditionalDialogData,
  ColumnConfig,
  ColumnOutput,
} from 'app/interfaces';

@Component({
  selector: 'app-news-modal',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  public static readonly genericErrorMessages = {
    '502': 'The server is currently unavailable. Please try again later.',
    '503':
      'The server is currently undergoing maintenance. Please try again later.',
    '504':
      'The server took to long to reply, as it may be overloaded. Please try again later.',
    default: 'Something went wrong.',
  };

  title: DialogTitle;
  description: string;
  confirm: boolean;
  inputOptions: InputOptions;
  optionActions: DialogOptionActions;
  file: File;
  columnPickerConfig: ColumnConfig[];
  columnSelected: ColumnConfig[];
  additionalData: AdditionalDialogData = {
    confirmed: true,
  };
  isDisabled = false;

  static parseErrorResponse(errors) {
    let message = '';

    // for some reason when news_ws is off it return status 0
    if (errors.status === 500 || errors.status === 0) {
      message = errors.statusText;
    } else if (
      errors.hasOwnProperty('status') &&
      this.genericErrorMessages.hasOwnProperty(errors.status.toString())
    ) {
      message = this.genericErrorMessages[errors.status.toString()];
    } else if (typeof errors.error === 'object' && errors.error !== null) {
      message = Object.values(errors.error).toString();
    } else {
      message = this.genericErrorMessages['default'];
    }
    return message;
  }

  constructor(
    private modalRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    const {
      description,
      title,
      confirm,
      optionActions,
      inputOptions,
      columnPickerConfig,
    } = data;
    this.description = description;
    this.title = title;
    this.confirm = confirm;
    this.optionActions = optionActions;
    this.inputOptions = inputOptions;
    this.columnPickerConfig = columnPickerConfig;
  }

  setValid(isValid: boolean): void {
    this.isDisabled = !isValid;
  }

  onFileInput(file: File) {
    this.additionalData.file = file;
  }

  onColumnPickerChange(column: ColumnOutput) {
    if (!this.additionalData.columnOptions) {
      this.additionalData.columnOptions = {} as ColumnOutput[];
    }
    this.additionalData.columnOptions[column.key] = column.checked;
  }

  save() {
    this.additionalData.confirmed = true;
    this.modalRef.close(this.additionalData);
  }

  close() {
    this.additionalData.confirmed = false;
    this.modalRef.close(this.additionalData);
  }
}
