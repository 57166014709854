import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import {
  ColumnConfig,
  ColumnFilter,
  ColumnFilterValue,
  RequestArgs,
} from 'app/components/ui/data-table/data-table.interfaces';
import { BaseServerSideDataSource } from 'app/data-source/base-server-side-data-source';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styles: [],
})
export class DataTableComponent<T> implements AfterViewInit {
  @Input() initialSortName: string;
  @Input() initialSortDirection: string;
  @Input() set columnConfig(config: ColumnConfig[]) {
    this._columnConfig = config;
    this.applyNewColumnConfig(config);
    this.removeHiddenColumnsFromFilters(config);

    if (this.sort) {
      this.resetSort();
    }

    this.filters = {};
  }
  @Input() dataSource: BaseServerSideDataSource<T>;
  @Input() isPageable: boolean;

  get columnConfig() {
    return this._columnConfig;
  }

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ContentChild('cellTemplate', { static: true }) cellTemplate: TemplateRef<
    any
  >;

  displayedColumns: string[];
  filterColumns: string[];
  _columnConfig: ColumnConfig[];

  filterPrefix = 'filter-';
  filters: RequestArgs = {};
  filtersSubject: Subject<RequestArgs> = new Subject();

  loading$: Observable<boolean>;

  ngAfterViewInit() {
    this.initDataChangeTriggers();
    this.loading$ = this.dataSource.loading$;
  }

  resetSort() {
    const active = this.initialSortName;
    const direction = this.initialSortDirection as SortDirection;
    this.sort.active = active;
    this.sort.direction = direction;

    /* This is an ugly hack to reset sort to initial state (related: https://github.com/angular/components/issues/10242)*/
    this.sort._stateChanges.next();
  }

  initDataChangeTriggers() {
    if (this.isPageable) {
      this.dataSource.paginator = this.matPaginator;
    }

    if (!!this.columnConfig.filter(col => col.sortable)) {
      this.dataSource.sort = this.sort;
    }

    const filter = this.columnConfig.filter(col => col.filter);
    if (filter.length) {
      const intialFilterValue = filter
        .filter(col => col.filter.value)
        .reduce((curr, next) => {
          curr[next.filter.name] = next.filter.value;
          return curr;
        }, {});

      this.filters = intialFilterValue;
      this.dataSource.currArgs.filters = intialFilterValue;
      this.dataSource.filter = this.filtersSubject;
    }
  }

  applyNewColumnConfig(config: ColumnConfig[]) {
    const visibleColumn = (col: ColumnConfig) => !col.hide;
    const filterVisible = (col: ColumnConfig) =>
      visibleColumn(col) && col.filter;

    this.displayedColumns = config
      .filter(visibleColumn)
      .map(col => col.sortingKey || col.key);

    this.filterColumns = this.columnConfig
      .filter(filterVisible)
      .map(col => `${this.filterPrefix}${col.key}`);
  }

  removeHiddenColumnsFromFilters(config: ColumnConfig[]) {
    const hiddenFilters = config
      .filter(col => col.hide && col.filter)
      .map(col => col.filter.name);
    hiddenFilters.forEach(name => delete this.filters[name]);
  }

  getFilterColumnConfig(name: string): ColumnFilter {
    return this.columnConfig.find(
      col => col.key === name.slice(this.filterPrefix.length)
    ).filter;
  }

  createContext(element: T, column: ColumnConfig) {
    return { element, column };
  }

  onFilterValueChange(filterValue: ColumnFilterValue) {
    const key = Object.keys(filterValue)[0];
    const value = filterValue[key];
    if (value) {
      this.filters = { ...this.filters, ...filterValue };
      const filterConfig = this.columnConfig.find(
        col => col.filter.name === key.replace(this.filterPrefix, '')
      );
      filterConfig.filter.value = value;
    } else {
      delete this.filters[key];
    }
  }

  onFiltersSubmit() {
    this.filtersSubject.next(this.filters);
  }
}
