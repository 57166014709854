<span class="form-group data-table-form" id="key-terms-form-group">
  <h4>
    Search Parameters:
  </h4>
  <app-key-term-dropdown
    #keyTermDropdown
    (selectChange)="optionSelected($event)"
    (valid)="onKeyTermsValidUpdate($event)"
    [clearOnSelect]="clearOnSelect"
    [blurOnSelect]="blurOnSelect"
    [multiple]="multiple"
    [initialChips]="chipList"
  >
  </app-key-term-dropdown>
</span>
