<div class="analyticsFilters" [formGroup]="parentForm">
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <div
        class="analyticsFilters__column"
        [class.analyticsFilters__column--selected]="
          selectedColumnID.includes(0)
        "
        (click)="selectColumn(0)"
      >
        <app-key-terms-select
          #searchKeyTerms
          [initialKeyTerms]="initialKeyTerms"
          (selectedOutput)="keyTermsEvent($event)"
          (valid)="searchInputsAreValid($event, 0)"
        ></app-key-terms-select>

        <h4>Filters</h4>

        <app-filters
          [logicalOperators]="filtersConfig.logicalOperators"
          [selectOptions]="filtersConfig.selectOptions"
          [rowsOptions]="filtersConfig.queryRowsOptions"
          [queryRowsList]="filtersConfig.queryRowsOptions.queryRowsList"
          (rowsUpdate)="rowsUpdate($event)"
          (searchInputsAreValid)="searchInputsAreValid($event, 1)"
        ></app-filters>
      </div>
    </div>

    <div class="col-md-6 col-xs-12">
      <div
        class="analyticsFilters__column"
        [class.analyticsFilters__column--selected]="
          selectedColumnID.includes(1)
        "
        (click)="selectColumn(1)"
      >
        <h4>Profile</h4>

        <app-profile-picker
          [control]="parentForm.get('profile')"
          [displayEmpty]="'true'"
          [resetSubject]="resetProfilesSubject"
          [initialProfileIds]="this.parentForm.get('profile').value"
          (selectedObjects)="setSelectedProfile($event)"
        >
        </app-profile-picker>

        <div class="profiles-buttons">
          <button
            class="theme-decernis__button panel-profiles"
            mat-button
            mat-stroked-button
            type="button"
            routerLink="/profiles/add"
          >
            <mat-icon
              class="theme-decernis__icon"
              svgIcon="add_circle"
            ></mat-icon>
            Add
          </button>

          <button
            class="theme-decernis__button panel-profiles"
            mat-button
            mat-stroked-button
            type="button"
            *ngIf="
              parentForm.get('profile').value &&
              permissionToModify(selectedProfile)
            "
            routerLink="/profiles/{{ parentForm.get('profile').value }}"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="edit"></mat-icon>
            Edit
          </button>

          <button
            class="theme-decernis__button panel-profiles"
            mat-button
            mat-stroked-button
            type="button"
            (click)="importProfile()"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="upload"></mat-icon>
            Import
          </button>

          <button
            class="theme-decernis__button panel-profiles"
            mat-button
            mat-stroked-button
            type="button"
            *ngIf="parentForm.get('profile').value"
            (click)="exportProfile(parentForm.get('profile').value)"
          >
            <mat-icon
              class="theme-decernis__icon"
              svgIcon="download"
            ></mat-icon>
            Export
          </button>

          <button
            class="theme-decernis__button panel-profiles"
            mat-button
            mat-stroked-button
            type="button"
            *ngIf="
              parentForm.get('profile').value &&
              permissionToModify(parentForm.get('profile').value)
            "
            (click)="callDeleteProfile()"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="delete"></mat-icon>
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <button
    class="theme-decernis__button analyticsFilters__searchButton"
    mat-button
    mat-stroked-button
    color="primary"
    type="submit"
    [disabled]="isSearchLocked"
  >
    Search
  </button>
</div>
