import { ColumnConfig } from 'app/interfaces';

const ColumnConfigData: ColumnConfig[] = [
  {
    name: 'Title',
    key: 'title',
    sortable: true,
    locked: true,
    hide: false,
    bold: true,
    sticky: true,
    filter: {
      hide: false,
      type: 'text',
      value: '',
      name: 'title',
    },
  },
  {
    name: 'Issue type',
    key: 'issue_type',
    sortable: true,
    locked: false,
    hide: false,
    sortingKey: 'issue_type__issue_type',
    filter: {
      hide: false,
      type: 'select',
      value: '',
      name: 'issue_type',
      selectable: [],
    },
  },

  {
    name: 'Status',
    key: 'status',
    sortable: true,
    locked: true,
    hide: false,
    sortingKey: 'publish',
    filter: {
      hide: false,
      type: 'select',
      name: 'status',
      value: '',
      selectable: [
        { name: 'Draft', value: 'draft' },
        { name: 'Scheduled', value: 'scheduled' },
        { name: 'Published', value: 'published' },
      ],
    },
  },
  {
    name: 'Creator',
    key: 'creator',
    sortable: true,
    locked: false,
    hide: false,
    sortingKey: 'user__username',
    filter: {
      hide: false,
      type: 'text',
      value: '',
      name: 'username',
    },
  },
  {
    name: 'Modification date',
    key: 'modified',
    sortable: true,
    locked: false,
    hide: false,
    sortingKey: 'updated',
    filter: {
      hide: false,
      type: 'date',
      value: '',
      name: 'updated',
    },
  },
  {
    name: 'Creation date',
    key: 'created',
    sortable: true,
    locked: false,
    hide: true,
    filter: {
      hide: false,
      type: 'date',
      value: '',
      name: 'created',
    },
  },
  {
    name: 'Publishing date',
    key: 'publish',
    sortable: true,
    locked: false,
    hide: true,
    filter: {
      hide: false,
      type: 'date',
      value: '',
      name: 'publish',
    },
  },
  {
    name: 'Company',
    key: 'ownercompany',
    sortable: true,
    locked: false,
    hide: true,
    sortingKey: 'owner_company__name',
    filter: {
      hide: false,
      type: 'text',
      value: '',
      name: 'owner_company',
    },
  },
  {
    name: 'Manage',
    key: 'manage',
    sortable: false,
    locked: true,
    hide: false,
    stickyEnd: true,
    filter: {
      hide: false,
      type: 'submit',
      value: '',
      name: 'manage',
    },
  },
];

export { ColumnConfigData };
