<div class="filters">
  <div class="filters__header" *ngIf="title">
    <h3 class="filters__title">{{ title }}</h3>
    <mat-icon
      *ngIf="tooltip"
      class="theme-decernis__icon theme-decernis__icon--small filters__header-icon"
      color="primary"
      svgIcon="info"
      matTooltip="{{ tooltip }}"
      matTooltipClass="theme-decernis__tooltip"
    ></mat-icon>
  </div>
  <ul class="filters__list">
    <li
      *ngFor="let queryRow of queryRowsList; let id = index"
      class="filters__list-item"
      [attr.data-key]="id"
    >
      <mat-form-field
        class="theme-decernis__form-field filters__variableSelect"
        appearance="outline"
        *ngIf="selectableOptions.length > 1"
      >
        <mat-select
          class="theme-decerins__select"
          panelClass="theme-decernis__selectPanel"
          disableOptionCentering
          placeholder="Choose variable"
          [value]="queryRow.selectedOption"
          (selectionChange)="onSelectVariableChange($event.value, id)"
        >
          <span
            *ngFor="let option of selectableOptions"
            [matTooltipDisabled]="!option.variableDisabled"
            [matTooltip]="getTooltipText(option.name)"
          >
            <mat-option
              value="{{ option.safeName }}"
              [ngClass]="{ disabled: option.variableDisabled }"
            >
              {{ option.name }}
            </mat-option>
          </span>
        </mat-select>
      </mat-form-field>

      <ng-container [ngSwitch]="queryRow.selectedOption">
        <ng-container *ngSwitchCase="'date'">
          <mat-form-field
            class="theme-decernis__form-field"
            appearance="outline"
          >
            <input
              matInput
              type="number"
              [formControl]="dateFormControl"
              min="1"
              appOnlyNumbers
              placeholder="Type a number..."
              autocomplete="off"
              (input)="onDateNumberInput($event.target.value, id)"
            />
            <mat-error align="end" *ngIf="dateFormControl.errors?.max"
              >Maximum number is 1000</mat-error
            >
          </mat-form-field>

          <mat-form-field
            class="theme-decernis__form-field"
            appearance="outline"
          >
            <mat-select
              class="theme-decerins__select"
              panelClass="theme-decernis__selectPanel"
              disableOptionCentering
              [value]="getOptionalText(id)"
              (selectionChange)="onDateSelectChange($event.value, id)"
            >
              <mat-option
                *ngFor="
                  let options of queryRow._additionalOptions.dateSelectValues
                "
                value="{{ options.value }}"
              >
                {{ options.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <app-key-term-dropdown
          *ngSwitchCase="'key_terms'"
          class="filters__searchPreview"
          [clearOnSelect]="clearOnSelect"
          [blurOnSelect]="blurOnSelect"
          [multiple]="true"
          [initialChips]="queryRow.chips"
          [withExtraActions]="true"
          (selectChange)="onChipListUpdate($event, id)"
        >
        </app-key-term-dropdown>

        <app-chips-select
          *ngSwitchDefault
          class="filters__searchPreview"
          [disabled]="queryRow._variableSelectDisabled"
          [useService]="queryRow._useService"
          [chips]="queryRow.chips"
          [selectableOptions]="
            queryRow.selectedOption &&
            getOptionObj(queryRow.selectedOption).serviceValues
          "
          [isLoading]="isLoading"
          [hintMessage]="defaultHintMessage"
          (valid)="onSearchValidUpdate($event, id)"
          (chipListUpdate)="onChipListUpdate($event, id)"
        >
        </app-chips-select>
      </ng-container>

      <mat-form-field
        *ngIf="logicalOperators"
        class="theme-decernis__form-field filters__logicalOperatorSelect"
        appearance="outline"
      >
        <mat-select
          class="theme-decerins__select"
          panelClass="theme-decernis__selectPanel"
          disableOptionCentering
          [value]="queryRow.logicalOperator"
          [disabled]="queryRow._logicalOperatorDisabled"
          (selectionChange)="onLogicalOperatorSelect($event.value, id)"
        >
          <mat-option
            *ngFor="let option of logicalOperators.options"
            value="{{ option.value }}"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <button
        (click)="addRow()"
        mat-button
        mat-icon-button
        class="theme-decernis__button"
        type="button"
      >
        <mat-icon
          class="theme-decernis__icon"
          color="primary"
          svgIcon="add_circle"
        ></mat-icon>
      </button>

      <button
        *ngIf="queryRowsList.length > 1"
        (click)="removeRow(id, queryRow.selectedOption)"
        mat-button
        mat-icon-button
        class="theme-decernis__button"
        type="button"
      >
        <mat-icon
          class="theme-decernis__icon"
          color="primary"
          svgIcon="remove_circle"
        ></mat-icon>
      </button>
    </li>
  </ul>
</div>
