import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';
import { map } from 'rxjs/operators';
import { HttpResponseBodyWithPagination, Company } from 'app/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private rest: RestService) {}

  get(customEndpoint?: string): Observable<Company[]> {
    let endpoint = environment.apiUrls.news_ws + '/issue/issueadminusercompany';
    if (customEndpoint) {
      endpoint = environment.apiUrls.news_ws + customEndpoint;
    }

    return this.rest
      .get<HttpResponseBodyWithPagination<Company[]>>(endpoint, {
        limit: 5000,
      })
      .pipe(map(res => res.body.results));
  }
}
