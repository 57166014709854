<input matInput class="select-search-input select-search-hidden" />

<div
  class="select-search-inner"
  [ngClass]="{ 'select-search-inner-multiple': matSelect.multiple }"
>
  <input
    matInput
    class="select-search-input"
    #searchSelectInput
    (keydown)="_handleKeydown($event)"
    (input)="onInputChange($event.target.value)"
    (blur)="onBlur($event.target.value)"
    [placeholder]="placeholderLabel"
  />
  <button
    mat-button
    *ngIf="value"
    mat-icon-button
    aria-label="Clear"
    (click)="_reset(true)"
    class="select-search-clear"
  >
    <mat-icon
      class="select-search-icon theme-decernis__icon"
      svgIcon="close"
    ></mat-icon>
  </button>
</div>

<div
  *ngIf="noEntriesFoundLabel && value && _options?.length === 0"
  class="select-search-no-entries-found"
>
  {{ noEntriesFoundLabel }}
</div>
