<app-loading [show]="showLoader"></app-loading>

<div class="profile-form mat-typography wrapper">
  <div class="form__header">
    <h2>
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--big"
        svgIcon="{{ iconName }}"
      ></mat-icon>
      {{ pageTitle }}
    </h2>
  </div>

  <ng-container *ngIf="!showLoader">
    <div class="issue__row">
      <h3 class="issue__sectionTitle">Title:</h3>
      <span class="issue__sectionContent">{{ title }}</span>
    </div>
    <div class="issue__row">
      <h3 class="issue__sectionTitle">Issue type:</h3>
      <span class="issue__sectionContent">{{
        mapIssueType(issue_type) | async
      }}</span>
    </div>
    <div class="issue__row">
      <h3 class="issue__sectionTitle">Publishing date:</h3>
      <span *ngIf="publish; else noDate" class="issue__sectionContent">
        <app-chip
          color="accent"
          [iconName]="'calendar'"
          [name]="publish | date: 'yyyy/MM/dd'"
        >
        </app-chip>
      </span>
      <ng-template #noDate class="issue__sectionContent">
        -
      </ng-template>
    </div>
    <div class="issue__row" *ngIf="content">
      <h3 class="issue__sectionTitle">Content:</h3>
      <span class="issue__sectionContent">
        <div [innerHtml]="content"></div>
      </span>
    </div>
    <div class="issue__row" *ngIf="attachments.length">
      <h3 class="issue__sectionTitle">Attachments:</h3>
      <ul>
        <li *ngFor="let attachment of attachments">
          <button
            class="issue__download"
            type="button"
            (click)="
              getAttachement(attachment.uploaded_file, attachment.file_name)
            "
          >
            {{ attachment.file_name }}
          </button>
        </li>
      </ul>
    </div>
    <div class="issue__row" *ngIf="tags.length">
      <h3 class="issue__sectionTitle">Tags:</h3>
      <app-tag-list [hideTitle]="true" [tags]="tags"> </app-tag-list>
    </div>
    <div class="issue__row" *ngIf="(isContentAdmin$ | async) && company.length">
      <h3 class="issue__sectionTitle">Subscribers:</h3>
      <span class="issue__sectionContent">
        <span class="issue__chipWrap">
          <ng-container *ngFor="let singleCompany of company">
            <app-chip
              color="accent"
              [iconName]="'check'"
              [name]="singleCompany.name"
            ></app-chip>
          </ng-container>
        </span>
      </span>
    </div>
    <footer class="form__footer">
      <div class="container">
        <div class="form__footerInner">
          <button
            class="theme-decernis__button"
            mat-button
            mat-stroked-button
            color="primary"
            type="button"
            (click)="onCancelClick()"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="cancel"></mat-icon>
            Back
          </button>
        </div>
      </div>
    </footer>
  </ng-container>
</div>
