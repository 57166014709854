import { BaseServerSideDataSource } from 'app/data-source/base-server-side-data-source';
import { Observable, Subscription } from 'rxjs';
import { EventSubscriptions } from 'app/interfaces';

export interface RequestArgs {
  [key: string]: string;
}
export class SubscriptionsDataSource extends BaseServerSideDataSource<
  EventSubscriptions
> {
  public fetchingDataObservable: Observable<any>;
  public subscription = Subscription.EMPTY;

  constructor(protected eventSubscriptionsService) {
    super();
  }

  public loadData(): void {
    this.loadingSubject.next(true);
    this.eventSubscriptionsService.list().subscribe(
      response => {
        this.dataLength = response.body.count;
        this.data = response.body;
        this.dataSubject.next(this.data);
      },
      error => {
        console.log(error);
        const errorText = error.detail || error.statusText;
      },
      () => {
        this.loadingSubject.next(false);
      }
    );
  }
}
