import { BaseServerSideDataSource } from 'app/data-source/base-server-side-data-source';
import { Observable, Subscription } from 'rxjs';
import { Newsletter, HttpResponseBodyWithPagination } from 'app/interfaces';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

export interface RequestArgs {
  [key: string]: string;
}
export class NewsletterDataSource extends BaseServerSideDataSource<Newsletter> {
  public fetchingDataObservable: Observable<any>;
  public subscription = Subscription.EMPTY;

  offset = this.getPageOffset();

  constructor(
    protected newsletterService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public loadData(args?: RequestArgs): void {
    this.loadingSubject.next(true);
    this.getDataObservable(args).subscribe(
      response => {
        this.dataLength = response.body.count;
        this.data = response.body.results;
        this.dataSubject.next(this.data);
      },
      error => {
        console.log(error);
        const errorText = error.detail || error.statusText;
      },
      () => {
        this.loadingSubject.next(false);
      }
    );
  }

  setVariablesToUrl(args: RequestArgs) {
    const params = new URLSearchParams(window.location.search);
    const offset = args.offset
      ? args.offset.toString()
      : this.offset.toString();

    if (params.has('offset')) {
      params.set('offset', offset);
    } else {
      params.append('offset', offset);
    }

    const url = this.router
      .createUrlTree([], { relativeTo: this.activatedRoute })
      .toString();

    this.location.go(url, params.toString());
  }

  getDataObservable(
    args?: RequestArgs
  ): Observable<HttpResponse<HttpResponseBodyWithPagination<Newsletter[]>>> {
    const newArgs = { offset: this.offset.toString(), ...args };
    return this.newsletterService
      .list(newArgs)
      .pipe(tap(() => this.setVariablesToUrl(newArgs)));
  }

  getPageOffset() {
    return +new URLSearchParams(window.location.search).get('offset') || 0;
  }
}
