<div class="row-above-header">
  <ng-container *ngIf="dataSource">
    <div class="panelButtons">
      <app-google-translate-widget></app-google-translate-widget>
      <button
        class="theme-decernis__button"
        mat-button
        type="button"
        mat-stroked-button
        (click)="exportToXls($event, true)"
        [disabled]="(dataSource.loading$ | async) || !dataSource.dataLength"
      >
        <mat-icon class="theme-decernis__icon" svgIcon="save"></mat-icon>
        Export to XLS
      </button>
    </div>
    <div>
      <button
        class="theme-decernis__button"
        mat-button
        type="button"
        mat-stroked-button
        color="accent"
        id="details-button"
        (click)="toggleExpandAll()"
        [disabled]="dataSource.loading$ | async"
      >
        <mat-icon class="theme-decernis__icon" svgIcon="info"></mat-icon>
        <span>{{ expandAll ? 'HIDE DETAILS' : 'SHOW DETAILS' }}</span>
      </button>

      <button
        class="theme-decernis__button fixed-btn-width"
        mat-button
        type="button"
        mat-flat-button
        color="primary"
        (click)="select.open()"
      >
        <mat-select
          #select
          (selectionChange)="onColumnSelectChange($event)"
          placeholder="{{ displayedColumns.length }} Columns"
          [(value)]="displayedColumns"
          multiple
          panelClass="theme-decernis__selectPanel"
        >
          <mat-select-trigger>
            <span> {{ displayedColumns.length }} Columns </span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let option of visibleColumns"
            [value]="option.key"
            [disabled]="option.name === 'Citation'"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </button>
    </div>
  </ng-container>
</div>

<p *ngIf="!(dataSource.loading$ | async) && dataSource.dataLength !== null">
  About {{ dataSource.dataLength }} document{{
    dataSource.dataLength !== 1 ? 's' : ''
  }}
  found.
</p>

<div class="theme-decernis__tableWrap" [formGroup]="parentForm">
  <table
    mat-table
    matSort
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
    multiTemplateDataRows
    class="theme-decernis__table"
    [dataSource]="dataSource"
  >
    <ng-container *ngFor="let col of visibleColumns">
      <ng-container
        *ngIf="col.key !== 'date'; else dateColumn"
        [matColumnDef]="col.key"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [attr.data-disabled]="!col.sortable || !dataSource?.dataLength"
          class="theme-decernis__tableHead"
        >
          {{ col.name }}
        </th>

        <td
          mat-cell
          *matCellDef="let element"
          class="theme-decernis__tableCell"
        >
          <ng-container *ngIf="!col.bold; else link">
            {{ element[col.key] }}
          </ng-container>
          <ng-template #link>
            <a class="link" href="{{ element.url }}" target="_blank">{{
              element.title
            }}</a>
          </ng-template>
        </td>
      </ng-container>

      <ng-template #dateColumn>
        <ng-container matColumnDef="date">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [attr.data-disabled]="!col.sortable || !dataSource.dataLength"
            class="theme-decernis__tableHead"
          >
            Publication Date
          </th>
          <td
            class="theme-decernis__tableCell"
            mat-cell
            *matCellDef="let element"
          >
            {{ element.date | date: 'yyyy-MM-dd':'UTC' }}
          </td>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngFor="let column of filterColumns" [matColumnDef]="column">
      <td
        *matHeaderCellDef
        class="theme-decernis__tableHead theme-decernis__tableHead--lighter"
      >
        <ng-container *ngIf="getColumnConfigByFilterKey(column); let config">
          <ng-container *ngIf="config.key !== 'date'; else dateCell">
            <ng-container *ngIf="!config.autocomplete; else autocomplete">
              <mat-form-field
                class="theme-decernis__form-field theme-decernis__form-field--as-filter"
                appearance="outline"
                hideRequiredMarker
              >
                <input
                  #filterInput
                  matInput
                  type="text"
                  placeholder="Type to filter..."
                  title="{{ config.name }} filter"
                  [attr.data-filter-field]="config.key"
                  [value]="params[config.filter.name] || ''"
                />
              </mat-form-field>
            </ng-container>

            <ng-template #autocomplete>
              <mat-form-field
                class="theme-decernis__form-field theme-decernis__form-field--as-filter"
                appearance="outline"
                hideRequiredMarker
              >
                <input
                  #autocompleteInput
                  matInput
                  type="text"
                  placeholder="Type to filter..."
                  [matAutocomplete]="auto"
                  [attr.data-filter-field]="config.key"
                  [value]="params[config.filter.name] || ''"
                />

                <mat-autocomplete
                  #auto="matAutocomplete"
                  class="theme-decernis__autocompletePanel"
                >
                  <mat-option
                    *ngIf="config.autocompleteLoading; else optionList"
                  >
                    <mat-spinner diameter="20"></mat-spinner>
                  </mat-option>

                  <ng-template #optionList>
                    <mat-option
                      *ngFor="let option of config.autocompleteDisplayedValues"
                      [value]="option.value"
                      class="mat-option-"
                      >{{ option.name }}</mat-option
                    >
                  </ng-template>
                </mat-autocomplete>
              </mat-form-field>
            </ng-template>
          </ng-container>

          <ng-template #dateCell>
            <div class="datepicker-wrapper">
              <app-datepicker
                [placeholder]="'From'"
                [basicStyles]="'true'"
                [value]="parentForm.controls.dateFrom.value"
                (onChange)="onDatepickerChange($event, 'dateFrom')"
              >
              </app-datepicker>
            </div>
            <div class="datepicker-wrapper">
              <app-datepicker
                [placeholder]="'To'"
                [basicStyles]="'true'"
                [value]="parentForm.controls.dateTo.value"
                (onChange)="onDatepickerChange($event, 'dateTo')"
              >
              </app-datepicker>
            </div>
          </ng-template>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        class="theme-decernis__tableCell"
        [attr.colspan]="visibleColumns.length"
        mat-cell
        *matCellDef="let element"
      >
        <div
          class="tags"
          [@detailExpand]="
            expandedElements.indexOf(element) > -1 ? 'expanded' : 'collapsed'
          "
        >
          <div class="tabs__section">
            <ng-container *ngIf="element.loadingHighlights$ | async">
              <div class="detail-section detail-section__spinner">
                <mat-spinner diameter="20"></mat-spinner>
              </div>
            </ng-container>

            <ng-container *ngIf="!(element.loadingHighlights$ | async)">
              <div
                *ngIf="element.matchedKeyTerms?.length"
                class="tabs__section"
              >
                <div class="tags__title">Key Terms</div>
                <div class="tags__content">
                  <app-chip
                    *ngFor="let keyTerm of element.matchedKeyTerms"
                    [name]="keyTerm"
                    color="accent"
                  ></app-chip>
                </div>
              </div>

              <ng-container *ngIf="element.highlights?.content">
                <div class="tags__title">Match Terms</div>
                <ul class="tags__list">
                  <li *ngFor="let content of element.highlights.content">
                    <span [innerHTML]="content"></span>
                  </li>
                </ul>
              </ng-container>
            </ng-container>
          </div>

          <div *ngIf="element.summary" class="tags__section">
            <div class="tags__title">Summary</div>
            <p class="tags__content">{{ element.summary }}</p>
          </div>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      class="theme-decernis__headerRow"
      *matHeaderRowDef="displayedColumns"
    ></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="theme-decernis__tableRow theme-decernis__tableRow--expandable"
      (click)="toggleExpandSingle(row)"
    ></tr>

    <tr
      mat-header-row
      *matHeaderRowDef="filterColumns"
      class="theme-decernis__headerRow"
    ></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="theme-decernis__tableRow theme-decernis__tableRow--details"
    ></tr>
  </table>

  <div *ngIf="dataSource.loading$ | async" class="theme-decernis__tableLoader">
    <mat-spinner diameter="80"></mat-spinner>
  </div>
</div>

<mat-paginator
  #paginator
  [length]="dataSource?.dataLength"
  [pageIndex]="dataSource?.currParams.offset / dataSource?.pageSize"
  [pageSize]="dataSource?.pageSize"
  (page)="extendUrlByOffset($event)"
>
</mat-paginator>
