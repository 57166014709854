<div class="tags">
  <div class="tags_container">
    <h5 *ngIf="!hideTitle" class="tags__title">Tags</h5>
    <ul class="tags__list">
      <li *ngFor="let tag of tags" class="tags__listItem">
        <span class="tags__name"
          >{{ mapTagKeyToName(tag.selectedOption) }}:</span
        >
        <app-chip
          *ngFor="let chip of tag.chips"
          class="tags__chip"
          [name]="chip.name"
          color="accent"
        ></app-chip>
      </li>
    </ul>
  </div>
</div>
