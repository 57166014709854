import { Component, OnInit, Input } from '@angular/core';
import { SelectOptionsConfig } from 'app/components/profile/select-option-config';
import { Tag } from 'app/interfaces';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent implements OnInit {
  @Input() tags: Tag[];
  @Input() hideTitle: boolean;

  constructor() {}

  ngOnInit() {}

  mapTagKeyToName(name: string): string {
    return SelectOptionsConfig.find(option => option.safeName === name).name;
  }
}
