import { Injectable, OnDestroy } from '@angular/core';
import { Subject, of, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { MetadataService } from './metadata.service';
import { map } from 'rxjs/operators';

interface RegionValues {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class RegionService implements OnDestroy {
  regionMap: RegionValues[] = [];
  regionSubject: Subject<RegionValues[]> = new Subject();
  constructor(private metadata: MetadataService) {}

  getMap(): Observable<RegionValues[]> {
    if (!this.regionMap.length) {
      return this.metadata.getData('region').pipe(
        map((response: HttpResponse<any>) => {
          const formattedResponse = response.body.results.map(
            (region: RegionValues) => ({
              name: region.name,
              value: region.value,
            })
          );
          this.regionMap = formattedResponse;
          return formattedResponse;
        })
      );
    } else {
      return of(this.regionMap);
    }
  }

  translateRegionCode(regionCode: string, regionMap?: RegionValues[]): string {
    if (!regionCode) {
      return;
    }

    if (!regionMap) {
      regionMap = this.regionMap;
    }
    const regionOpt = regionMap.find(
      region => region.value.toLowerCase() === regionCode.toLowerCase()
    );
    return regionOpt ? regionOpt.name : regionCode;
  }

  translateRegionName(regionName: string, regionMap?: RegionValues[]): string {
    if (!regionName) {
      return;
    }

    if (!regionMap) {
      regionMap = this.regionMap;
    }
    const regionOpt = regionMap.find(
      region => region.name.toLowerCase() === regionName.toLowerCase()
    );
    return regionOpt ? regionOpt.value : regionName;
  }

  ngOnDestroy(): void {
    this.regionSubject.unsubscribe();
  }
}
