<div class="theme-decernis__dialog-header">
  <mat-icon
    *ngIf="title.iconName"
    class="theme-decernis__icon theme-decernis__icon--medium"
    svgIcon="{{ title.iconName }}"
  ></mat-icon>
  <h2 class="theme-decernis__dialog-title">
    {{ title.text }}
  </h2>
  <button
    mat-button
    mat-icon-button
    class="theme-decernis__dialog-close theme-decernis__button"
    (click)="close()"
    tabIndex="-1"
  >
    <mat-icon class="theme-decernis__icon" svgIcon="close"></mat-icon>
  </button>
</div>

<mat-dialog-content class="theme-decernis__dialog-content">
  <app-column-picker
    *ngIf="columnPickerConfig"
    [columnConfig]="columnPickerConfig"
    (onChange)="onColumnPickerChange($event)"
  ></app-column-picker>

  <p
    *ngIf="description"
    class="theme-decernis__dialog-text"
    [innerHTML]="description"
  ></p>

  <ng-container *ngIf="inputOptions && inputOptions.type === 'file'">
    <app-file-upload
      (isFileValid)="setValid($event)"
      (fileOutput)="onFileInput($event)"
      [inputOptions]="inputOptions"
    ></app-file-upload>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions
  class="theme-decernis__dialog-actions"
  *ngIf="optionActions; else basicActions"
>
  <ng-container *ngFor="let elem of optionActions">
    <ng-container *ngIf="!elem.actionAfterClose">
      <button
        [ngClass]="'theme-decernis__button ' + elem.cssClass"
        (click)="close()"
      >
        {{ elem.description }}
      </button>
    </ng-container>

    <ng-container *ngIf="elem.actionAfterClose">
      <button
        [ngClass]="'theme-decernis__button ' + elem.cssClass"
        (click)="save()"
        [disabled]="isDisabled"
      >
        {{ elem.description }}
      </button>
    </ng-container>
  </ng-container>
</mat-dialog-actions>

<ng-template #basicActions>
  <mat-dialog-actions class="theme-decernis__dialog-actions">
    <button class="theme-decernis__button mat-stroked-button" (click)="close()">
      Close
    </button>
    <button
      *ngIf="confirm"
      class="theme-decernis__button mat-raised-button mat-primary"
      (click)="save()"
      [disabled]="isDisabled"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</ng-template>
