import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChipCreatorService } from 'app/services/chip-creator.service';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {
  @Output() removed: EventEmitter<boolean> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() name: string;
  @Input() color: 'primary' | 'accent';
  @Input() selectable: boolean;
  @Input() removable: boolean;
  @Input() small: boolean;
  @Input() disabled: boolean;
  @Input() iconOptions: object;
  @Input() tooltip: string;
  @Input() iconName: string;

  formattedValue: string;
  useTooltip: boolean;

  constructor(private chipCreator: ChipCreatorService) {}

  ngOnInit() {
    this.formattedValue = this.chipCreator.formatName(this.name);
    this.useTooltip = this.formattedValue !== this.name;
  }

  remove() {
    this.removed.emit();
  }

  click() {
    this.onClick.emit();
  }
}
