<div
  class="tableColumnFilter"
  [ngClass]="{ 'tableColumnFilter--hide': hide }"
  [ngSwitch]="type"
>
  <app-datepicker
    *ngSwitchCase="'date'"
    [basicStyles]="true"
    [id]="name"
    [placeholder]="datepickerPlaceholder"
    [value]="value"
    (onChange)="onDatepickerChange($event, id)"
  ></app-datepicker>

  <mat-form-field
    *ngSwitchCase="'select'"
    class="theme-decernis__form-field theme-decernis__form-field--as-filter"
    appearance="outline"
  >
    <mat-select
      class="theme-decerins__select"
      disableOptionCentering
      placeholder="Filter"
      panelClass="theme-decernis__selectPanel"
      [value]="value"
      (selectionChange)="onSelectChange($event, name)"
    >
      <mat-option></mat-option>
      <ng-container *ngIf="selectable.length; else async">
        <mat-option *ngFor="let option of selectable" [value]="option.value">{{
          option.name
        }}</mat-option>
      </ng-container>

      <ng-template #async>
        <mat-option *ngIf="loading">
          <mat-spinner diameter="20"></mat-spinner>
        </mat-option>

        <mat-option
          *ngFor="let option of selectable$ | async"
          [value]="option.value"
          >{{ option.name }}</mat-option
        >
      </ng-template>
    </mat-select>
  </mat-form-field>

  <button
    *ngSwitchCase="'submit'"
    type="submit"
    class="theme-decernis__button"
    mat-button
    mat-flat-button
    color="primary"
  >
    Filter
  </button>

  <mat-form-field
    *ngSwitchDefault
    class="theme-decernis__form-field theme-decernis__form-field--as-filter"
    appearance="outline"
    hideRequiredMarker
  >
    <input
      matInput
      placeholder="Search"
      [name]="name"
      [value]="value"
      (input)="onInput($event.target.value, name)"
    />
  </mat-form-field>
</div>
