import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]',
})
export class NumberOnlyDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      [
        'Delete',
        'Backspace',
        'Tab',
        'Escape',
        'Enter',
        'DecimalPoint',
        'Period',
        'End',
        'Home',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
      ].indexOf(event.code) !== -1 ||
      // Allow: Ctrl+A
      (event.code === 'KeyA' && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.code === 'KeyC' && event.ctrlKey === true) ||
      // Allow: Ctrl+V
      (event.code === 'KeyV' && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.code === 'KeyX' && event.ctrlKey === true)
    ) {
      // let it happen, don't do anything
      return;
    }

    /* Prevent check when arrow down is pressed */
    if (event.code !== 'ArrowDown') {
      const current: string = this.el.nativeElement.value;
      const next: string = current.concat(event.key);
      if (next.length > 4) {
        event.preventDefault();
      }
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
