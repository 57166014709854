import { Injectable } from '@angular/core';
import { RestService } from 'sso-angular';
import { environment } from 'env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface KeyTermValidatorResponse {
  correct: string[];
  incorrect: string[];
}

@Injectable({
  providedIn: 'root',
})
export class KeyTermValidationService {
  constructor(private rest: RestService) {}

  readonly perPage = 50;

  validateKeyTerms(keyTerms: string[]): Observable<KeyTermValidatorResponse> {
    const url = `${environment.apiUrls.news_ws}/lexicon/validator`;
    return this.rest
      .post<KeyTermValidatorResponse>(url, { key_terms: keyTerms })
      .pipe(map(response => response.body));
  }
}
