import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateOutput, ColumnFilterValue, SelectValue } from 'app/interfaces';
import { MatSelectChange } from '@angular/material/select';
import { Observable, isObservable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-table-column-filter',
  templateUrl: './table-column-filter.component.html',
  styleUrls: ['./table-column-filter.component.scss'],
})
export class TableColumnFilterComponent implements OnInit {
  @Output() onFilterValueChange: EventEmitter<
    ColumnFilterValue
  > = new EventEmitter();
  @Input() type: 'text' | 'select' | 'date' | 'submit';
  @Input() value: string | string[];
  @Input() selectable: SelectValue[] | Observable<SelectValue[]>;
  @Input() hide: boolean;
  @Input() name: string;

  datepickerPlaceholder = 'YYYY-MM-DD';
  loading = false;
  selectable$: Observable<SelectValue[]>;

  constructor() {}

  ngOnInit() {
    if (isObservable(this.selectable)) {
      this.loading = true;
      this.selectable$ = this.getSelectableObservable().pipe(
        tap(() => (this.loading = false))
      );
    }
  }

  getSelectableObservable(): Observable<SelectValue[]> {
    return this.selectable as Observable<SelectValue[]>;
  }

  onInput(value: string, name: string) {
    this.onFilterValueChange.emit({ [name]: value });
  }

  onSelectChange(event: MatSelectChange, name) {
    this.onFilterValueChange.emit({ [name]: event.value });
  }

  onDatepickerChange(dateValue: DateOutput) {
    const { date, id } = dateValue;
    const output = date ? { [id]: this.formatDate(date) } : { [id]: null };
    this.onFilterValueChange.emit(output);
  }

  formatDate(date: Date): string {
    const dateObj = new Date(date);
    const leadingZero = number => (number < 10 ? `0${number}` : number);
    return `${dateObj.getFullYear()}-${leadingZero(
      dateObj.getMonth() + 1
    )}-${leadingZero(dateObj.getDate())}`;
  }
}
