import { Component, OnInit } from '@angular/core';
import { environment } from 'env/environment';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RestService } from 'sso-angular';

@Component({
  selector: 'app-zendesk-widget',
  templateUrl: './zendesk-widget.component.html',
  styles: [],
})
export class ZendeskWidgetComponent implements OnInit {
  jwtUrl = environment.jwtUrl;
  category = environment.zendeskCategory;
  scriptSrc =
    'https://static.zdassets.com/ekr/snippet.js?key=8f0a3c06-c4cf-4e54-bffb-a881be967528';

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor(private rest: RestService) {}

  ngOnInit(): void {
    this.getJwtToken()
      .pipe(
        tap(token => {
          this.initConfig(token);
        }),
        switchMap(() => this.initZendeskScript())
      )
      .subscribe();
  }

  initZendeskScript(): Observable<void> {
    return new Observable(observer => {
      const doc = this.browserGlobals.documentRef();
      const script = doc.createElement('script');
      script.async = true;
      script.src = this.scriptSrc;
      script.id = 'ze-snippet';
      doc.body.appendChild(script);

      script.addEventListener('load', () => observer.next());
      script.addEventListener('error', () =>
        observer.error('failed to load a script')
      );
    });
  }

  initConfig(tokenArg: string) {
    const window = this.browserGlobals.windowRef();
    window.zESettings = {
      webWidget: {
        authenticate: {
          jwtFn: callback => {
            this.getJwtToken().subscribe(token => callback(token));
          },
        },
        helpCenter: {
          filter: {
            category: this.category,
          },
        },
        offset: { vertical: '60px' },
      },
    };
  }

  getJwtToken(): Observable<string> {
    return this.rest
      .get<{ jwt: string }>(this.jwtUrl)
      .pipe(map(response => response.body.jwt));
  }
}
