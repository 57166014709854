import { Injectable } from '@angular/core';
import { SelectOptionsConfig } from 'app/components/profile/select-option-config';

/**
 * General Analytics query building helpers and constants
 */
@Injectable()
export class QueryService {
  termLimit = 1000; // maximum number of terms in the query

  constructor() {}

  /**
   * Get SOLR date filter.
   * If no end date is provided the start date will be used as both
   * start and end date.
   * @param from start date in 'yyyy-mm-dd' format
   * @param to end date in 'yyyy-mm-dd' format
   * @return solr filter query
   */
  dateFilter(from: string, to: string): string {
    if (from && to) {
      return `date:[${from}T00:00:00Z TO ${to}T23:59:59Z]`;
    } else if (from) {
      return `date:[${from}T00:00:00Z TO NOW]`;
    } else if (to) {
      return `date:[* TO ${to}T23:59:59Z]`;
    }

    return null;
  }

  dateFromNow(value: number, type: string): string {
    const dateOptionObject = SelectOptionsConfig.find(
      option => option.safeName === 'date'
    ).additionalOptions.dateSelectValues.find(
      dateOption => dateOption.name === type
    );

    let solrDate: string;
    switch (dateOptionObject.value) {
      case 'days':
        solrDate = `date:[NOW-${value}DAYS TO NOW]`;
        break;

      case 'months':
        solrDate = `date:[NOW-${value}MONTHS TO NOW]`;
        break;

      case 'years':
        solrDate = `date:[NOW-${value}YEARS TO NOW]`;
        break;

      default:
        break;
    }
    return solrDate;
  }
}
