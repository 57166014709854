<!-- <app-loading [show]="loader"></app-loading> -->
<div class="mat-typography wrapper">
  <div class="form__header">
    <h2>
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--big"
        svgIcon="{{ iconName }}"
      ></mat-icon>
      {{ pageTitle }}
    </h2>
  </div>
  <form [formGroup]="formdata" (ngSubmit)="onSaveClick(formdata.value)">
    <div class="row">
      <div class="col-md-4 col-xs-12">
        <mat-form-field
          class="theme-decernis__form-field"
          appearance="outline"
          hideRequiredMarker
        >
          <mat-label>Title</mat-label>
          <input
            type="text"
            matInput
            formControlName="title"
            placeholder="title"
            maxlength="100"
          />
          <mat-error
            align="end"
            *ngIf="formdata.controls.title.errors?.required"
            >Title is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8 col-xs-12">
        <mat-form-field class="theme-decernis__form-field" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea
            class="theme-decernis__textarea"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            formControlName="description_text"
          ></textarea>
          <mat-error
            align="end"
            *ngIf="formdata.controls.description_text.errors?.maxlength"
            >Description is too long</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <mat-form-field class="theme-decernis__form-field" appearance="outline">
      <mat-label>Language</mat-label>
      <mat-select
        class="theme-decerins__select"
        panelClass="theme-decernis__selectPanel"
        disableOptionCentering
        formControlName="language"
      >
        <mat-option *ngFor="let elem of languages$ | async" [value]="elem.id">
          {{ elem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="row">
      <div class="col-md-4 col-xs-12">
        <mat-form-field class="theme-decernis__form-field" appearance="outline">
          <mat-label>Schedule</mat-label>
          <mat-select
            class="theme-decerins__select"
            panelClass="theme-decernis__selectPanel"
            disableOptionCentering
            formControlName="periodicity"
          >
            <mat-option
              *ngFor="let elem of periodicity$ | async"
              [value]="elem.id"
            >
              {{ elem.name }}
            </mat-option>
          </mat-select>
          <mat-error
            align="end"
            *ngIf="formdata.controls.periodicity.errors?.required"
            >Schedule is required</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4 col-xs-12">
        <app-timepicker
          [initialTime]="initialTime"
          (timeChanged)="onTimeChanged($event)"
        ></app-timepicker>
      </div>

      <div class="col-md-4 col-xs-12">
        <app-timezone-picker [control]="formdata.get('timezone')">
        </app-timezone-picker>
      </div>
    </div>

    <app-profile-picker
      [control]="formdata.get('data.categories')"
      multiple="true"
      [initialProfileIds]="initialPorfileIds"
    >
    </app-profile-picker>

    <app-chips-select
      class="search-query-filters__searchPreview"
      [useService]="false"
      [chips]="chips"
      [placeholder]="'Emails'"
      [control]="formdata.get('data.cc_emails')"
      [hideText]="true"
      [iconName]="'email'"
    >
    </app-chips-select>

    <div class="form__section form__section--small">
      <mat-checkbox
        formControlName="include_summary"
        color="primary"
        class="theme-decernis__checkbox"
        >Include Summary</mat-checkbox
      >
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--small newsletter__tooltip-icon"
        color="primary"
        svgIcon="info"
        matTooltip="Check to include summary in Newsletter Alert emails."
        matTooltipClass="theme-decernis__tooltip"
      ></mat-icon>
    </div>
    <div class="form__section form__section--small">
      <mat-checkbox
        formControlName="include_key_terms"
        color="primary"
        class="theme-decernis__checkbox"
        >Include Key Terms</mat-checkbox
      >
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--small newsletter__tooltip-icon"
        color="primary"
        svgIcon="info"
        matTooltip="Check to include Key Terms matches in Newsletter Alert emails."
        matTooltipClass="theme-decernis__tooltip"
      ></mat-icon>
    </div>
    <div class="form__section form__section--small">
      <mat-checkbox
        formControlName="include_match_terms"
        color="primary"
        class="theme-decernis__checkbox"
        >Include Match Terms</mat-checkbox
      >
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--small newsletter__tooltip-icon"
        color="primary"
        svgIcon="info"
        matTooltip="Check to include Match Terms in Newsletter Alert emails."
        matTooltipClass="theme-decernis__tooltip"
      ></mat-icon>
    </div>
    <div class="form__section form__section--small">
      <mat-checkbox
        formControlName="send_empty"
        color="primary"
        class="theme-decernis__checkbox"
        >Send alert email when there are zero results</mat-checkbox
      >
      <mat-icon
        class="theme-decernis__icon theme-decernis__icon--small newsletter__tooltip-icon"
        color="primary"
        svgIcon="info"
        matTooltip="Check to send mail even if there is no new data."
        matTooltipClass="theme-decernis__tooltip"
      ></mat-icon>
    </div>

    <footer class="form__footer">
      <div class="container">
        <div class="form__footerInner">
          <button
            class="theme-decernis__button"
            mat-button
            mat-stroked-button
            color="primary"
            type="button"
            (click)="onCancelClick()"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="cancel"></mat-icon>
            Cancel
          </button>
          <button
            [disabled]="isFormInvalid()"
            class="theme-decernis__button"
            mat-button
            mat-flat-button
            color="primary"
            type="submit"
          >
            <mat-icon class="theme-decernis__icon" svgIcon="save"></mat-icon>
            Save
          </button>
        </div>
      </div>
    </footer>
  </form>
</div>
