import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Issue,
  HttpResponseBodyWithPagination,
  ServerResponseIssue,
} from 'app/interfaces';
import { IssuesCrudService } from './issues-crud.service';
import { tap, map, flatMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IssuesDataFormatterService } from './issues-data-formatter.service';

@Injectable({
  providedIn: 'root',
})
export class IssueDataSourceService {
  dataSubject = new BehaviorSubject<Issue[]>([]);
  loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public dataLength = null;
  public pageSize = 50;
  public offset = this.getPageOffset();

  sortingKey = 'ordering';

  constructor(
    private issueCrudService: IssuesCrudService,
    private issueDataFormatter: IssuesDataFormatterService
  ) {}

  loadData(args?) {
    this.loadingSubject.next(true);

    if (args.offset) {
      this.offset = args.offset;
    }

    return this.issueCrudService
      .getAll(args)
      .pipe(
        tap((response: HttpResponse<HttpResponseBodyWithPagination<any>>) => {
          this.dataSubject.next([]);
          this.dataLength = response.body.count;
        }),
        map(
          (response: HttpResponse<HttpResponseBodyWithPagination<any>>) =>
            response.body.results
        ),
        flatMap((issues: ServerResponseIssue[]) => {
          return this.issueDataFormatter.format(issues);
        })
      )
      .subscribe((issue: Issue[]) => {
        this.loadingSubject.next(false);
        return this.dataSubject.next(issue);
      });
  }

  getPageOffset() {
    return +new URLSearchParams(window.location.search).get('offset') || 0;
  }

  connect(): Observable<Issue[]> {
    return this.dataSubject.asObservable();
  }

  disconnect() {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
}
