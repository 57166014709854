import { Injectable } from '@angular/core';
import { environment } from 'env/environment';
import { RestService } from 'sso-angular';
import { Observable } from 'rxjs';
import { IssueType, HttpResponseBodyWithPagination } from 'app/interfaces';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IssueTypeServiceService {
  issueEndpoint = environment.apiUrls.news_ws + '/issue/issue_type';
  constructor(private rest: RestService) {}

  get(): Observable<IssueType[]> {
    return this.rest
      .get<HttpResponseBodyWithPagination<IssueType[]>>(this.issueEndpoint, {
        limit: 50,
      })
      .pipe(map(res => res.body.results));
  }
}
