import { Component, OnInit } from '@angular/core';
import {
  IssueType,
  Issue,
  SearchQueryFilterData,
  Company,
  Tag,
} from 'app/interfaces';
import { IssueTypeServiceService } from 'app/services/issues/issue-type-service.service';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { IssuesCrudService } from 'app/services/issues/issues-crud.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { CompanyService } from 'app/services/company/company.service';
import { UserPermissionsService } from 'app/services/user/user-permissions.service';
import { DialogRedirectService } from 'app/services/dialog/dialog-redirect.service';
import { RestService } from 'sso-angular';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-issue-display',
  templateUrl: './issue-display.component.html',
  styleUrls: ['./issue-display.component.scss'],
})
export class IssueDisplayComponent implements OnInit {
  issueType$: Observable<IssueType[]>;
  issueID: number;

  title: string;
  issue_type: string;
  content: string;
  tags: Tag[];
  publish: Date;
  attachments: File[];
  shared: boolean;
  company: Company[];

  company$: Observable<Company[]>;
  pageTitle = 'Details';
  iconName = 'eye';
  showLoader = false;
  filtersConfig: SearchQueryFilterData;
  isContentAdmin$: Observable<boolean>;

  constructor(
    private issueTypeService: IssueTypeServiceService,
    private issueCrudService: IssuesCrudService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private companyService: CompanyService,
    private userPermissions: UserPermissionsService,
    private rest: RestService,
    private dialogRedirect: DialogRedirectService
  ) {}

  ngOnInit() {
    this.issueType$ = this.issueTypeService.get().pipe(shareReplay());
    this.company$ = this.companyService.get().pipe(shareReplay());
    this.isContentAdmin$ = this.userPermissions
      .isContentAdmin()
      .pipe(shareReplay());
    this.issueID = parseInt(this.route.snapshot.params.id, 10);
    if (!isNaN(this.issueID)) {
      this.setupPage(this.issueID);
    } else {
      this.router.navigate([`/issues`]);
    }
  }

  issueGetService(issueID): Observable<Issue> {
    return this.issueCrudService
      .get(issueID)
      .pipe(map((response: HttpResponse<Issue>) => response.body));
  }

  setupPage(issueID: number) {
    this.showLoader = true;
    this.issueGetService(issueID).subscribe(
      (issue: Issue) => {
        const {
          title,
          issue_type,
          content,
          tags,
          publish,
          attachments,
          companies,
        } = issue;
        this.title = title;
        this.issue_type = issue_type;
        this.content = content;
        this.tags = tags;
        this.publish = publish;
        this.attachments = attachments;
        this.company = companies;

        if (issue.tags === null) {
          issue.tags = [];
        }

        this.pageTitle = `Details - ${issue.title}`;
        this.showLoader = false;
      },
      error => {
        if (error.status === 404) {
          this.dialogRedirect.callDialog('/issues');
        }
      }
    );
  }

  getAttachement(url: string, fileName: string) {
    return this.rest
      .get(url, {}, {}, true)
      .pipe(map((response: HttpResponse<any>) => response.body))
      .subscribe(
        blob => {
          if (navigator.msSaveBlob) {
            // IE, EDGE
            navigator.msSaveBlob(blob, fileName);
          } else {
            saveAs(blob, fileName);
          }
        },
        err => {
          console.error(err);
          this.dialogRedirect.callDialog('/issues');
        }
      );
  }

  mapIssueType(issueID: number) {
    return this.issueType$.pipe(
      map(issues => {
        const found = issues.find(issue => issue.id === issueID);
        if (found) {
          return found.issue_type;
        }
      })
    );
  }

  onCancelClick() {
    this.location.back();
  }
}
