<mat-form-field class="theme-decernis__form-field" appearance="outline">
  <mat-select
    msInfiniteScroll
    [complete]="infiniteLoadingLocked"
    [formControl]="control"
    [multiple]="multiple"
    (infiniteScroll)="getNextProfiles()"
    (selectionChange)="selectProfile($event)"
    name="profile"
    class="theme-decernis__select"
    panelClass="theme-decernis__selectPanel"
    disableOptionCentering
  >
    <mat-select-trigger>
      {{ trigger }}
    </mat-select-trigger>

    <app-select-search
      #selectSearch
      [formControl]="filterCtrl"
    ></app-select-search>

    <div *ngIf="loading" class="profile-picker__loading">
      <mat-spinner diameter="20"></mat-spinner>
    </div>

    <mat-option *ngIf="displayEmpty" [value]="null"></mat-option>

    <mat-option *ngFor="let profile of filtered | async" [value]="profile.id">
      <span>{{ profile.full_title }}</span>
      <app-chip
        [name]="profile.private ? 'Private' : 'Public'"
        [color]="profile.private ? 'primary' : 'accent'"
        [selectable]="'false'"
      ></app-chip>
      <app-chip
        *ngIf="profile.shared"
        [name]="'Shared'"
        [color]="'accent'"
        [selectable]="'false'"
      ></app-chip>
    </mat-option>
  </mat-select>
  <mat-error align="end" *ngIf="control.errors?.required"
    >Profile is required</mat-error
  >
  <mat-error align="end" *ngIf="control.errors?.maxLengthArray"
    >You can select max {{ control.errors?.max }} profiles</mat-error
  >
  <mat-label>Select profile</mat-label>
</mat-form-field>
