import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  Subscription,
  Observable,
  BehaviorSubject,
  Subject,
  of,
  forkJoin,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { Profile, HttpResponseBodyWithPagination } from 'app/interfaces';
import {
  map,
  shareReplay,
  tap,
  debounceTime,
  finalize,
  catchError,
} from 'rxjs/operators';
import { ProfilesV2Service } from 'app/services/analytics/profile.service';
import { HttpResponse } from '@angular/common/http';
import { MatSelectChange } from '@angular/material/select';
import { SelectSearchComponent } from '../select-search/select-search.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-profile-picker',
  templateUrl: './profile-picker.component.html',
  styleUrls: ['./profile-picker.component.scss'],
})
export class ProfilePickerComponent implements OnInit {
  @Input() multiple: boolean;
  @Input() displayEmpty: boolean;
  @Input() control: FormControl;
  @Input() limit = 20;
  @Input() resetSubject: Subject<boolean>;
  @Input() set initialProfileIds(profileId: number | number[]) {
    if (profileId) {
      this.setProfile(profileId);
    }
  }
  @Output() selectedObjects = new EventEmitter<Profile>();
  @ViewChild('selectSearch', { static: true })
  selectSearch: SelectSearchComponent;

  selected: Profile | Profile[];
  trigger: string;
  profiles: Profile[];
  offsetPage = 1;
  subscription: Subscription;
  loading = false;
  infiniteLoadingLocked = false;
  profiles$: Observable<Profile[]>;
  filterCtrl: FormControl = new FormControl();
  filtered: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([]);
  previousSelectedProfiles: Profile[] = [];

  constructor(private profilesService: ProfilesV2Service) {}

  ngOnInit() {
    this.initProfileObservable();
    this.initProfiles();
    this.bindEvents();

    if (this.resetSubject) {
      this.resetSubject.subscribe(reset => {
        if (reset) {
          this.initProfileObservable();
          this.initProfiles();
          this.infiniteLoadingLocked = false;
          this.offsetPage = 1;
          this.previousSelectedProfiles = [];
        }
      });
    }
  }

  initProfileObservable() {
    this.profiles$ = this.getProfiles().pipe(
      map(profiles => this.formatProfiles(profiles)),
      shareReplay(1)
    );
  }

  initProfiles(): void {
    this.subscription = this.profiles$.subscribe(profiles => {
      this.profiles = profiles;
      this.filtered.next(profiles);

      const initialProfileID = this.control.value;
      if (initialProfileID) {
        this.setProfile(initialProfileID);
      }
    });
  }

  bindEvents() {
    this.filterCtrl.valueChanges
      .pipe(
        tap(() => {
          this.infiniteLoadingLocked = true;
        }),
        debounceTime(300),
        tap(() => {
          if (this.control.value) {
            if (this.multiple) {
              this.previousSelectedProfiles = this.filtered.value.filter(
                profile => this.control.value.includes(profile.id)
              );
            } else {
              const singleProfile = this.filtered.value.find(
                profile => profile.id === this.control.value
              ) as Profile;
              if (singleProfile) {
                this.previousSelectedProfiles = [singleProfile];
              }
            }
          }
        })
      )
      .subscribe(() => {
        this.filterProfiles();
      });
  }

  setProfile(id: number | number[]): void {
    if (!this.multiple) {
      const singleID = id as number;
      this.setSingleProfile(singleID);
    } else {
      const search = id as number[];
      this.setMultipleProfile(search);
    }
  }

  setSingleProfile(id: number) {
    const isSelectedProfileOnList = this.filtered.value.find(
      profile => profile.id === id
    );
    if (!isSelectedProfileOnList) {
      const singleID = id as number;
      this.getSingleProfile(singleID).subscribe(profile => {
        this.filtered.next([profile, ...this.filtered.value]);
        this.selected = profile;
        this.previousSelectedProfiles = [this.selected];
        this.trigger = profile.full_title;
      });
    } else {
      this.selected = this.filtered.value.find(profile => profile.id === id);
      this.trigger = this.selected.full_title;
      this.selectedObjects.emit(this.selected);
    }
  }
  setMultipleProfile(id: number[]) {
    const profilesOnList = id.filter(profileID => {
      return this.filtered.value.find(profile => profile.id === profileID);
    });

    const remainingProfiles = id.filter(
      profileID => !profilesOnList.includes(profileID)
    );
    const remaining$ = remainingProfiles.map(profileID =>
      // we pass null when there is no profile
      this.getSingleProfile(profileID).pipe(
        catchError(err => {
          this.control.setValue(
            this.control.value.filter(profiId => profiId !== profileID)
          );
          return of(null);
        })
      )
    );
    const profilesList = profilesOnList.map(profileID =>
      this.filtered.value.find(profile => profile.id === profileID)
    );

    if (remaining$.length) {
      forkJoin(...remaining$).subscribe((profiles: Profile[]) => {
        // filteredProfiles remove nulls from array
        const filteredProfiles = profiles.filter(profile => profile);
        this.trigger = [...filteredProfiles, ...profilesList]
          .map(profile => profile.full_title)
          .join(', ');
        this.filtered.next([...filteredProfiles, ...this.filtered.value]);
        const removeDuplicates = _.uniqBy([...this.filtered.value], 'id');
        this.filtered.next(removeDuplicates);
        this.previousSelectedProfiles = filteredProfiles;
      });
    } else {
      this.trigger = profilesList.map(profile => profile.full_title).join(', ');
    }
  }

  selectProfile(changeEvent: MatSelectChange) {
    const profileID = changeEvent.value;
    if (profileID) {
      this.setProfile(profileID);
    }
  }

  getSingleProfile(ID: number): Observable<Profile> {
    return this.profilesService.detail(ID).pipe(
      map(response => {
        const profile = response.body as Profile;
        return profile;
      })
    );
  }

  getProfiles(args?: Object): Observable<Profile[]> {
    this.loading = true;
    return this.profilesService
      .getProfiles({ limit: 20, ordering: 'private,title', ...args })
      .pipe(
        map(
          (
            response: HttpResponse<HttpResponseBodyWithPagination<Profile[]>>
          ) => {
            const results = response.body.results;

            if (!results.length || results.length < this.limit) {
              this.infiniteLoadingLocked = true;
            }

            return results;
          }
        ),
        finalize(() => {
          this.loading = false;
        })
      );
  }

  applyResponse(profiles: Profile[], mergePrevious?: boolean) {
    let appliedProfiles = profiles;
    if (mergePrevious) {
      appliedProfiles = _.uniqBy([...this.filtered.value, ...profiles], 'id');
    }
    this.filtered.next(appliedProfiles);
  }

  getNextProfiles() {
    const offset = this.limit * this.offsetPage;
    const title = this.filterCtrl.value;
    const args = {
      offset,
    };

    if (title) {
      args['title'] = title;
    }

    this.offsetPage++;
    this.subscription = this.getProfiles(args).subscribe(profiles => {
      this.applyResponse(profiles, true);
    });
  }

  formatProfiles(profiles: Profile[]): Profile[] {
    const onlyNeededKeys = profile => {
      const {
        id,
        full_title,
        key_terms,
        key_terms_string,
        structure,
        advanced_view_selected,
        filter_query_string,
        username,
        query_string,
        description,
        created,
        update_date,
        company,
        shared,
        shared_to_companies,
      } = profile;
      return {
        id,
        full_title,
        private: profile.private,
        key_terms,
        advanced_view_selected,
        key_terms_string,
        structure,
        filter_query_string,
        username,
        query_string,
        description,
        created,
        update_date,
        company,
        shared,
        shared_to_companies,
      };
    };

    const formattedProfiles = profiles.map(onlyNeededKeys).flat();

    return formattedProfiles;
  }

  filterProfiles() {
    this.offsetPage = 1;

    if (!this.profiles) {
      this.infiniteLoadingLocked = false;
      return;
    }

    const search = this.filterCtrl.value;
    if (!search) {
      this.filtered.next([
        ...this.profiles.slice(),
        ...this.previousSelectedProfiles,
      ]);
      this.infiniteLoadingLocked = false;
      return;
    }

    const args = {
      title: search,
    };

    this.subscription = this.getProfiles(args).subscribe(profiles => {
      const merged = _.uniqBy(
        [...profiles, ...this.previousSelectedProfiles],
        'id'
      );
      this.applyResponse(merged);
      this.infiniteLoadingLocked = false;
    });
  }
}
