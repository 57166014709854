import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-now',
  styles: [],
  template: `
    <span>
      {{ date | date: format }}
    </span>
  `,
})
export class NowComponent {
  date: Date;

  @Input()
  format: string;

  constructor() {
    this.date = new Date();
  }
}
